import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NoAccess from '../Common/NoAccess';
import Internal from './Internal';
import AAUS from './AAUS';
import Divers from './Divers';
import Expirations from './Expirations';

export const Reports = () => {
    const local = useSelector((state) => state.org.remember);
    const permissions = useSelector((state) => state[local ? 'localUser' : 'user'].permissions).split(',');

    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Report') >= 0} />}>
                    <Route index element={<Internal />} />
                    <Route path="internal" element={<Internal />} />
                    <Route path="aaus" element={<AAUS />} />
                    <Route path="divers" element={<Divers />} />
                    <Route path="expirations" element={<Expirations />} />
                </Route>
                <Route path="*" element={<div className="error-404">There's nothing here: 404!</div>} />
            </Routes>
        </>
    );
};

export default Reports;

const ProtectedRoute = ({ isAllowed, children }) => {
    if (!isAllowed) {
      return <NoAccess />;
    } else 
      return <>
        {children ? children : <Outlet />}
      </>;
};
import React, { useState, useReducer, useEffect } from 'react';
import Select from 'react-select';

import { suggestionService } from '../../Services';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const SuggestionBox = () => {
    const [to, setTo] = useState();
    const [data, setData] = useReducer(dataReducer, {});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.message)
            message = 'Please, enter required fields';

            if (!message) {
                const res = await suggestionService.add(data);
    
                if (res.res === 'OK') {
                    setTo(null);
                    setData({
                        action: 'clear'
                    });
                    setSuccess(true);
                } else {
                    setError(res.error);
                }
            } else {
                setError(message);
            }
            setLoading(false);
    };

    const toList = [
        { value: 'DSO', label: 'DSO' },
        { value: 'Support', label: 'Support' }
    ];
    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '44px'
          }),
        control: (provided, state) => ({
          ...provided,
          height: '44px',
          borderRadius: '8px',
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const changeValue = (key, value) => {
        setData({
            key: key,
            value: value,
        }); 
    }

    const changeTo = (value) => {
        setTo(value);
        setData({
            key: 'to',
            value: value.value,
        }); 
    }

    useEffect(() => {
        changeTo({ value: 'DSO', label: 'DSO' });
    }, [])

    return (
     <>
        <h1>Suggestion Box</h1>
        <p>Please feel free to submit your comments, suggestions and constructive criticism</p>
        <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
            <div className="form-column">
                <label>To</label>
                <Select className="select" options={toList} onChange={changeTo} styles={customStyles} required value={to} />
            </div>
            <div className="form-column mb-32">
                <label>Subject</label>
                <input type="text" name="subject" value={data.subject || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
            </div>
            <div className="form-column">
                <label>Message</label>
                <textarea name="message" onChange={(e) => changeValue(e.target.name, e.target.value)} value={data.message || ''}></textarea>
            </div>
            <div className="form-submit">
                <div><button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button></div>
                {error ? <div className="error">{error}</div> : ''}
                {success ? <div className="success">Your suggestion was successfully sent</div> : ''}
            </div>
        </form>
     </>
    );
};

export default SuggestionBox;
import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { confService } from '../../Services';

import { setFilter } from '../../redux/stores/orgSlice';

import Loader from '../Common/Loader';
import Modal from '../Common/Modal';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Plus} from '../../images/icons/General/plus.svg';
import {ReactComponent as Sunset} from '../../images/icons/Weather/sunset.svg';
import {ReactComponent as Sunrise} from '../../images/icons/Weather/sunrise.svg';
import {ReactComponent as Edit} from '../../images/icons/General/edit-01.svg';
import {ReactComponent as Globe} from '../../images/icons/Maps/globe-06.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Tasks = () => {
    const [optional, setOptional] = useReducer(dataReducer, {});
    const [custom, setCustom] = useReducer(dataReducer, {});
    const [optionalNumber, setOptionalNumber] = useState(0);
    const [customNumber, setCustomNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(0);
    const [editItem, setEditItem] = useState(null);
    const [editItemKey, setEditItemKey] = useState(null);
    const [sortField, setSortField] = useState('Task');
    const [sortOrder, setSortOrder] = useState(true);
    const [sortFieldCustom, setSortFieldCustom] = useState('Task');
    const [sortOrderCustom, setSortOrderCustom] = useState(true);
    const dispatch = useDispatch();

    const filter = useSelector((state) => state.org.filter.taskOptional ?? null);
    const filterCustom = useSelector((state) => state.org.filter.taskCustom ?? null);

    const changeFilter = (value, name) => {
        dispatch(setFilter({
            name,
            value
        }));
    }


    const sort = (e, type) => {
        if (type === 'custom') {
            if (e.target.id === sortFieldCustom)
                setSortOrderCustom(!sortOrderCustom)
            else 
                setSortFieldCustom(e.target.id);
        } else {
            if (e.target.id === sortField)
                setSortOrder(!sortOrder)
            else 
                setSortField(e.target.id);
        }
    }

    const updateInfo = (item) => {
        setCustom({
            key: editItemKey || item.TaskID, 
            value: item
        });
    }

    const setStatus = async (id, name, statusValue, type) => {
        setStatusLoading(id);
        const res = await confService.taskStatus({
            id: id,
            status: statusValue ? 1 : 0
        });
        if (res.result === 'OK')
            if (type === 'optional') {
                setOptional({
                    key: name, 
                    value: {
                        ...optional[name],
                        Status: statusValue ? 1 : 0
                    }
                });
            } else {
                setCustom({
                    key: name, 
                    value: {
                        ...custom[name],
                        Status: statusValue ? 1 : 0
                    }
                });
            }

            setStatusLoading(0);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await confService.getTasks();

        res.optional.forEach((el, key) => {
            setOptional({
                key: key,
                value: el
            });
        });
        setOptionalNumber(res.optionalNumber);

        res.custom.forEach((el, key) => {
            setCustom({
                key: key,
                value: el
            });
        });
        setCustomNumber(res.customNumber);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Dive log tasks</h1>
                {optional ? <>
                    <div className="bordered-content">
                        <div className="header">
                            <h4>Optional <div className="label">{optionalNumber} task{optionalNumber === 1 ? '' : 's'}</div></h4>
                            <p>You cannot rename these dive log tasks, but you can enable/disable them</p>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filter === null ? 'active' : ''} onClick={() =>changeFilter(null, 'taskOptional')}>View all</button>
                                <button type="button" className={filter === 1 ? 'active' : ''} onClick={() =>changeFilter(1, 'taskOptional')}>Active</button>
                                <button type="button" className={filter === 0 ? 'active' : ''} onClick={() =>changeFilter(0, 'taskOptional')}>Inactive</button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '90%'}} className={`sortable ${sortField === 'Task' ? (sortOrder ? 'down' : 'up') : ''}`} id="Task" onClick={sort}>Name</th>
                                    <th style={{width: '10%'}} className="light">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(optional).filter(([key, item]) => (filter === null || item.Status === filter)).sort(([,a],[,b]) => {
                                    if (typeof a[sortField] === 'number')
                                        return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                    else {
                                        const fieldA = a[sortField].toUpperCase(); 
                                        const fieldB = b[sortField].toUpperCase();
                                        if (fieldA < fieldB)
                                            return sortOrder ? -1 : 1;

                                        if (fieldA > fieldB)
                                            return sortOrder ? 1 : -1;
                                        
                                        return 0;
                                    }
                                }).map(([key, item]) => {
                                    return (
                                        <tr key={item.TaskID} className={!item.Status ? 'disabled' : ''}>
                                            <td className="mobile-bold">{item.Task}</td>
                                            <td className="desktop last">{
                                                statusLoading === item.TaskID ? 
                                                    <Loading className="btn-loader" /> : 
                                                    (item.Status ?
                                                        <Sunset className="pointer" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status, 'optional')} /> :
                                                        <Sunrise className="pointer" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status, 'optional')} />
                                                    )
                                                }</td>
                                            <td className="mobile"><label className="label link" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status, 'optional')}>{statusLoading === item.TaskID ? <Loading className="btn-loader" /> : ''}{item.Status ? 'Deactivate' : 'Activate'}</label></td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </> : <p className="empty">Task list is empty</p>}

                {custom ? <>
                    <div className="bordered-content">
                        <div className="header">
                            <h4>Custom <div className="label">{customNumber} task{customNumber === 1 ? '' : 's'}</div></h4>
                            <p>In this section you can customize dive log tasks specific to your organization</p>
                            <div className="buttons">
                                <button className="primary" type="button" onClick={() => setEditItem({})}><Plus />Add item</button>
                            </div>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filterCustom === null ? 'active' : ''} onClick={() =>changeFilter(null, 'taskCustom')}>View all</button>
                                <button type="button" className={filterCustom === 1 ? 'active' : ''} onClick={() =>changeFilter(1, 'taskCustom')}>Active</button>
                                <button type="button" className={filterCustom === 0 ? 'active' : ''} onClick={() =>changeFilter(0, 'taskCustom')}>Inactive</button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '25%'}} className={`sortable ${sortFieldCustom === 'Task' ? (sortOrderCustom ? 'down' : 'up') : ''}`} id="Task" onClick={(e) =>sort(e, 'custom')}>Name</th>
                                    <th style={{width: '55%'}} className={`sortable ${sortFieldCustom === 'Description' ? (sortOrderCustom ? 'down' : 'up') : ''}`} id="Description" onClick={(e) =>sort(e, 'custom')}>Description</th>
                                    <th style={{width: '10%'}} className="light">Status</th>
                                    <th style={{width: '10%'}} className="light">Edit</th>  
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(custom).filter(([key, item]) => (filterCustom === null || item.Status === filterCustom)).sort(([,a],[,b]) => {
                                     if (typeof a[sortFieldCustom] === 'number')
                                        return sortOrderCustom ? a[sortFieldCustom] - b[sortFieldCustom] : b[sortFieldCustom] - a[sortFieldCustom];
                                    else {
                                        const fieldA = a[sortFieldCustom].toUpperCase(); 
                                        const fieldB = b[sortFieldCustom].toUpperCase();
                                        if (fieldA < fieldB)
                                            return sortOrderCustom ? -1 : 1;

                                        if (fieldA > fieldB)
                                            return sortOrderCustom ? 1 : -1;
                                        
                                        return 0;
                                    }
                                }).map(([key, item]) => {
                                    return (
                                        <tr key={item.TaskID} className={!item.Status ? 'disabled' : ''}>
                                            <td className={`mobile-bold ${item.TaskID <= 2 ? 'bold' : ''}`}>{item.Task}</td>
                                            <td><strong className="mobile">Description: </strong>{item.Description ? <>{item.Description} <span className="mobile">ft</span></> : 'n/a'}</td>
                                            <td className="desktop">{statusLoading === item.TaskID ? 
                                                <Loading className="btn-loader" /> : 
                                                (item.Status ?
                                                    <Sunset className="pointer" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status)} /> :
                                                    <Sunrise className="pointer" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status)} />
                                                )
                                            }</td>
                                            <td className="desktop last">{<Edit className="pointer" onClick={() => {
                                                setEditItem(item);
                                                setEditItemKey(key);    
                                            }} />}</td>
                                            <td className="mobile">
                                                <label className="label link" onClick={() => statusLoading === item.TaskID ? {} : setStatus(item.TaskID, key, !item.Status)}>{statusLoading === item.TaskID ? <Loading className="btn-loader" /> : ''}{item.Status ? 'Deactivate' : 'Activate'}</label>
                                                <label className="label link" onClick={() => {
                                                    setEditItem(item);
                                                    setEditItemKey(key);
                                                }}>Edit</label>
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    {editItem != null ? <ModalTask item={editItem} setClose={() => {
                        setEditItem(null);
                        setEditItemKey(null);
                    }} updateInfo={updateInfo} /> : ''}
                </> : <p className="empty">Task list is empty</p>}
            </>}
        </>
    );
};

export default Tasks;

const ModalTask = ({item, setClose, updateInfo}) => {
    const [data, setData] = useState(item);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const changeValue = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.Task)
            message = 'Please, enter required fields';

            if (!message) {
                const res = await (data.TaskID ? confService.editTask(data) : confService.addTask(data));
    
                if (res.res === 'OK') {
                    setData({
                        ...data,
                        TaskID: item.TaskID || res.id,
                        Status: item.Status > -1 ? item.Status : 1
                    });
                    updateInfo({
                        ...data,
                        TaskID: item.TaskID || res.id,
                        Status: item.Status > -1 ? item.Status : 1
                    });
                    setSuccess(true);
                } else {
                    setError(res.error);
                }
            } else {
                setError(message);
            }
            setLoading(false);
    };

    return (
        <Modal setClose={setClose} icon={<Globe />}>
            <h4>{data.TaskID ? 'Edit' : 'Add'} task</h4>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label>Name</label>
                    <input type="text" name="Task" value={data.Task || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                </div>
                <div className="form-control">
                    <label>Description</label>
                    <textarea name="Description" value={data.Description || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} step="1" />
                </div>
                <div className="form-submit">
                    <div>
                        <button type="button" className="default" onClick={setClose}>Cancel</button>
                        <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    </div>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Task was successfully {data.TaskID ? 'updated' : 'added'}</div> : ''}
                </div>
            </form>
        </Modal>
    )
}
import React, {useState, useEffect} from 'react';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { reportService } from '../../Services';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Download} from '../../images/icons/General/download-cloud-02.svg';
import {ReactComponent as Printer} from '../../images/icons/Media/printer.svg';

import styles from '../../styles/components/users/letter.js';

export const Internal = () => {
    const [year, setYear] = useState(null);
    const [yearVar, setYearVar] = useState();
    const [years, setYears] = useState();
    const [report, setReport] = useState();
    const [classifications, setClassifications] = useState();
    const [tasks, setTasks] = useState();
    const [incidents, setIncidents] = useState();
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);

    const date = new Date();
    const orgName = useSelector((state) => state.org.name);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            display: 'inline-block',
            width: '300px',
            marginRight: '10px'
          }),
        control: (provided, state) => ({
          ...provided,
          height: '42px',
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const printReport = (e) => {
        e.preventDefault();
        
        window.frames["print_frame"].document.body.innerHTML = document.getElementById("report-title").outerHTML + document.getElementById("report").outerHTML + '<style>'+styles+'</style>';
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
    }

    const downloadCsv = async () => {
        if (year) {
            setLoadingCsv(true);
            const date = new Date();
            const res = await reportService.internalReportExport(year);
            const a = document.createElement('a');
            let binaryData = [];
            binaryData.push(res);
            let url = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csv"}))
            a.href = url;
            a.download = 'Internal-Stats-Report_'+window.location.hostname+'_'+moment(date).format('YYYY-MM-DD-HH-mm')+'.csv';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            setLoadingCsv(false);
        }
    };

    const handleReport = async () => {
        if (yearVar) {
            setLoading(true);
            const res = await reportService.internalReport(yearVar.value);
            if (res.report) {
                setYear(yearVar.value);
                setReport(res.report);
                setClassifications(res.classifications);
                setTasks(res.tasks);
                setIncidents(res.incidents);
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        let yearsVar = []
        for (let y = 2002; y <= new Date().getFullYear(); y ++) {
            yearsVar.push({
                value: y,
                label: y
            });
        }
        setYears(yearsVar);
        setYearVar(yearsVar[yearsVar.length - 2]);
    }, [setYears]);
    return (
        <>
            <h1 id="report-title">{year ? `${orgName} ${year} Internal Statistics Filed ${moment(date).format('YYYY-MM-DD-HH-mm')}` : 'Internal Statistics Report for the year'}</h1>
            <div className="bordered-content">
                <div className="header">
                    <div className="select-year">
                        {years ? <Select className={`select`} options={years} onChange={setYearVar} styles={customStyles} value={yearVar} /> : ''}
                        <button className="primary" type="button" onClick={handleReport}>{loading ? <Loading className="btn-loader"alt="loader" /> : ''}Generate report</button>
                    </div>
                </div>
            </div>
            {year ? <div id="report">
                <div className="bordered-content mt-32">
                    <div className="header">
                        <div className="buttons static">
                            <button className="default" onClick={printReport}><Printer />Print</button>
                            <button className="default" onClick={downloadCsv}>{loadingCsv ? <Loading className="btn-loader" alt="loader" /> : <Download />}Download CSV</button>
                            <iframe name="print_frame" title="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
                        </div>
                        <p><strong>Total Number of Divers logging dives during {year}: {report.TotalDivers}</strong></p>
                        <p><strong>Total Number of Dives logged during {year}: {report.TotalDives}</strong></p>
                        <p><strong>Diving Incidents This Training Cycle: {incidents}</strong></p>
                    </div>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Classification</h4>
                        <p>(each dive has only one classification)</p>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            {classifications ? classifications.map((item) => (
                                <tr key={`classification_${item.Name}`}>
                                    <td className="mobile-bold">{item.Name}</td>
                                    <td><span className="mobile">Dive Time In Minutes: </span>{item.TotalTime}</td>
                                    <td><span className="mobile">Dives Logged: </span>{item.TotalDives}</td>
                                    <td><span className="mobile">Number of Divers Logging Dives: </span>{item.TotalDivers}</td>
                                </tr>
                            )) : {}}
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Tasks</h4>
                        <p>(each dive may have multiple tasks)</p>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks ? tasks.map((item) => (
                                <tr key={`task_${item.Name}`}>
                                    <td className="mobile-bold">{item.Name}</td>
                                    <td><span className="mobile">Dive Time In Minutes: </span>{item.TotalTime}</td>
                                    <td><span className="mobile">Dives Logged: </span>{item.TotalDives}</td>
                                    <td><span className="mobile">Number of Divers Logging Dives: </span>{item.TotalDivers}</td>
                                </tr>
                            )) : ''}
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Diving Mode</h4>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="mobile-bold">Open Circuit SCUBA</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Mode_OCS_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Mode_OCS_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Mode_OCS_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Hookah</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Mode_Hoo_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Mode_Hoo_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Mode_Hoo_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Surface Supplied</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Mode_SS_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Mode_SS_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Mode_SS_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Rebreather</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Mode_Reb_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Mode_Reb_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Mode_Reb_Divers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Breathing Gas</h4>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="mobile-bold">Air</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Gas_Air_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Gas_Air_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Gas_Air_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Nitrox</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Gas_Nitrox_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Gas_Nitrox_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Gas_Nitrox_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Mixed Gas</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Gas_Mix_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Gas_Mix_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Gas_Mix_Divers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Decompression Profiling Method</h4>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="mobile-bold">Dive Tables</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.DP_Tables_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.DP_Tables_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.DP_Tables_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Dive Computer</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.DP_Computer_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.DP_Computer_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.DP_Computer_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">PC-based Deco Software</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.DP_Soft_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.DP_Soft_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.DP_Soft_Divers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Dives by Specialized Diving Environment</h4>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="mobile-bold">Required Decompression</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_RD_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_RD_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_RD_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Overhead Environment</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_OE_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_OE_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_OE_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Blue Water</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_BW_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_BW_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_BW_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Ice/Polar</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_IP_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_IP_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_IP_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Saturation Diving</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_SD_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_SD_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_SD_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">Aquarium Diving</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.SE_AD_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.SE_AD_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.SE_AD_Divers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className="bordered-content mt-32">
                    <div className="header">
                        <h4>Scientific or Training/Proficiency Dives by AAUS Depth Range</h4>
                    </div>
                    <table>
                        <thead>
                            <tr className="desktop">
                                <th style={{width: '40%'}}></th>
                                <th style={{width: '20%'}}>Dive Time In Minutes</th>
                                <th style={{width: '20%'}}>Dives Logged</th>
                                <th style={{width: '20%'}}>Number of Divers<br />Logging Dives</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="mobile-bold">0 - 30 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_30_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_30_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_30_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">31 - 60 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_60_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_60_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_60_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">61 - 100 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_100_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_100_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_100_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">101 - 130 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_130_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_130_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_130_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">131 - 150 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_150_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_150_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_150_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">151 - 190 feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_190_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_190_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_190_Divers}</td>
                            </tr>
                            <tr>
                                <td className="mobile-bold">190 - &gt; feet</td>
                                <td><span className="mobile">Dive Time In Minutes: </span>{report.Depth_191_Min}</td>
                                <td><span className="mobile">Dives Logged: </span>{report.Depth_191_Dives}</td>
                                <td><span className="mobile">Number of Divers Logging Dives: </span>{report.Depth_191_Divers}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div> : ''}
        </>
    );
};

export default Internal;

import React from 'react';

import {ReactComponent as Close} from '../../images/icons/General/x-close.svg';

export const Modal = ({setClose, icon, type, children}) => {
    return (
        <div className={`modal ${type}`}>
            <div className="modal-content">
                {icon ? <div className="modal-icon-bg"><span></span></div> : ''}
                <div className="modal-header">
                    {icon ? <div className="modal-icon">{icon}</div> : ''}
                    {setClose ? <Close onClick={setClose} /> : ''}
                </div>
                {children}
            </div>
        </div>
    );
}

export default Modal;
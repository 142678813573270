import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import { logsService } from '../../Services';

import Loader from '../Common/Loader';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const IncidentForm = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useReducer(dataReducer, {});
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const local = useSelector((state) => state.org.remember);
    const is24h = useSelector((state) => state[local ? 'localUser' : 'user'].is24h);

    const changeValue = (key, value) => {
        setData({
            key: key,
            value: value,
        }); 
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        if (params.incidentId)
            data.id = params.incidentId;

        
        let message = '';

        if (!data.date || !data.time || !data.Description)
            message = 'Please, enter required fields';

        if (!message) {
            const res = await (params.incidentId ? logsService.editIncident({
                ...data,
                date: data.date instanceof Date ? data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate() : data.date,
                time: data.time instanceof Date ? data.time.getHours() + ':' + data.time.getMinutes() : data.time
            }) : logsService.addIncident({
                ...data,
                date: data.date instanceof Date ? data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate() : data.date,
                time: data.time instanceof Date ? data.time.getHours() + ':' + data.time.getMinutes() : data.time
            }));

            if (res.res === 'OK') {
                if (!params.incidentId)
                    navigate('/logs/incidents/' + res.id);
                setSuccess(true);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    const fetchData = useCallback(async () => {
        setPageLoading(true);

        if (params.incidentId) {
            const resData = await logsService.editIncidentInfo(params.incidentId);
            Object.keys(resData.incident).forEach((key) => {
                if (key === 'date' || key === 'time')
                    resData.incident[key] = new Date(resData.incident[key].date);

                setData({
                    key: key,
                    value: resData.incident[key]
                });
            });
        }
        
        setPageLoading(false);
      }, [params]);

    useEffect(() => {
        setData({
            action: 'clear'
        });
        fetchData();
    }, [fetchData]);

    return (
        <>
         {pageLoading ? <Loader /> : 
            <>
                <h1>Log a new incident</h1> 
                <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                    <div className="form-column mb-32">
                        <label>Incident date</label>
                        <DatePicker name="date" selected={data.date} onChange={(value) => changeValue('date', value)} placeholderText="Select date" className="required" isClearable />
                    </div>
                    <div className="form-column">
                        <label>Incident time</label>
                        <DatePicker 
                            name="time" 
                            onChange={(value) => changeValue('time', value)} 
                            selected={data.time || ''} 
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={15}
                            timeFormat={is24h ? 'HH:mm' : 'hh:mm a'}
                            dateFormat={is24h ? 'HH:mm' : 'hh:mm a'}
                            isClearable
                        />
                    </div>
                    <div className="form-column">
                        <label>Incident description</label>
                        <textarea name="Description" onChange={(e) => changeValue(e.target.name, e.target.value)} value={data.Description || ''}></textarea>
                    </div>
                    <div className="form-submit">
                        <div><button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button></div>
                        {error ? <div className="error">{error}</div> : ''}
                        {success ? <div className="success">Your Incident was successfully saved</div> : ''}
                    </div>
                </form>
            </>}
        </>
    );
};

export default IncidentForm;


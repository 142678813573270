import { createSlice } from '@reduxjs/toolkit';

export const orgSlice = createSlice({
  name: 'org',
  initialState: {
    id: 0,
    logoExt: '',
    name: '',
    remember: false,
    version: '',
    filter: {
      'user': null,
      'location': null,
      'purposeOptional': null,
      'purposeCustom': null,
      'diver': null
    }
  },
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setLogoExt: (state, action) => {
      state.logoExt = action.payload;
    },
    setRemember: (state, action) => {
      state.remember = action.payload;
    },
    setVersion: (state, action) => {
      state.version = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        [action.payload.name]: action.payload.value 
      }
    },
    clearFilter: (state, initialState) => {
      state.filter =  {
        'user': null,
        'location': null,
        'taskOptional': null,
        'taskCustom': null,
        'diver': null,
        'divePlans': null
      }
    },
  },
});

export const { setId, setName, setLogoExt, setRemember, setVersion, setFilter, clearFilter } = orgSlice.actions;

export default orgSlice.reducer;
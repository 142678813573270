import { apiUrl } from '.';
import { authService } from './';

const add = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans', requestOptions);
    return res;
}

const edit = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans', requestOptions);
    return res;
}

const status = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans/status', requestOptions);
    return res;
}

const editInfo = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans/edit-info?id='+id, requestOptions);
    return res;
}

const history = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans', requestOptions);
    return res;
}

const diversInfo = async () => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans/divers', requestOptions);
    return res;
}

const updateDiversInfo = async (id) => {
    const requestOptions = {
        method: 'PUT'
    };

    const res = await authService.handleResponse(apiUrl + '/dive-plans/update-divers?id='+id, requestOptions);
    return res;
}

export const plansService = {
    add,
    edit,
    editInfo,
    history,
    status,
    diversInfo,
    updateDiversInfo
};
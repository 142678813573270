import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import { usersService } from '../../Services';
import styles from '../../styles/components/users/letter.js';

import Loader from '../Common/Loader';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else if (event.action === 'new') {
        return {
            ...event.value
        };
    } else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Letter = () => {
    const params = useParams();
    const location = useLocation();
    const [user, setUser] = useReducer(dataReducer, {});
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState();
    const [personType, setPersonType] = useState();

    const orgName = useSelector((state) => state.org.name);

    const personTypes = [
        {value: 'employee', label: 'employee'},
        {value: 'volunteer', label: 'volunteer'},
        {value: 'student', label: 'student'}
    ];

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const printLetter = (e) => {
        e.preventDefault();
        
        window.frames["print_frame"].document.body.innerHTML = document.getElementById("letter").outerHTML + '<style>'+styles+'</style>';
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
    }

    const changeValue = (key, value) => {
        setUser({
            key: key,
            value: value,
        }); 
    }

    const changeCertValue = (key, value) => {
        let cert = user.certifications;
        cert[key] = value;

        setUser({
            key: 'certifications',
            value: cert,
        }); 
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await usersService.letter(params.userId);

        let today = new Date();
        setDate((today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear());

        setUser({
            action: 'new',
            value: res.user
        });

        setLoading(false);
      }, [params]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {loading ? <Loader /> : <>
                <h1>{location.pathname.indexOf('reciprocity') > -1 ? 'AAUS REQUEST FOR DIVING RECIPROCITY FORM' : 'AAUS VERIFICATION OF TRAINING LETTER'}</h1>
                <form className="letter" onSubmit={printLetter}>
                    <div className="columns par">
                        <div>Diver: <input type="text" name="Name" value={user.Name} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>Date: <input type="text" name="date" value={date} onChange={e => setDate(e.target.value)}  /></div>
                    </div>
                    <div className="par">This letter serves to verify that the above listed  
                        <Select className={`select`} options={personTypes} onChange={setPersonType} styles={customStyles} required value={personType} defaultValue={personTypes[0]} />
                        has met the training and pre-requisites as indicated below, and has completed all requirements necessary to be certified as a 
                        <input type="text" id="DiverStatus" name="DiverStatus" value={user.DiverStatus}  onChange={e => changeValue(e.target.name, e.target.value)}  /> as established by the <strong>{orgName}</strong> Diving Safety Manual, and has demonstrated competency in the indicated areas. <strong>{orgName}</strong> is an AAUS OM and meets or exceeds all AAUS training requirements.
                    </div>
                    <div className="par"><strong>The following is a brief summary of this diver's personnel file regarding dive status at {orgName}</strong></div>
                    <div className="columns par">
                        <div>
                            <div><input type="text" name="OriginalDivingAuth" value={user.OriginalDivingAuth || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> Original diving authorization</div>
                            <div><input type="text" name="ScientificExam" value={user.ScientificExam || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> Written scientific diving examination</div>
                            <div><input type="text" name="MedicalDate" value={user.MedicalDate || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> Last diving medical examination</div>
                            <div><input type="text" name="RecentCheckoutDive" value={user.RecentCheckoutDive || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> Most recent checkout dive</div>
                            <div><input type="text" name="BCServiceExpiration" value={user.BCServiceExpiration || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> BC Service Expiration (scientific)</div>
                            <div><input type="text" name="RegulatorServiceExpiration" value={user.RegulatorServiceExpiration || ''} onChange={e => changeValue(e.target.name, e.target.value)} /> Regulator Service Expiration (scientific)</div>
                        </div>
                        <div>Medical examination exp.: <input type="text" name="MedExamExpDate" value={user.MedicalExpiration || ''} onChange={e => changeValue(e.target.name, e.target.value)}  /></div>
                    </div>
                    <div className="columns par">
                        <div>CPR training (Agency) <input type="text" name="CPRAgency" value={user.CPRAgency || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>CPR Exp.: <input type="text" name="CPRExpiration" value={user.CPRExpiration || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    </div>
                    <div className="columns par">
                        <div>Oxygen administration (Agency) <input type="text" name="O2Agency" value={user.O2Agency || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>O2 Exp.: <input type="text" name="O2Expiration" value={user.O2Expiration || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    </div>
                    <div className="columns par">
                        <div>First aid for diving (Agency) <input type="text" name="FirstAidAgency" value={user.FirstAidAgency || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>F.A. Exp.: <input type="text" name="FirstAidExpiration" value={user.FirstAidExpiration || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    </div>
                    <div className="par">
                        <input type="text" name="LastDiveDate" value={user.LastDiveDate || ''} onChange={e => changeValue(e.target.name, e.target.value)}  /> Date of last dive
                        <input type="text" name="LastDiveDepth" value={user.LastDiveDepth || ''} onChange={e => changeValue(e.target.name, e.target.value)}  /> Depth
                    </div>
                    <div className="columns par">
                        <div>Number of dives completed within previous 12 months? <input type="text" name="YearDivesNumber" value={user.YearDivesNumber} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>Depth Certification: <input type="text" name="DepthCertification" value={user.DepthCertification} onChange={e => changeValue(e.target.name, e.target.value)} /> feet</div>
                    </div>
                    <div className="par">Total number of career dives? <input type="text" name="DivesNumber" value={user.DivesNumber} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    <div className="par">Any restrictions? (Y/N) <input type="text" name="IsRestrictions" value={user.IsRestrictions} onChange={e => changeValue(e.target.name, e.target.value)} /> if yes, explain in the General Comments</div>
                    <div className="par mt-32">Please indicate any pertinent specialty certifications or training:</div>
                    <div className="certifications">
                        {user.certifications ? user.certifications.map((item, key) => {
                            return (
                                <input key={key} type="text" name={key} value={item || ''} onChange={e => changeCertValue(key, e.target.value)}  />
                            )
                        }) : ''}
                    </div>
                    <div className="par mt-32">Emergency Information:</div>
                    <div className="columns par">
                        <div>Name: <input type="text" name="EmergencyName" value={user.EmergencyName || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>Relationship: <input type="text" name="EmergencyRelationship" value={user.EmergencyRelationship || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    </div>
                    <div className="columns par">
                        <div>Best Phone #: <input type="text" name="EmergencyPhone" value={user.EmergencyPhone || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                        <div>Alternative Phone #: <input type="text" name="EmergencyPhoneAlternative" value={user.EmergencyPhoneAlternative || ''} onChange={e => changeValue(e.target.name, e.target.value)} /></div>
                    </div>
                    <div className="par">
                        <textarea name="EmergencyAddress" value={user.EmergencyAddress || ''} onChange={e => changeValue(e.target.name, e.target.value)} />
                    </div>
                    <div className="par mt-32">General Comments: </div>
                    <div className="par">
                        <textarea name="GeneralComments" value={user.GeneralComments || ''} onChange={e => changeValue(e.target.name, e.target.value)} />
                    </div>
                    <div className="par mt-32">This is to verify that the above individual is currently a certified scientific diver at <strong>{orgName}</strong></div>
                    <div className="par mt-32">Diving Safety Officer:</div>
                    <div className="columns par signature  mt-32">
                        <div>
                            <div>______________________________<br/>(Signature)</div>
                            <div>______________________________<br/>(Print)</div>
                        </div>
                        <div><input type="text" name="date" value={date} onChange={e => setDate(e.target.value)}  /><br />(Date)</div>
                    </div>
                    <div className="form-submit">
                        <button type="submit" className="primary">Print</button>
                    </div>
                </form>
                <div id="letter">
                    <h1>{location.pathname.indexOf('reciprocity') > -1 ? 'AAUS REQUEST FOR DIVING RECIPROCITY FORM' : 'AAUS VERIFICATION OF TRAINING LETTER'}</h1>
                    <div className="letter">
                        <div className="columns par">
                            <div>Diver: {user.Name}</div>
                            <div>Date: {date}</div>
                        </div>
                        <div className="par">This letter serves to verify that the above listed {personType ? personType.value : personTypes[0].value} has met the training and pre-requisites as indicated below, and has completed all requirements necessary to be certified as a {user.DiverStatus} as established by the <strong>{orgName}</strong> Diving Safety Manual, and has demonstrated competency in the indicated areas. <strong>{orgName}</strong> is an AAUS OM and meets or exceeds all AAUS training requirements.
                        </div>
                        <div className="par"><strong>The following is a brief summary of this diver's personnel file regarding dive status at {orgName}</strong></div>
                        <div className="columns par">
                            <div>
                                <div>Original diving authorization: {user.OriginalDivingAuth || ''}</div>
                                <div>Written scientific diving examination: {user.ScientificExam || ''}</div>
                                <div>Last diving medical examination: {user.MedicalDate || ''}</div>
                                <div>Most recent checkout dive: {user.RecentCheckoutDive || ''}</div>
                                <div>BC Service Expiration (scientific): {user.BCServiceExpiration || ''}</div>
                                <div>Regulator Service Expiration (scientific): {user.RegulatorServiceExpiration || ''}</div>
                            </div>
                            <div>Medical examination exp.: {user.MedicalExpiration || ''}</div>
                        </div>
                        <div className="columns par">
                            <div>CPR training (Agency): {user.CPRAgency || ''}</div>
                            <div>CPR Exp.: {user.CPRExpiration || ''}</div>
                        </div>
                        <div className="columns par">
                            <div>Oxygen administration (Agency): {user.O2Agency || ''}</div>
                            <div>O2 Exp.: {user.O2Expiration || ''}</div>
                        </div>
                        <div className="columns par">
                            <div>First aid for diving (Agency): {user.FirstAidAgency || ''}</div>
                            <div>F.A. Exp.: {user.FirstAidExpiration || ''}</div>
                        </div>
                        <div className="par">
                            Date of last dive: {user.LastDiveDate || ''} <span style={{width: '20px', display: 'inline-block'}}></span>
                            Depth: {user.LastDiveDepth || ''}
                        </div>
                        <div className="columns par">
                            <div>Number of dives completed within previous 12 months: {user.YearDivesNumber}</div>
                            <div>Depth Certification: {user.DepthCertification} feet</div>
                        </div>
                        <div className="par">Total number of career dives: {user.DivesNumber}</div>
                        <div className="par">Any restrictions? (Y/N) <input type="text" name="IsRestrictions" value={user.IsRestrictions} onChange={e => changeValue(e.target.name, e.target.value)} /> if yes, explain in the General Comments</div>
                        <div className="par mt-32">Please indicate any pertinent specialty certifications or training:</div>
                        <div className="certifications">
                            {user.certifications ? user.certifications.map((item, key) => {
                                return (
                                    <>{item ? <span key={key}>{item || ''}</span> : ''}</>
                                )
                            }) : ''}
                        </div>
                        <div className="par mt-32">Emergency Information:</div>
                        <div className="columns par">
                            <div>Name: {user.EmergencyName || ''}</div>
                            <div>Relationship: {user.EmergencyRelationship || ''}</div>
                        </div>
                        <div className="columns par">
                            <div>Best Phone #: {user.EmergencyPhone || ''}</div>
                            <div>Alternative Phone #: {user.EmergencyPhoneAlternative || ''}</div>
                        </div>
                        <div className="par">
                            {user.EmergencyAddress || ''}
                        </div>
                        <div className="par mt-32">General Comments: </div>
                        <div className="par">
                            {user.GeneralComments || ''}
                        </div>
                        <div className="par mt-32">This is to verify that the above individual is currently a certified scientific diver at <strong>{orgName}</strong></div>
                        <div className="par mt-32">Diving Safety Officer:</div>
                        <div className="columns par signature  mt-32">
                            <div>
                                <div>______________________________<br/>(Signature)</div>
                                <div>______________________________<br/>(Print)</div>
                            </div>
                            <div>{date}<br />(Date)</div>
                        </div>
                    </div>
                </div>
                <iframe name="print_frame" title="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
            </>}
        </>
    );
};

export default Letter;
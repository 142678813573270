import { apiUrl } from '.';
import { authService } from './';

const getByHost = async () => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify({
            host: window.location.host
        })
    };

    const res = await authService.handleResponse(apiUrl + '/organizations/get-by-host', requestOptions);
    return res;
}

export const orgService = {
    getByHost
};
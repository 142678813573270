import { apiUrl } from '.';
import { authService } from './';

const add = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/logs', requestOptions);
    return res;
}

const edit = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/logs', requestOptions);
    return res;
}

const addInfo = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/add-info' + (id ? '?id='+id : ''), requestOptions);
    return res;
}

const editInfo = async (id, userId) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/edit-info?id='+id+(userId ? '&userId='+userId : ''), requestOptions);
    return res;
}

const history = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs' + (id ? '?id='+id : ''), requestOptions);
    return res;
}

const historyExport = async (id) => {
    const requestOptions = {
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
    };

    const res = await authService.handleResponse(apiUrl + '/logs/csv' + (id ? '?id='+id : ''), requestOptions, true);
    return res;
}

const getIncidents = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/incidents', requestOptions);
    return res;
}

const editIncidentInfo = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/incidents/edit-info?id='+id, requestOptions);
    return res;
}

const addIncident = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/logs/incidents', requestOptions);
    return res;
}

const editIncident = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/logs/incidents', requestOptions);
    return res;
}

const importTemplate = async () => {
    const requestOptions = {
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
    };

    const res = await authService.handleResponse(apiUrl + '/logs/import/file', requestOptions, true);
    return res;
}

const importInfo = async () => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/import/info', requestOptions);
    return res;
}

const importData = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/logs/import', requestOptions);
    return res;
}

const importList = async () => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/import', requestOptions);
    return res;
}

const importClear = async () => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/logs/import/clear', requestOptions);
    return res;
}

export const logsService = {
    add,
    edit,
    addInfo,
    editInfo,
    history,
    historyExport,
    getIncidents,
    editIncidentInfo,
    addIncident,
    editIncident,
    importTemplate,
    importInfo,
    importData,
    importList,
    importClear
};
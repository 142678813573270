import React, { useState, useReducer, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import { plansService } from '../../Services';

import Loader from '../Common/Loader';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Plus} from '../../images/icons/General/plus.svg';
import {ReactComponent as Minus} from '../../images/icons/General/minus.svg';
import {ReactComponent as Refresh} from '../../images/icons/Arrows/refresh-cw-02.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Form = () => {
    const params = useParams();
    const [data, setData] = useReducer(dataReducer, {});
    const [loading, setLoading] = useState(false);
    const [loadingInfo, setLoadingInfo] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [emergency, setEmergency] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState(null);
    const [reason, setReason] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState(false);
    const [errorStatus, setErrorStatus] = useState('');
    const [successStatus, setSuccessStatus] = useState(false);
    const [divers, setDivers] = useState([]);
    const [users, setUsers] = useState([]);

    const local = useSelector((state) => state.org.remember);
    const permissions = useSelector((state) => state[local ? 'localUser' : 'user'].permissions).split(',');
    const name = useSelector((state) => state[local ? 'localUser' : 'user'].name);

    const today = new Date();
    today.setDate(today.getDate() + 1);

    const endorsements = {
        DrySuit: 'Dry suit',
        AgaMask: 'Full face mask',
        DiveComputer: 'Dive computer',
        Nitrox: 'Nitrox',
        MixedGas: 'Mixed gas',
        ClosedCircuit: 'Closed circuit rebreather',
        SemiClosedCircuit: 'Semi-closed circuit rebreather',
        Saturation: 'Saturation',
        Decompression: 'Decompression',
        BlueWater: 'Blue water',
        Altitude: 'Altitude',
        IcePolar: 'Ice/polar',
        Cave: 'Cave',
        Cavern: 'Cavern',
        Night: 'Night',
        TeamLeader: 'Lead diver',
        ScientificDiver: 'Scientific diver',
        ScientificDiverInTraining: 'Scientific diver in training',
        AquariumDiver: 'Aquarium diver',
        ExhibitDiver: 'Exhibit diver',
        PresentationDiver: 'Presentation diver',
        SharkLagoonDiver: 'Shark diver',
        NewDiverTrainer: 'New diver trainer',
        NewDiverTraining: 'New diver training complete',
        PresentationTrainer: 'Presentation trainer'
    };

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            minHeight: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const changeValue = (key, value) => {
        setData({
            key: key,
            value: value,
        }); 
    }

    const changeSelectValue = (name, value, selectSetVar) => {
        selectSetVar(value);
        setData({
            key: name,
            value: value.map(item => item.value).join(','),
        });
    }

    const handleStatus = (value) => {
        setStatus(value);
        setSuccessStatus(false);
        setErrorStatus('');
    }

    const updateInfo = async () => {
        setLoadingInfo(true);

        const res = await plansService.updateDiversInfo(params.planId);
        setUsers(res.users);
        setDivers(res.users ? res.users.filter(item => data.divers.split(',').includes(item.value.toString())) : '');
        setData({
            key: 'diversUpdateDate',
            value: res.date
        });

        setLoadingInfo(false);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        if (params.planId)
            data.id = params.planId;

        let message = '';

        if (data.dateStart <= new Date())
            message = 'The date can not be in the past';

        if (data.dateStart > data.dateEnd)
            message = 'Start date can not be greater than end date';
        
        if (!data.divers || !data.divers.length)
            message = 'Add divers';

        if (!message) {
            const res = await (params.planId ? plansService.edit({
                ...data,
                dateStart: data.dateStart instanceof Date ? data.dateStart.getFullYear() + '-' + (data.dateStart.getMonth() + 1) + '-' + data.dateStart.getDate() : data.dateStart,
                dateEnd: data.dateEnd instanceof Date ? data.dateEnd.getFullYear() + '-' + (data.dateEnd.getMonth() + 1) + '-' + data.dateEnd.getDate() : data.dateEnd
            }) : plansService.add({
                ...data,
                dateStart: data.dateStart instanceof Date ? data.dateStart.getFullYear() + '-' + (data.dateStart.getMonth() + 1) + '-' + data.dateStart.getDate() : data.dateStart,
                dateEnd: data.dateEnd instanceof Date ? data.dateEnd.getFullYear() + '-' + (data.dateEnd.getMonth() + 1) + '-' + data.dateEnd.getDate() : data.dateEnd
            }));

            if (res.res === 'OK') {
                if (!params.planId) {
                    clearData();
                }
                setSuccess(true);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    const handleSubmitStatus = async (event) => {
        event.preventDefault();
        
        setErrorStatus('');
        setSuccessStatus(false);
        setLoadingStatus(true);

        let message = '';

        if (!message) {
            const res = await plansService.status({
                id: params.planId,
                status,
                reason
            });

            if (res.res === 'OK') {
                setData({
                    key: 'status',
                    value: status
                });
                setData({
                    key: 'reason',
                    value: reason
                });
                setData({
                    key: 'dsoname',
                    value: name
                });
                
                setSuccessStatus(true);
            } else
                setErrorStatus(res.error);
        } else
            setErrorStatus(message);
            
        setLoadingStatus(false);
    };

    const fetchData = useCallback(async (last) => {
        setPageLoading(true);

        if (params.planId) {
            const resData = await plansService.editInfo(params.planId);
            Object.keys(resData.plan).forEach((key) => {
                if (key === 'dateStart' || key === 'dateEnd')
                    resData.plan[key] = new Date(resData.plan[key].date);

                setData({
                    key: key,
                    value: resData.plan[key]
                });
            });

            setUsers(resData.users);
            setDivers(resData.users ? resData.users.filter(item => resData.plan.divers.split(',').includes(item.value.toString())) : '');

            setDisabled(resData.disabled);
            setReason(resData.plan.reason);
            setStatus(resData.plan.status);
        } else {
            const resData = await plansService.diversInfo();
            setUsers(resData);
        }

        setPageLoading(false);
    }, [params]);

    const clearData = () => {
        setError(null);
        setDivers([]);
        setData({
            action: 'clear'
        });
    }

    useEffect(() => {
        clearData();
        fetchData();
    }, [fetchData]);

    return (
        <>
            {pageLoading ? <Loader /> : 
                <>
                    <h1>{params.planId ? 'Edit' : 'New'} dive plan</h1>
                    <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                        <div className="form-column mb-32">
                            <label>Submitted by</label>
                            <div>{params.planId ? data.username : name}</div>
                        </div>
                        <div className="form-column">
                            <label>Divers</label>
                            <Select className="select" required 
                                options={users} 
                                onChange={(value) => changeSelectValue('divers', value, setDivers)} 
                                styles={customStyles}  
                                value={divers}
                                isMulti={true}
                            />
                        </div>
                        {divers.length ? <>
                            <div className="divers-table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="2">Divers</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        divers.sort((a,b) => {
                                            const fieldA = (a['FirstName'] + ' ' + a['LastName']).toUpperCase(); 
                                            const fieldB = (b['FirstName'] + ' ' + b['LastName']).toUpperCase(); 
                                            if (fieldA < fieldB)
                                                return -1;

                                            if (fieldA > fieldB)
                                                return 1;
                                            
                                            return 0;
                                        }).map(item => {
                                            let endorsementList = '';
                                            Object.keys(endorsements).forEach(key => {
                                                if (item[key])
                                                    endorsementList += (endorsementList ? ', ' : '') + endorsements[key];
                                            });
                                            return (
                                                <tr key={item.value}>
                                                    <td>
                                                        <p>{permissions.indexOf('View user') >= 0 ? <a href={`/configuration/users/${item.value}`} target="_blank" rel="noreferrer">{item.LastName}, {item.FirstName}</a> : <strong>{item.LastName}, {item.FirstName}</strong>}</p>
                                                        <p><strong>DOB:</strong> {item.DateOfBirth}</p>
                                                        <p><strong>Emergency contact:</strong> {item.EmergencyFirstName} {item.EmergencyLastName}, {item.EmergencyRelationship}, {item.EmergencyPhone}{item.EmergencyPhoneAlternative ? `, ${item.EmergencyPhoneAlternative}` : ''}</p>
                                                        <p><strong>Diving insurance carrier, policy #:</strong> {item.InsuranceCarrier} {item.InsurancePolicy}</p>
                                                        {item.ScientificDiverInTraining || item.ScientificDiver ? <p><strong>Role:</strong> {item.ScientificDiverInTraining ? 'Scientific diver in training' : (item.ScientificDiver ? 'Scientific diver' : '')}</p> : ''}
                                                        <p><strong>Depth Certification:</strong> {item.DepthCertification}</p>
                                                        <p className={item.MedicalExpiration && new Date(item.MedicalExpiration) < data.dateEnd ? 'red' : ''}><strong>Medical Expiration:</strong> {item.MedicalExpiration}</p>
                                                        <p className={item.CPRExpiration && new Date(item.CPRExpiration) < data.dateEnd ? 'red' : ''}><strong>CPR Expiration:</strong> {item.CPRExpiration}</p>
                                                        <p className={item.O2Expiration && new Date(item.O2Expiration) < data.dateEnd ? 'red' : ''}><strong>O2 Expiration:</strong> {item.O2Expiration}</p>
                                                    </td>
                                                    <td>
                                                        <p className={item.AEDExpiration && new Date(item.AEDExpiration) < data.dateEnd ? 'red' : ''}><strong>AED Expiration:</strong> {item.AEDExpiration}</p>
                                                        <p className={item.FirstAidExpiration && new Date(item.FirstAidExpiration) < data.dateEnd ? 'red' : ''}><strong>First Aid Expiration:</strong> {item.FirstAidExpiration}</p>
                                                        <p className={item.BCServiceExpiration && new Date(item.BCServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>BC Service Expiration:</strong> {item.BCServiceExpiration}</p>
                                                        <p className={item.RegulatorServiceExpiration && new Date(item.RegulatorServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>Regulator Service Expiration:</strong> {item.RegulatorServiceExpiration}</p>
                                                        <p className={item.GaugeCompExp && new Date(item.GaugeCompExp) < data.dateEnd ? 'red' : ''}><strong>Gauge/Computer Expiration:</strong> {item.GaugeCompExp}</p>
                                                        <p>
                                                            <strong>Endorsements:</strong> {endorsementList}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    {params.planId ? <tr>
                                        <td colSpan="2">
                                            <div className="flex">
                                                <p>Participants information last updated: <span>{data.diversUpdateDate}</span></p>
                                                <button type="button" className="default" onClick={updateInfo}><Refresh className={loadingInfo ? 'btn-loader' : ''} disabled={loadingInfo} />Refresh</button>
                                            </div>
                                            <p><em>Please keep in mind that all information copied from the participant's dive profile is a frozen record. In other words it shows the role, depth certification, and all expirations as of the moment when you added the participant. If needed, please use the refresh button to repopulate all participants' data from their profiles.</em></p>
                                        </td>
                                    </tr> : ''}
                                    </tbody>
                                </table>
                            </div>
                        </> : ''}
                        <div className="form-column">
                            <label>Start date</label>
                            <DatePicker 
                                name="dateStart" 
                                selected={data.dateStart} 
                                minDate={today} 
                                onChange={(value) => changeValue('dateStart', value)}  
                                placeholderText="Select date" 
                                className="required"
                                autoComplete="off"
                                required={true}
                                isClearable={!disabled}
                                disabled={disabled}
                             />
                        </div>
                        <div className="form-column">
                            <label>End date</label>
                            <DatePicker 
                                name="dateEnd" 
                                selected={data.dateEnd} 
                                minDate={today} 
                                onChange={(value) => changeValue('dateEnd', value)}  
                                placeholderText="Select date" 
                                className="required"
                                autoComplete="off"
                                required={true}
                                isClearable={!disabled}
                                disabled={disabled}
                             />
                        </div>
                        <div className="form-column">
                            <label>Diving mode(s) and gas(es)</label>
                            <textarea type="text" 
                                name="divingModes" 
                                value={data.divingModes || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Approximate number of proposed dives</label>
                            <input type="number" 
                                name="divesNumber" 
                                value={data.divesNumber || ''} 
                                min="0"
                                step="1"                             
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Location(s) of proposed dives</label>
                            <textarea type="text" 
                                name="locations" 
                                value={data.locations || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Estimated maximum working diver depth(s) and bottom time(s) anticipated</label>
                            <textarea type="text" 
                                name="depths" 
                                value={data.depths || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Decompression status and repetitive dive plans (if required)</label>
                            <textarea type="text" 
                                name="decompressionStatus" 
                                value={data.decompressionStatus || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Proposed work, equipment, and boats to be employed</label>
                            <textarea type="text" 
                                name="proposedWork" 
                                value={data.proposedWork || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Any hazardous conditions anticipated</label>
                            <textarea type="text" 
                                name="conditions" 
                                value={data.conditions || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className={`emergency-plan ${emergency ? 'open' : ''}`}>
                            <h3 onClick={() => setEmergency(!emergency)}>Emergency Action Plan {emergency ? <Minus /> : <Plus />}</h3>
                            <div>
                                <div>
                                    <p>Depending on and according to the nature of the diving accident:</p>
                                    <ol>
                                        <li>Make appropriate contact with victim or rescue as required.</li>
                                        <li>Establish (A)irway (B)reathing (C)irculation or (C)irculation (A)irway (B)reathing as appropriate.</li>
                                        <li>Stabilize the victim.</li>
                                        <li>Administer 100% oxygen, if appropriate (in cases of Decompression Illness, or Near Drowning).</li>
                                        <li>Call local Emergency Medical System (EMS) for transport to nearest medical treatment facility. Explain the circumstances of the dive incident to the evacuation teams, medics and physicians. Do not assume that they understand why 100% oxygen may be required for the diving accident victim or that recompression treatment may be necessary.</li>
                                        <li>Call appropriate Diving Accident Coordinator for contact with diving physician and recompression chamber, etc.</li>
                                        <li>Notify DSO or designee according to the Emergency Action Plan of the OM.</li>
                                        <li>Complete and submit Incident Report Form (<a href="https://www.aaus.org" target="_blank"rel="noreferrer">www.aaus.org</a>) to the DCB of the organization and the AAUS (Section 2.70 Required Incident Reporting).</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="form-column">
                            <label>List of emergency contact numbers appropriate for dive location</label>
                            <textarea type="text" 
                                name="emergencyContactLocation" 
                                value={data.emergencyContactLocation  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Name, telephone number, and relationship of person to be contacted for each diver in the event of an emergency<span>Optional</span></label>
                            <textarea type="text" 
                                name="emergencyContact" 
                                value={data.emergencyContact  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Nearest operational recompression chamber</label>
                            <textarea type="text" 
                                name="orChamber" 
                                value={data.orChamber  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Nearest accessible hospital</label>
                            <textarea type="text" 
                                name="hospital" 
                                value={data.hospital  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Available means of transport</label>
                            <textarea type="text" 
                                name="transport" 
                                value={data.transport  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <h3>In water details</h3>
                        <div className="form-column">
                            <label>Dive buddy assignments and tasks</label>
                            <textarea type="text" 
                                name="diverTasks" 
                                value={data.diverTasks || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Goals and objectives</label>
                            <textarea type="text" 
                                name="goals" 
                                value={data.goals || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Maximum dive site depth(s)</label>
                            <textarea type="text" 
                                name="maximumDepth" 
                                value={data.maximumDepth || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Gas management plan</label>
                            <textarea type="text" 
                                name="gasPlan" 
                                value={data.gasPlan  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Entry, exit, descent, and ascent procedures</label>
                            <textarea type="text" 
                                name="procedures" 
                                value={data.procedures  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Perceived environmental and operational hazards and mitigations</label>
                            <textarea type="text" 
                                name="hazards" 
                                value={data.hazards  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        <div className="form-column">
                            <label>Emergency and diver recall procedures</label>
                            <textarea type="text" 
                                name="emergencyProcedures" 
                                value={data.emergencyProcedures  || ''} 
                                rows="1"                              
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                required={true}
                                disabled={disabled} />
                        </div>
                        {data.id && parseInt(data.status) !== 0 ? 
                        <div className="form-column mb-32">
                            <label>{parseInt(data.status) === 1 ? 'Approved' : 'Rejected'} by</label>
                            <div>{data.dsoname}</div>
                        </div> : ''}
                        {data.id && parseInt(data.status) === 2 ? 
                        <div className="form-column mb-32">
                            <label>Rejection reason:</label>
                            <div>{data.reason}</div>
                        </div> : ''}
                        <div className="form-submit">
                            {disabled ? '' : <div><button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button></div>}
                            {error ? <div className="error">{error}</div> : ''}
                            {success ? <div className="success">Dive plan was successfully {params.planId ? 'updated' : 'added'}</div> : ''}
                        </div>
                    </form>
                    {data.id && parseInt(data.status) !== 1 && permissions.indexOf('Edit dive plan') >= 0 ? 
                        <form className="confirm-dive-plan" onSubmit={handleSubmitStatus}>
                            <h3>Dive plan status</h3>
                            <div className="radio-group">
                                <div className="radio">
                                    <input type="radio" name="confirmation" value="1" checked={1 === parseInt(status)} onChange={(e) => handleStatus(e.target.value)} id="confirmation_app"  />
                                    <label htmlFor="confirmation_app">Approved</label>
                                </div>
                                <div className="radio">
                                    <input type="radio" name="confirmation" value="2" checked={2 === parseInt(status)} onChange={(e) => handleStatus(e.target.value)} id="confirmation_dis" />
                                    <label htmlFor="confirmation_dis">Rejected</label>
                                </div>
                            </div>
                            {2 === parseInt(status) ? <div className="form-control">
                                <label>Reason</label>
                                <textarea type="text" 
                                    name="reason" 
                                    value={reason || ''} 
                                    rows="1"                              
                                    onChange={(e) => setReason(e.target.value)} 
                                    required={'2' === status}
                                    />
                            </div> : ''}
                            <div className="form-submit align-left">
                                <div><button type="submit" className="primary">{loadingStatus ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button></div>
                                {errorStatus ? <div className="error">{errorStatus}</div> : ''}
                                {successStatus? <div className="success">Dive plan was successfully {params.planId ? 'updated' : 'added'}</div> : ''}
                            </div>
                        </form> : ''
                    }
                </>
            }
        </>
    )
}

export default Form;
import React, { useState, useCallback, useEffect } from 'react';
import { Routes, Route, Outlet, BrowserRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { orgService } from '../Services';

import Layout from './Common/Layouts/Layout';
import Login from './Auth/Login';
import ResetPassword from './Auth/ResetPassword';
import SignUp from './Auth/SignUp';
import ConfirmEmail from './Auth/ConfirmEmail';
import Loader from './Common/Loader';
import SuggestionBox from './SuggestionBox/Index';
import Logs from './Logs/Index';
import Log from './Logs/Log';
import Profile from './Profile/Index';
import Configuration from './Configuration/Index';
import Reports from './Reports/Index';
import Versions from './Versions/Index';
import DivePlans from './DivePlans/Index';

import { setId, setName, setLogoExt, setVersion } from '../redux/stores/orgSlice';

import '../styles/styles.scss';

export const App = () => {
  const [loading, setLoading] = useState(true);
  const [noHost, setNoHost] = useState(false);
  const local = useSelector((state) => state.org.remember);
  const user = useSelector((state) => state[local ? 'localUser' : 'user'].id);
  const dispatch = useDispatch();

  const fetchData = useCallback(async () => {
    const data = await orgService.getByHost();
    if (data.error === 'no-host')
      setNoHost(true);
    else {
      dispatch(setId(data.org.id));
      dispatch(setName(data.org.name));
      dispatch(setLogoExt(data.org.logoExt));
      dispatch(setVersion(data.version.version));
      document.title = data.org.name;
    }
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
      fetchData();
  }, [fetchData])

  return (
    <>
    {loading ? <Loader /> : 
      (noHost ? 
        <div className="error-404">There's nothing here: 404!</div> :
        <BrowserRouter>
            <Routes>
                <Route element={<ProtectedRoute isAllowed={!!user} />}>
                    <Route index element={<Log />} />
                    <Route path="suggestion-box" element={<SuggestionBox />} />
                    <Route path="logs/*" element={<Logs />} />
                    <Route path="dive-plans/*" element={<DivePlans />} />
                    <Route path="reports/*" element={<Reports />} />
                    <Route path="configuration/*" element={<Configuration />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="versions" element={<Versions />} />
                </Route>
                <Route path="reset-password" element={<ResetPassword />} />
                <Route path="sign-up" element={<SignUp />} />
                <Route path="confirm-email" element={<ConfirmEmail />} />
                <Route path="*" element={<div className="error-404">There's nothing here: 404!</div>} />
            </Routes>
        </BrowserRouter>
      )
      }
    </>
  );
};

export default App;

const ProtectedRoute = ({ isAllowed, children }) => {
    if (!isAllowed) {
      return <Login />;
    } else 
      return <Layout>
        {children ? children : <Outlet />}
      </Layout>;
};
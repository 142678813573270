import React, {useState, useEffect, useCallback} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Loader from '../Common/Loader';

import { reportService } from '../../Services';

import { setFilter } from '../../redux/stores/orgSlice';

import {ReactComponent as Copy} from '../../images/icons/General/copy-01.svg';

export const Divers = () => {
    const [divers, setDivers] = useState();
    const [loading, setLoading] = useState(false);
    const filter = useSelector((state) => state.org.filter.diver ?? null);
    const dispatch = useDispatch();

    const changeFilter = (value) => {
        dispatch(setFilter({
            name: 'diver',
            value
        }));
    }

    const copy = async () => {
        const emails = divers.filter((item) => (filter === null || parseInt(item.status) === filter)).map(item => item['Email']).join(';');
        await navigator.clipboard.writeText(emails);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await reportService.diversReport();
        setDivers(res.divers);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {loading ? <Loader /> : <>
                <h1 id="report-title">Divers Contacts</h1>
                {divers ? <div id="report">
                    <div className="bordered-content">
                        <div className="header">
                            <button className="default" onClick={copy}><Copy />Copy these divers' email addresses to clipboard</button>
                        </div>
                    </div>
                    <div className="bordered-content mt-32">
                        <div className="header">
                        <p>To e-mail individual divers, please click on their names:</p>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filter === null ? 'active' : ''} onClick={() =>changeFilter(null)}>View all</button>
                                <button type="button" className={filter === 1 ? 'active' : ''} onClick={() =>changeFilter(1)}>Active</button>
                                <button type="button" className={filter === 0 ? 'active' : ''} onClick={() =>changeFilter(0)}>Inactive</button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr className="desktop">
                                    <th style={{width: '5%'}}>#</th>
                                    <th>Last Name, First Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {divers.filter((item) => (filter === null || parseInt(item.status) === filter)).map((item, key) => {
                                    return (
                                        <tr key={key} className={item.groupId < 3 ? 'not-approved' : ''}>
                                            <td className="desktop">{key + 1}</td>
                                            <td className="mobile-bold"><a href={`mailto:${item.Email}`} target="_blank" rel="noreferrer">{item.LastName}, {item.FirstName}</a></td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </div> : ''}
            </>}
        </>
    );
};

export default Divers;
import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Message} from '../../images/icons/Communication/message-text-square-02.svg';

import { usersService } from '../../Services';

import Modal from '../Common/Modal';
import Loader from '../Common/Loader';

const DSO = ({data, changeValue, changeSelectValue, userId}) => {
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [depthCertification, setDepthCertification] = useState({});
    const [logField, setLogField] = useState(null);

    // eslint-disable-next-line
    const [depthCert, setDepthCert] = useState([
        { value: '30', label: '>30ft' },
        { value: '60', label: '>60ft' },
        { value: '100', label: '>100ft' },
        { value: '130', label: '>130ft' },
        { value: '150', label: '>150ft' },
        { value: '190', label: '>190ft' }
    ]);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const logs = (field, fieldName) => {   
        setLogField({field, fieldName});
    }

    const formatDate = (date) => {
        let newDate = new Date(date);
        return date ? (newDate.getMonth() + 1) + '/' + newDate.getDate() + '/' + newDate.getFullYear() : '';
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let sendData = {};
        for (const [key, value] of Object.entries(data)) {
            if (typeof value == 'object' && value) {
                let date = new Date(value);
                sendData[key] = (key, date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate());
            }
        }

        const res = await usersService.edit({
            'editType': 'DSO',
            ...data,
            ...sendData
        }, (userId || 0));

        if (res.res === 'OK') {
            setSuccess(true);
        } else {
            setError(res.error);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (data.DepthCertification)
            setDepthCertification(depthCert.filter(item => parseInt(item.value) === parseInt(data.DepthCertification)));
    }, [setDepthCertification, data, depthCert])

    return (
        <>
            
            <form onSubmit={userId ? handleSubmit : () => {}} className={(error ? 'required' : '') + ' ' + (userId ? '' : 'disabled-info')}>
                <div className="form-column">
                    <label>Other Information</label>
                    <div className="checkboxes blue">
                        {userId ? <div className="checkbox">
                            <input type="checkbox" name="status" id="status" 
                                checked={data.status ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.status ? 0 : 1))} 
                                 />
                            <label htmlFor="status">Active status</label>                            
                            <Message className="logs-icon" onClick={() => logs('Status', 'Active status')} />
                        </div> : ''}
                        <div className="checkbox">
                            <input type="checkbox" name="CurrentYearWaiver" id="CurrentYearWaiver" 
                                checked={data.CurrentYearWaiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.CurrentYearWaiver ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="CurrentYearWaiver">Current waiver</label>                            
                            {userId ? <Message className="logs-icon" onClick={() => logs('CurrentYearWaiver', 'Current waiver')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="DrySuit" id="DrySuit" 
                                checked={data.DrySuit ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.DrySuit ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="DrySuit">Dry suit</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('DrySuit', 'Dry suit')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="AgaMask" id="AgaMask" 
                                checked={data.AgaMask ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.AgaMask ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="AgaMask">Full face mask</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('AgaMask', 'Full face mask')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="DiveComputer" id="DiveComputer" 
                                checked={data.DiveComputer ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.DiveComputer ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="DiveComputer">Dive computer</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('DiveComputer', 'Dive computer')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Nitrox" id="Nitrox" 
                                checked={data.Nitrox ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Nitrox ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Nitrox">Nitrox</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Nitrox', 'Nitrox')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="MixedGas" id="MixedGas" 
                                checked={data.MixedGas ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.MixedGas ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="MixedGas">Mixed gas</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('MixedGas', 'Mixed gas')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="ClosedCircuit" id="ClosedCircuit" 
                                checked={data.ClosedCircuit ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.ClosedCircuit ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="ClosedCircuit">Closed circuit rebreather</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('ClosedCircuit', 'Closed circuit rebreather')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="SemiClosedCircuit" id="SemiClosedCircuit" 
                                checked={data.SemiClosedCircuit ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.SemiClosedCircuit ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="SemiClosedCircuit">Semi-closed circuit rebreather</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('SemiClosedCircuit', 'Semi-closed circuit rebreather')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Saturation" id="Saturation" 
                                checked={data.Saturation ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Saturation ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Saturation">Saturation</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Saturation', 'Saturation')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Decompression" id="Decompression" 
                                checked={data.Decompression ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Decompression ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Decompression">Decompression</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Decompression', 'Decompression')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="BlueWater" id="BlueWater" 
                                checked={data.BlueWater ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.BlueWater ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="BlueWater">Blue water</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('BlueWater', 'Blue water')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Altitude" id="Altitude" 
                                checked={data.Altitude ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Altitude ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Altitude">Altitude</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Altitude', 'Altitude')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="IcePolar" id="IcePolar" 
                                checked={data.IcePolar ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.IcePolar ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="IcePolar">Ice/polar</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('IcePolar', 'Ice/polar')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Cave" id="Cave" 
                                checked={data.Cave ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Cave ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Cave">Cave</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Cave', 'Cave')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Cavern" id="Cavern" 
                                checked={data.Cavern ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Cavern ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Cavern">Cavern</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Cavern', 'Cavern')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="Night" id="Night" 
                                checked={data.Night ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.Night ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="Night">Night</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('Night', 'Night')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="TeamLeader" id="TeamLeader" 
                                checked={data.TeamLeader ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.TeamLeader ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="TeamLeader">Lead diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('TeamLeader', 'Lead diver')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="ScientificDiver" id="ScientificDiver" 
                                checked={data.ScientificDiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.ScientificDiver ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="ScientificDiver">Scientific diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('ScientificDiver', 'Scientific diver')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="ScientificDiverInTraining" id="ScientificDiverInTraining" 
                                checked={data.ScientificDiverInTraining ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.ScientificDiverInTraining ? 0 : 1))} 
                                disabled={userId ? (data.ScientificDiver ? false : true) : true} />
                            <label htmlFor="ScientificDiverInTraining">In training</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('ScientificDiverInTraining', 'Scientific diver in training')} /> : ''}
                        </div>
                        <div className="checkbox">
                            <input type="checkbox" name="AquariumDiver" id="AquariumDiver" 
                                checked={data.AquariumDiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.AquariumDiver ? 0 : 1))} 
                                disabled={userId ? false : true} />
                            <label htmlFor="AquariumDiver">Aquarium diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('AquariumDiver', 'Aquarium diver')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="ExhibitDiver" id="ExhibitDiver" 
                                checked={data.ExhibitDiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.ExhibitDiver ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="ExhibitDiver">Exhibit diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('ExhibitDiver', 'Exhibit diver')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="PresentationDiver" id="PresentationDiver" 
                                checked={data.PresentationDiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.PresentationDiver ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="PresentationDiver">Presentation diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('PresentationDiver', 'Presentation diver')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="SharkLagoonDiver" id="SharkLagoonDiver" 
                                checked={data.SharkLagoonDiver ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.SharkLagoonDiver ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="SharkLagoonDiver">Shark diver</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('SharkLagoonDiver', 'Shark diver')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="NewDiverTrainer" id="NewDiverTrainer" 
                                checked={data.NewDiverTrainer ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.NewDiverTrainer ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="NewDiverTrainer">New diver trainer</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('NewDiverTrainer', 'New diver trainer')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="NewDiverTraining" id="NewDiverTraining" 
                                checked={data.NewDiverTraining ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.NewDiverTraining ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="NewDiverTraining">New diver training complete</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('NewDiverTraining', 'New diver training complete')} /> : ''}
                        </div>
                        <div className="checkbox shift">
                            <input type="checkbox" name="PresentationTrainer" id="PresentationTrainer" 
                                checked={data.PresentationTrainer ? 1 : 0} 
                                onChange={(e) => changeValue(e.target.name, (data.PresentationTrainer ? 0 : 1))} 
                                disabled={userId ? (data.AquariumDiver ? false : true) : true} />
                            <label htmlFor="PresentationTrainer">Presentation trainer</label>
                            {userId ? <Message className="logs-icon" onClick={() => logs('PresentationTrainer', 'Presentation trainer')} /> : ''}
                        </div>
                    </div>
                </div>
                <div className="form-column">
                    <label>Medical exam date{userId ? <Message className="logs-icon" onClick={() => logs('MedicalDate', 'Medical exam date')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="MedicalDate" selected={data.MedicalDate} onChange={(value) => changeValue('MedicalDate', value)} placeholderText="Select date" className="required" isClearable /> : formatDate(data.MedicalDate)}
                </div>  
                <div className="form-column">
                    <label>Medical expiration{userId ? <Message className="logs-icon" onClick={() => logs('MedicalExpiration', 'Medical expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="MedicalExpiration" selected={data.MedicalExpiration} onChange={(value) => changeValue('MedicalExpiration', value)} placeholderText="Select date" className="required" isClearable /> : formatDate(data.MedicalExpiration)}
                </div>
                <div className="form-column">
                    <label>Neurological exam training expiration{userId ? <Message className="logs-icon" onClick={() => logs('NeuroExam', 'Neurological exam training expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="NeuroExam" selected={data.NeuroExam} onChange={(value) => changeValue('NeuroExam', value)} placeholderText="Select date" isClearable /> : formatDate(data.NeuroExam)}
                </div>
                <div className="form-column">
                    <label>CPR training agency{userId ? <Message className="logs-icon" onClick={() => logs('CPRAgency', 'CPR training agency{')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <input type="text" name="CPRAgency" value={data.CPRAgency || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} /> : data.CPRAgency}
                </div>
                <div className="form-column">
                    <label>CPR expiration{userId ? <Message className="logs-icon" onClick={() => logs('CPRExpiration', 'CPR expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="CPRExpiration" selected={data.CPRExpiration} onChange={(value) => changeValue('CPRExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.CPRExpiration)}
                </div>
                <div className="form-column">
                    <label>O2 administration agency{userId ? <Message className="logs-icon" onClick={() => logs('O2Agency', 'O2 administration agency')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <input type="text" name="O2Agency" value={data.O2Agency || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} /> : data.O2Agency}
                </div>
                <div className="form-column">
                    <label>O2 expiration{userId ? <Message className="logs-icon" onClick={() => logs('O2Expiration', 'O2 expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="O2Expiration" selected={data.O2Expiration} onChange={(value) => changeValue('O2Expiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.O2Expiration)}
                </div>
                <div className="form-column">
                    <label>Fill Station Operator (FSO) expiration{userId ? <Message className="logs-icon" onClick={() => logs('FSOExpiration', 'Fill Station Operator (FSO) expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="FSOExpiration" selected={data.FSOExpiration} onChange={(value) => changeValue('FSOExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.FSOExpiration)}
                </div>
                <div className="form-column">
                    <label>AED expiration{userId ? <Message className="logs-icon" onClick={() => logs('AEDExpiration', 'AED expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="AEDExpiration" selected={data.AEDExpiration} onChange={(value) => changeValue('AEDExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.AEDExpiration)}
                </div>
                <div className="form-column">
                    <label>First aid for diving agency{userId ? <Message className="logs-icon" onClick={() => logs('FirstAidAgency', 'First sid for diving agency')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <input type="text" name="FirstAidAgency" value={data.FirstAidAgency || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} /> : data.FirstAidAgency}
                </div>
                <div className="form-column">
                    <label>First aid expiration{userId ? <Message className="logs-icon" onClick={() => logs('FirstAidExpiration', 'First sid expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="FirstAidExpiration" selected={data.FirstAidExpiration} onChange={(value) => changeValue('FirstAidExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.FirstAidExpiration)}
                </div>
                <div className="form-column">
                    <label>BC service expiration{userId ? <Message className="logs-icon" onClick={() => logs('BCServiceExpiration', 'BC service expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="BCServiceExpiration" selected={data.BCServiceExpiration} onChange={(value) => changeValue('BCServiceExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.BCServiceExpiration)}
                </div>
                <div className="form-column">
                    <label>Regulator service expiration{userId ? <Message className="logs-icon" onClick={() => logs('RegulatorServiceExpiration', 'Regulator service expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="RegulatorServiceExpiration" selected={data.RegulatorServiceExpiration} onChange={(value) => changeValue('RegulatorServiceExpiration', value)} placeholderText="Select date" isClearable /> : formatDate(data.RegulatorServiceExpiration)}
                </div>
                <div className="form-column">
                    <label>Gauge/computer expiration{userId ? <Message className="logs-icon" onClick={() => logs('GaugeCompExp', 'Gauge/computer expiration')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="GaugeCompExp" selected={data.GaugeCompExp} onChange={(value) => changeValue('GaugeCompExp', value)} placeholderText="Select date" isClearable /> : formatDate(data.GaugeCompExp)}
                </div>
                <div className="form-column">
                    <label>Depth certification{userId ? <Message className="logs-icon" onClick={() => logs('DepthCertification', 'Depth certification')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <Select className={`select ${depthCertification ? '' : 'required'}`} options={depthCert} onChange={(value) => changeSelectValue('DepthCertification', value, setDepthCertification)} styles={customStyles} required value={depthCertification} /> : (data.DepthCertification ? '>'+ data.DepthCertification + 'ft' : '')}
                </div>
                <div className="form-column">
                    <label>Diving authorization date with the current OM{userId ? <Message className="logs-icon" onClick={() => logs('StartDate', 'Diving authorization date with the current OM')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="StartDate" selected={data.StartDate} onChange={(value) => changeValue('StartDate', value)} placeholderText="Select date" className="required" isClearable /> : formatDate(data.StartDate)}
                </div>
                <div className="form-column">
                    <label>Original diving authorization{userId ? <Message className="logs-icon" onClick={() => logs('OriginalDivingAuth', 'Original diving authorization')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="OriginalDivingAuth" selected={data.OriginalDivingAuth} onChange={(value) => changeValue('OriginalDivingAuth', value)} placeholderText="Select date" isClearable /> : formatDate(data.OriginalDivingAuth)}
                </div>
                <div className="form-column">
                    <label>Written scientific diving examination passed{userId ? <Message className="logs-icon" onClick={() => logs('ScientificExam', 'Written scientific diving examination passed')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="ScientificExam" selected={data.ScientificExam} onChange={(value) => changeValue('ScientificExam', value)} placeholderText="Select date" isClearable /> : formatDate(data.ScientificExam)}
                </div>
                <div className="form-column">
                    <label>Most recent checkout dive{userId ? <Message className="logs-icon" onClick={() => logs('RecentCheckoutDive', 'Most recent checkout dive')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <DatePicker name="RecentCheckoutDive" selected={data.RecentCheckoutDive} onChange={(value) => changeValue('RecentCheckoutDive', value)} placeholderText="Select date" isClearable /> : formatDate(data.RecentCheckoutDive)}
                </div>
                <div className="form-column">
                    <label>Comments{userId ? <Message className="logs-icon" onClick={() => logs('CurrentYearWaiver')} /> : ''}<span>(Optional)</span></label>
                    {userId ? <textarea name="Comments" value={data.Comments || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} /> : data.Comments}
                </div>
                {userId ? <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Profile was successfully updated</div> : ''}
                </div> : ''}
            </form>
            {logField ? <ModalLog setClose={() => setLogField(null)} userId={userId} logField={logField} /> : ''}
        </>
    )
};

export default DSO;

const ModalLog = ({setClose, userId, logField}) => {
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await usersService.getLogs({
            userId,
            logField: logField.field
        });
        setList(res.list);

        setLoading(false);
      }, [userId, logField]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <Modal setClose={setClose} icon={<Message />} type="logs">
            {loading ? <Loader /> : <>
                <h4>Change log for {logField.fieldName}</h4>
                {list.length ? <div className="bordered-content">
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '20%'}}>Date</th>
                                    <th style={{width: '20%'}}>Updated by</th>
                                    <th style={{width: '20%'}}>Organization</th>
                                    <th style={{width: '20%'}}>Previous value</th>
                                    <th style={{width: '20%'}}>New value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item) => {
                                    return (
                                        <tr key={item.id}>
                                            <td className="mobile-bold">{item.date}</td>
                                            <td><strong className="mobile">DSO: </strong>{item.dso}</td>
                                            <td><strong className="mobile">OM: </strong>{item.org}</td>
                                            <td><strong className="mobile">Previous value: </strong>{item.oldValue}</td>
                                            <td><strong className="mobile">New value: </strong>{item.newValue}</td>
                                            
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div> : <p>Log is empty</p>}
            </>}
        </Modal>
    )
}
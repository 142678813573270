import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { plansService } from '../../Services';

import {ReactComponent as Printer} from '../../images/icons/Media/printer.svg';

import Loader from '../Common/Loader';

export const Print = () => {
    const params = useParams();
    const [data, setData] = useState({});
    const [pageLoading, setPageLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [divers, setDivers] = useState([]);

    const local = useSelector((state) => state.org.remember);
    const name = useSelector((state) => state[local ? 'localUser' : 'user'].name);

    const printPlan = () => {
        window.frames["print_frame"].document.body.innerHTML = '<h2>Dive plan</h2>' + document.getElementById("print-plan").outerHTML + 
            // eslint-disable-next-line
            '<style>table {border: 1px solid #000; font-family: Tahoma; border-spacing: 0; page-break-after: always; width: 100%;}\
                td,th {border-bottom: 1px solid #000; border-right: 1px solid #000; padding: 5px 10px;}\
                td:last-child,th:last-child{border-right: 0;}\
                td .mobile {display: none;}\
                tr:last-child td { border-bottom: 0;}\
                body {font-family: Tahoma; line-height: 1.5;}\
                label {display: block; font-weight: bold;}\
                .form-control{margin: 10px 0;}\
                .emergency-plan {page-break-before: always; page-break-after: always;}\
                ol li {margin-bottom: 10px;}</style>';
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
    }

    const fetchData = useCallback(async (last) => {
        setPageLoading(true);

        if (params.planId) {
            const resData = await plansService.editInfo(params.planId);
            setData(resData.plan);
            setDate(new Date(resData.plan['date'].date));
            setDateStart(new Date(resData.plan['dateStart'].date));
            setDateEnd(new Date(resData.plan['dateEnd'].date));
            setDivers(resData.users ? resData.users.filter(item => resData.plan.divers.split(',').includes(item.value.toString())) : '');
        }

        setPageLoading(false);
    }, [params]);


    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {pageLoading ? <Loader /> : 
                <>
                    <h1>Dive plan</h1>
                    <div className="right print-button">
                        <button className="default" onClick={printPlan}><Printer />Print</button>
                        <iframe name="print_frame" title="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
                    </div>
                    <div id="print-plan">
                        <div className="form-control mb-32">
                            <label>Submitted by</label>
                            <div>{data.username}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Submitted date</label>
                            <div>{date ? (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes() : ''}</div>
                        </div>
                        <h3>Divers</h3>
                        {divers.length ? <>
                            <div className="divers-table">
                                <table>
                                    <tbody>
                                    {
                                        divers.sort((a,b) => {
                                            const fieldA = (a['FirstName'] + ' ' + a['LastName']).toUpperCase(); 
                                            const fieldB = (b['FirstName'] + ' ' + b['LastName']).toUpperCase(); 
                                            if (fieldA < fieldB)
                                                return -1;

                                            if (fieldA > fieldB)
                                                return 1;
                                            
                                            return 0;
                                        }).map(item => {
                                            return (
                                                <tr key={item.value}>
                                                    <td>
                                                        <p><strong>{item.FirstName} {item.LastName}</strong></p>
                                                        {item.ScientificDiverInTraining || item.ScientificDiver ? <p><strong>Role:</strong> {item.ScientificDiverInTraining ? 'Scientific diver in training' : (item.ScientificDiver ? 'Scientific diver' : '')}</p> : ''}
                                                        <p><strong>Depth Certification:</strong> {item.DepthCertification}</p>
                                                        <p className={item.MedicalExpiration && new Date(item.MedicalExpiration) < data.dateEnd ? 'red' : ''}><strong>Medical Expiration:</strong> {item.MedicalExpiration}</p>
                                                        <p className={item.CPRExpiration && new Date(item.CPRExpiration) < data.dateEnd ? 'red' : ''}><strong>CPR Expiration:</strong> {item.CPRExpiration}</p>
                                                        <p className={item.O2Expiration && new Date(item.O2Expiration) < data.dateEnd ? 'red' : ''}><strong>O2 Expiration:</strong> {item.O2Expiration}</p>
                                                        <p className={item.AEDExpiration && new Date(item.AEDExpiration) < data.dateEnd ? 'red' : ''}><strong>AED Expiration:</strong> {item.AEDExpiration}</p>
                                                        <p className={item.FirstAidExpiration && new Date(item.FirstAidExpiration) < data.dateEnd ? 'red' : ''}><strong>First Aid Expiration:</strong> {item.FirstAidExpiration}</p>
                                                        <p className={item.BCServiceExpiration && new Date(item.BCServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>BC Service Expiration:</strong> {item.BCServiceExpiration}</p>
                                                        <p className={item.RegulatorServiceExpiration && new Date(item.RegulatorServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>RegulatorServiceExpiration:</strong> {item.RegulatorServiceExpiration}</p>
                                                        <p className={item.GaugeCompExp && new Date(item.GaugeCompExp) < data.dateEnd ? 'red' : ''}><strong>Gauge/Computer Expiration:</strong> {item.GaugeCompExp}</p>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td>
                                            <p>Participants information last updated: <span>{data.diversUpdateDate}</span></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> : ''}
                        <div className="form-control mb-32">
                            <label>Start date</label>
                            <div>{dateStart ? (dateStart.getMonth() + 1) + '/' + dateStart.getDate() + '/' + dateStart.getFullYear() : ''}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>End date</label>
                            <div>{dateEnd ? (dateEnd.getMonth() + 1) + '/' + dateEnd.getDate() + '/' + dateEnd.getFullYear() : ''}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Diving mode(s) and gas(es)</label>
                            <div>{data.divingModes}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Approximate number of proposed dives</label>
                            <div>{data.divesNumber}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Location(s) of proposed dives</label>
                            <div>{data.locations}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Estimated depth(s) and bottom time(s) anticipated</label>
                            <div>{data.depths}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Decompression status and repetitive dive plans (if required)</label>
                            <div>{data.decompressionStatus}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Proposed work, equipment, and boats to be employed</label>
                            <div>{data.proposedWork}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Any hazardous conditions anticipated</label>
                            <div>{data.conditions}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>List of emergency contact numbers appropriate for dive location</label>
                           <div>{data.emergencyContactLocation}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Name, telephone number, and relationship of person to be contacted for each diver in the event of an emergency</label>
                            <div>{data.emergencyContact}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Nearest operational recompression chamber</label>
                            <div>{data.orChamber}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Nearest accessible hospital</label>
                            <div>{data.hospital}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Available means of transport</label>
                            <div>{data.transport}</div>
                        </div>
                        <h3>In water details</h3>
                        <div className="form-control mb-32">
                            <label>Dive buddy assignments and tasks</label>
                            <div>{data.diverTasks}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Goals and objectives</label>
                            <div>{data.goals}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Maximum depth(s) and bottom time</label>
                            <div>{data.maximumDepth}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Gas management plan</label>
                            <div>{data.gasPlan}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Entry, exit, descent, and ascent procedures</label>
                            <div>{data.procedures}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Perceived environmental and operational hazards and mitigations</label>
                            <div>{data.hazards}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Emergency and diver recall procedures</label>
                            <div>{data.emergencyProcedures}</div>
                        </div>
                        {data.id && parseInt(data.status) !== 0 ? 
                        <div className="form-control mb-32">
                            <label>{parseInt(data.status) === 1 ? 'Approved' : 'Rejected'} by</label>
                            <div>{data.dsoname}</div>
                        </div> : ''}
                        {data.id && parseInt(data.status) === 2 ? 
                        <div className="form-control mb-32">
                            <label>Rejection reason:</label>
                            <div>{data.reason}</div>
                        </div> : ''}
                        <div className={`emergency-plan open`}>
                            <h3>Emergency Action Plan</h3>
                            <div>
                                <div>
                                    <p>Depending on and according to the nature of the diving accident:</p>
                                    <ol>
                                        <li>Make appropriate contact with victim or rescue as required.</li>
                                        <li>Establish (A)irway (B)reathing (C)irculation or (C)irculation (A)irway (B)reathing as appropriate.</li>
                                        <li>Stabilize the victim.</li>
                                        <li>Administer 100% oxygen, if appropriate (in cases of Decompression Illness, or Near Drowning).</li>
                                        <li>Call local Emergency Medical System (EMS) for transport to nearest medical treatment facility. Explain the circumstances of the dive incident to the evacuation teams, medics and physicians. Do not assume that they understand why 100% oxygen may be required for the diving accident victim or that recompression treatment may be necessary.</li>
                                        <li>Call appropriate Diving Accident Coordinator for contact with diving physician and recompression chamber, etc.</li>
                                        <li>Notify DSO or designee according to the Emergency Action Plan of the OM.</li>
                                        <li>Complete and submit Incident Report Form (<a href="https://www.aaus.org" target="_blank"rel="noreferrer">www.aaus.org</a>) to the DCB of the organization and the AAUS (Section 2.70 Required Incident Reporting).</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default Print;
import React, { useState} from 'react';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

import { usersService } from '../../Services';

const EmergencyContact = ({data, changeValue, userId}) => {
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.EmergencyFirstName || !data.EmergencyLastName || !data.EmergencyRelationship || !data.EmergencyPhone)
            message = 'Please, enter required fields';

        if (!message) {
            const res = await usersService.edit({
                'editType': 'EmergencyContact',
                'EmergencyFirstName': data.EmergencyFirstName,
                'EmergencyLastName': data.EmergencyLastName,
                'EmergencyRelationship': data.EmergencyRelationship,
                'EmergencyPhone': data.EmergencyPhone,
                'EmergencyPhoneAlternative': data.EmergencyPhoneAlternative
            }, (userId || 0));

            if (res.res === 'OK') {
                setSuccess(true);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    return (
        <>
            <p>Please specify who should be contacted in case of an emergency</p>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                <div className="form-column">
                    <label>First name</label>
                    <input type="text" name="EmergencyFirstName" placeholder="Enter their first name" value={data.EmergencyFirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Last name</label>
                    <input type="text" name="EmergencyLastName" placeholder="Enter their last name" value={data.EmergencyLastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Relationship</label>
                    <input type="text" name="EmergencyRelationship" placeholder="Spouse, partner, sibling, etc." value={data.EmergencyRelationship || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Best phone number</label>
                    <input type="tel" name="EmergencyPhone" placeholder="Enter their phone number" value={data.EmergencyPhone || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={16} />
                </div>
                <div className="form-column">
                    <label>Alternative phone number<span>(Optional)</span></label>
                    <input type="tel" name="EmergencyPhoneAlternative" placeholder="Enter their alternative phone number" value={data.EmergencyPhoneAlternative || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={16} />
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Profile was successfully updated</div> : ''}
                </div>
            </form>
        </>
    )
};

export default EmergencyContact;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  name: '',
  hid: '',
  photo: '',
  permissions: '',
  is24h: false,
  expirations: {}
};

export const userSlice = createSlice({
  name: 'localUser',
  initialState,
  reducers: {
    setLocalId: (state, action) => {
      state.id = action.payload;
    },
    setLocalName: (state, action) => {
      state.name = action.payload;
    },
    setLocalHid: (state, action) => {
      state.hid = action.payload;
    },
    setLocalPhoto: (state, action) => {
      state.photo = action.payload;
    },
    setLocalPermissions: (state, action) => {
      state.permissions =  action.payload;
    },
    setLocalIs24h: (state, action) => {
      state.is24h =  action.payload;
    },
    setLocalExpirations: (state, action) => {
      state.expirations =  action.payload;
    },
    clearLocalUser: () => initialState
  },
})

// Action creators are generated for each case reducer function
export const { setLocalId, setLocalName, setLocalHid, setLocalPhoto, setLocalPermissions, setLocalIs24h, setLocalExpirations, clearLocalUser } = userSlice.actions;

export default userSlice.reducer
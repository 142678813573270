import { apiUrl } from '.';
import { authService } from './';

const internalReport = async (year) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/reports/internal?year='+year, requestOptions);
    return res;
}

const internalReportExport = async (year) => {
    const requestOptions = {
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
    };

    const res = await authService.handleResponse(apiUrl + '/reports/csv/internal?year=' + year, requestOptions, true);
    return res;
}

const AAUSReport = async (year) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/reports/aaus?year='+year, requestOptions);
    return res;
}

const AAUSReportExport = async (year) => {
    const requestOptions = {
        method: 'GET',
        xhrFields: {
            responseType: 'blob'
        },
    };

    const res = await authService.handleResponse(apiUrl + '/reports/csv/aaus?year=' + year, requestOptions, true);
    return res;
}

const expirationReport = async (data) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/reports/expiration?type='+data.type+'&date='+data.date, requestOptions);
    return res;
}

const diversReport = async () => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/reports/divers', requestOptions);
    return res;
}

export const reportService = {
    internalReport,
    internalReportExport,
    AAUSReport,
    AAUSReportExport,
    expirationReport,
    diversReport
};
import { apiUrl } from '.';
import { authService } from './';

const addLocation = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/locations', requestOptions);
    return res;
}

const editLocation = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/locations', requestOptions);
    return res;
}

const locationStatus = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/locations/status', requestOptions);
    return res;
}

const getLocations = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/locations', requestOptions);
    return res;
}

const addTask = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/tasks', requestOptions);
    return res;
}

const editTask = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/tasks', requestOptions);
    return res;
}

const taskStatus = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/tasks/status', requestOptions);
    return res;
}

const getTasks = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/configuration/tasks', requestOptions);
    return res;
}

const getUsers = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users', requestOptions);
    return res;
}

const userStatus = async (data) => {
    const requestOptions = {
        method: 'PUT',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/users/status', requestOptions);
    return res;
}

export const confService = {
    addLocation,
    editLocation,
    locationStatus,
    getLocations,
    addTask,
    editTask,
    taskStatus,
    getTasks,
    getUsers,
    userStatus
};
const Loader = () => {
    return <div className="loader">
        <div>
            <svg className="waves" viewBox="0 24 150 28">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" />
                <use xlinkHref="#gentle-wave" x="48" y="3" />
                <use xlinkHref="#gentle-wave" x="48" y="5" />
                <use xlinkHref="#gentle-wave" x="48" y="7" />
            </g>
            </svg>
            <div className="text">Loading</div>
        </div>
    </div>
};

export default Loader;
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

import { usersService } from '../../Services';

const Insurance = ({data, changeValue, userId}) => {
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!message) {
            const res = await usersService.edit({
                'editType': 'Insurance',
                'InsuranceCarrier': data.InsuranceCarrier,
                'InsurancePolicy': data.InsurancePolicy,
                'InsuranceExpiration': data.InsuranceExpiration ? data.InsuranceExpiration.getFullYear() + '/' + (data.InsuranceExpiration.getMonth() + 1) + '/' + data.InsuranceExpiration.getDate() : '',
                'MedInsuranceCarrier': data.MedInsuranceCarrier
            }, (userId || 0));

            if (res.res === 'OK') {
                setSuccess(true);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    return (
        <>
            <p>Please provide the insurance policy details</p>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                <div className="form-column">
                    <label>Diving insurance carrier</label>
                    <input type="text" name="InsuranceCarrier" placeholder="Enter company name (DAN, PADI, etc.)" value={data.InsuranceCarrier || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Diving insurance policy #</label>
                    <input type="text" name="InsurancePolicy" placeholder="Enter the policy number" value={data.InsurancePolicy || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={30} />
                </div>
                <div className="form-column">
                    <label>Diving insurance expiration</label>
                    <DatePicker name="InsuranceExpiration" selected={data.InsuranceExpiration} onChange={(value) => changeValue('InsuranceExpiration', value)} placeholderText="Select date" isClearable />
                </div>
                <div className="form-column">
                    <label>Medical insurance carrier</label>
                    <input type="text" name="MedInsuranceCarrier" placeholder="Enter company name (Aetna, Kaiser, etc.)" value={data.MedInsuranceCarrier || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={50} />
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Profile was successfully updated</div> : ''}
                </div>
            </form>
        </>
    )
};

export default Insurance;
import { apiUrl } from '.';
import { authService } from './';

const history = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/versions', requestOptions);
    return res;
}

export const versionService = {
    history
};
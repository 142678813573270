import { apiUrl } from '.';
import { clearUser } from '../redux/stores/userSlice';
import { clearLocalUser } from '../redux/stores/localUserSlice';
import { setRemember, clearFilter } from '../redux/stores/orgSlice';
import { store } from '../redux/store';

const login = async (credentials) => {
    try {
        const response = await fetch(apiUrl + '/auth/om-login', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        const user = await response.json();
        return user;
      } catch (error) {
        return error;
      }
}

const logout = () => {
    store.dispatch(clearUser());
    store.dispatch(clearLocalUser());
    store.dispatch(setRemember(false));
    store.dispatch(clearFilter());
}

const resetPassword = async (credentials) => {
    try {
        const response = await fetch(apiUrl + '/auth/reset-password', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        const user = await response.json();
        return user;
      } catch (error) {
        return error;
      }
}

const confirmPassword = async (credentials) => {
    try {
        const response = await fetch(apiUrl + '/auth/confirm-password', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        });
        const user = await response.json();
        return user;
      } catch (error) {
        return error;
      }
}

const signUp = async (data) => {
    try {
        const response = await fetch(apiUrl + '/auth/sign-up', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const res = await response.json();
        return res;
      } catch (error) {
        return error;
      }
}

const verifyEmail = async (data) => {
    try {
        const response = await fetch(apiUrl + '/auth/verify-email', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const res = await response.json();
        return res;
      } catch (error) {
        return error;
      }
}

const resendEmail = async (data) => {
    try {
        const response = await fetch(apiUrl + '/auth/resend-email', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        });
        const res = await response.json();
        return res;
      } catch (error) {
        return error;
      }
}

const handleResponse = async (url, args, file) => {
    const currentState = store.getState();
    const user = currentState.org.remember ? currentState.localUser : currentState.user;
    try {
        const response = await fetch(url+(url.indexOf('?') > -1 ? '&' : '?' )+'uid='+user.id+'&hid='+user.hid+'&oid='+currentState.org.id, args);
        if (file)
          return response.blob();
        else {
          const data = await response.json();
          if (data.logout)
              logout();
          return data;
        }
    } catch (error) {
        return error;
    }
}

export const authService = {
    handleResponse,
    login,
    logout,
    resetPassword,
    confirmPassword,
    signUp,
    verifyEmail,
    resendEmail
};
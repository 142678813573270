import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import ReactCodeInput from "react-code-input";

import Loader from '../Common/Loader';

import { authService } from '../../Services';

import {ReactComponent as Mail} from '../../images/icons/Communication/mail-01.svg';
import {ReactComponent as ArrowLeft} from '../../images/icons/Arrows/arrow-left.svg';
import {ReactComponent as Check} from '../../images/icons/General/check-circle.svg';
import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

export const ConfirmEmail = () => {
    // eslint-disable-next-line
    const [searchParams, setSearchParams] = useSearchParams();
    const orgId = useSelector((state) => state.org.id);
    const email = useSelector((state) => state.user.email);
    const [manually, setManually] = useState(false);
    const [code, setCode] = useState(searchParams.get("code") || '');
    // eslint-disable-next-line
    const [userEmail, setUserEmail] = useState(searchParams.get("email") || '');
    const [verified, setVerified] = useState(false);
    const [loading, setLoading] = useState(searchParams.get("code") ? true : false);
    const [sentLoading, setSentLoading] = useState(false);
    const [sentError, setSentError] = useState('');
    const [sentSuccess, setSentSuccess] = useState(false);

    const handleCodeChange = code => { 
        setCode(code);
    }

    const verify = async () => {
        setLoading(true);
        const data = await authService.verifyEmail({
            code: code,
            email: email || userEmail,
            orgId: orgId
        });
        setVerified(data.error ? 'no' : 'yes');
        setLoading(false);
    }

    const resend = async () => {
        setSentLoading(true);
        setSentError('');
        setSentSuccess(false);

        let data = await authService.resendEmail({
            email: email,
            orgId: orgId
        });
        if (data.error)
            setSentError(data.error);
        else
            setSentSuccess(true);
            setSentLoading(false);
    }

    const fetchData = useCallback(async () => {
        if (window.location.search) {
            const data = await authService.verifyEmail({
                code: code,
                email: manually ? email : userEmail,
                orgId: orgId
            });
            setVerified(data.error ? 'no' : 'yes');
            setLoading(false);
        }
      }, [setVerified, setLoading, code, email, manually, userEmail, orgId]);

    useEffect(() => {
        fetchData();
    }, [fetchData])

    return (
        <>
            {loading ? <Loader /> : 
                <div className="confirm-wrapper">
                    {verified === 'yes' ? 
                        <div>
                            <span className="icon green">
                                <Check />
                            </span>
                            <h2>Email verified</h2>
                            <p>Your email has been successfully verified. Click below to log in.</p>
                            <Link to="/" className="arrow">
                                <button className="primary">Continue</button>
                            </Link>
                        </div> : 
                        <div>
                            <span className="icon">
                                <Mail />
                            </span>

                            <h2>Check your email</h2>
                            <p>We sent a verification link to <br /><span className="medium">{email}</span></p>
                            {manually ? 
                                <>
                                    <div className="code">
                                        <ReactCodeInput
                                            id="code"
                                            type="number"
                                            fields={4}
                                            onChange={handleCodeChange}
                                            value={code}
                                            pattern="[0-9]*"
                                            
                                        />
                                    </div>
                                    <div className="verify-button">
                                        <button className="primary" onClick={verify}>{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Verify email</button>
                                        {verified === 'no' ? <div className="error">The data is missing or expired. Please, try again</div> : ''}
                                    </div>
                                    <p className="font-14">Didn’t receive the email? <span className="link" onClick={resend}>{sentLoading ? 'E-mail is sending' : 'Click to resend'}</span></p>
                                    {sentError ? <div className="error">{sentError}</div> : ''}
                                    {sentSuccess ? <div className="success">Email was successfully sent</div> : ''}
                                </> : 
                                <>
                                    <div className="verify-button">
                                        <button className="primary" onClick={() => setManually(true)}>Enter code manually</button>
                                        {verified === 'no' ? <div className="error">The data is missing or expired. Please, try again</div> : ''}
                                    </div>
                                </>
                            }

                            <div className="back">
                                <Link to="/" className="arrow">
                                    <ArrowLeft />Back to log in
                                </Link>
                            </div>
                        </div>
                    }
                </div>
            }
        </>
    )
}

export default ConfirmEmail;
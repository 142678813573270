import React, { useState, useCallback, useEffect } from 'react';

import { versionService } from '../../Services';

import Loader from '../Common/Loader';

export const Versions = () => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await versionService.history();
        setInfo(res);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Release notes</h1>
                {info.list ? <>
                    <div className="bordered-content">
                        <table id="history-table">
                            <thead>
                                <tr>
                                    <th style={{width: '15%'}}>Version</th>
                                    <th style={{width: '85%'}} className="light">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {info.list.map(item => {
                                    return (
                                        <tr key={item.id} class="align-top">
                                            <td><strong>2.0.{item.version}</strong></td>
                                            <td className="light" dangerouslySetInnerHTML={{__html: item.description}}></td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </> : <p className="empty">Version history is empty</p>}
            </>}
        </>
    );
};

export default Versions;
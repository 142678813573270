import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { logsService } from '../../Services';

import Loader from '../Common/Loader';
import Modal from '../Common/Modal';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Download} from '../../images/icons/General/download-cloud-02.svg';
import {ReactComponent as Upload} from '../../images/icons/General/upload-cloud-02.svg';
import {ReactComponent as Trash} from '../../images/icons/General/trash-03.svg';
import {ReactComponent as Info} from '../../images/icons/General/info-circle.svg';

export const Import = () => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingTemplate, setLoadingTemplate] = useState(false);
    const [instructions, setInstructions] = useState(true);

    const statuses = {
        0: 'In process',
        1: 'Success',
        2: 'Overlaps another dive'
    };

    const statuseClasses = {
        0: '',
        1: 'Success',
        2: 'Error'
    };

    const downloadTemplate = async () => {
        setLoadingTemplate(true);
        const date = new Date();
        const res = await logsService.importTemplate();
        const a = document.createElement('a');
        let binaryData = [];
        binaryData.push(res);
        let url = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csvapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"}))
        a.href = url;
        a.download = 'dive-import-template_'+window.location.hostname+'_'+moment(date).format('YYYY-MM-DD-HH-MM')+'.xlsx';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setLoadingTemplate(false);
    };

    const clearQueue = async () => {
        setLoadingTemplate(true);
        const res = await logsService.importClear();
        setInfo(res);

        setLoadingTemplate(false);
    };

    const fetchData = useCallback(async (silent) => {
        if (!silent)
            setLoading(true);

        const res = await logsService.importList();
        setInfo(res);

        if (!silent)
            setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();

        const interval = setInterval(() => {
            fetchData(true);
        }, 30000);
 
        //Clearing the interval
        return () => clearInterval(interval);
    }, [fetchData]);
    
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Import dive log</h1>
                <div className="bordered-content">
                    <div className="header">
                        <h4>Import queue {info.list ? <span className="label">{info.divesNumber} dive{info.divesNumber === 1 ? '' : 's'}</span> : ''} <Info onClick={() => setInstructions(true)} /></h4>
                        <p>The items displayed in red overlap with the existing dives</p>
                        <div className="buttons">
                            <Link to="/logs/import/form"><button className="default">{loadingTemplate ? <Loading className="btn-loader" alt="loader" /> : <Upload />}Import</button></Link>
                            {info.list && info.list.length ? <button className="default" onClick={() => clearQueue()}>{loadingTemplate ? <Loading className="btn-loader" alt="loader" /> : <Trash />}Remove all overlapping rows</button> : ''}
                            <button className="default" onClick={downloadTemplate}>{loadingTemplate ? <Loading className="btn-loader" alt="loader" /> : <Download />}Download template</button>
                        </div>
                    </div>
                    {info.list && info.list.length ? 
                        <table className="import-results">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>User</th>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Total time</th>
                                    <th>Location or project</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {info.list.map(item => {
                                    return (
                                        <tr key={item.id} className={statuseClasses[item.status]}>
                                            <td><span className="desktop">{item.email}</span><strong className="mobile">{item.email}</strong></td>
                                            <td><strong className="mobile">User: </strong>{item.userName}</td>
                                            <td><strong className="mobile">Date: </strong>{item.date}</td>
                                            <td><strong className="mobile">Time: </strong>{item.time}</td>
                                            <td><strong className="mobile">Total time: </strong>{item.total}</td>
                                            <td><strong className="mobile">Location: </strong>{item.location}</td>
                                            <td><strong className="mobile">Status: </strong>{statuses[item.status]}</td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>: 
                    <p className="empty">The import queue is empty</p>}
                </div>
                {instructions ? <ModalInstructions setClose={() => setInstructions(false)} downloadTemplate={downloadTemplate} /> : ''}
            </>}
        </>
    );
};

export default Import;

const ModalInstructions = ({setClose, downloadTemplate}) => {
   return (
        <Modal setClose={setClose} icon={<Info />} type="instructions">
            <h2>Instructions</h2>
            <p>In order to import your logs you must first <span className="pseudo-link" onClick={downloadTemplate}>download our template</span> and open it in Microsoft Excel.</p>
            <p>Please make sure you do not rename, delete, move around, or otherwise change the columns and sheets in this template file. You may adjust the widths of columns to see the column headings and entries in each column - upon export these width changes will be ignored.</p>
            <p>The email address of each diver must match what they use to sign into this website.</p>
            <p>The following columns will have dropdowns that will allow you to choose from the preexisting values that are specific to your organization:</p>
            <ul>
                <li>Location or project</li>
                <li>Decompression planning</li>
                <li>Diving mode</li>
                <li>Breathing gas</li>
                <li>Classification</li>
            </ul>
            <p>If you type in something that doesn't exist in the dropdowns, you will have to choose the preexisting values later, after you upload the CSV file.</p>
            <p>Once your existing log data conforms to the template, please go to the "File &gt; Save As" menu and choose "CSV UTF-8 (Comma delimited) (*.csv)" as the target format. Then click "Browse" and save the resulting CSV file to your Desktop.</p>
            <p>While saving Excel will prompt you "The selected file type does not support workbooks that contain multiple sheets. To save only the active sheet, click OK". This is exactly what you want to do, so click OK.</p>
            <p>Click the "Import" button in the right upper portion of the "Logs &gt; Import logs" page.</p>
            <p>Proceed to upload the CSV file you created. Once the file is uploaded, your browser lets you review all entries.</p>
            <p>Don't worry, the data is not placed in the AAUS database just yet!</p>
            <p>All items highlighted in red are the dives that have some missing or incorrectly formatted information.</p>
            <p>At this stage you may still edit the data via your browser. For example to assign individual dives to specific divers, or changing the starting date/time.</p>
            <p>If you leave them as red, they will not be uploaded!</p>
            <p>Once you are satisfied, hit "Submit" either at the top or the bottom of the screen (we placed it in both places for convenience).</p>
            <p>At this point all of the dives that weren't highlighted in red are placed into the import queue.</p>
            <p>The queue automatically processes 100 dives at a time and refreshes the screen every 30 seconds to show you the progress.</p>
        </Modal>
    )
}
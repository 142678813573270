import React, { useState, useReducer, useCallback, useEffect, useRef } from 'react';
import { Link, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { useSelector } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";

import { logsService } from '../../Services';

import Loader from '../Common/Loader';
import Modal from '../Common/Modal';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Copy} from '../../images/icons/General/copy-01.svg';
import {ReactComponent as Help} from '../../images/icons/General/help-circle.svg';
import {ReactComponent as Info} from '../../images/icons/General/info-circle.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Log = () => {
    const params = useParams();
    const [info, setInfo] = useState({});
    const [data, setData] = useReducer(dataReducer, {});
    const [loading, setLoading] = useState(false);
    const [copyLoading, setCopyLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [location, setLocation] = useState({});
    const [decompression, setDecompression] = useState({});
    const [gear, setGear] = useState({});
    const [gasMixture, setGasMixture] = useState({});
    const [surfaceCondition, setSurfaceCondition] = useState({});
    const [underwaterCondition, setUnderwaterCondition] = useState({});
    const [taskValue, setTaskValue] = useState([]);
    const [enviromentValue, setEnviromentValue] = useState([]);
    const [existing, setExisting] = useState(null);
    const [classification, setClassification] = useState(null);

    const local = useSelector((state) => state.org.remember);
    const orgId = useSelector((state) => state.org.id);
    const is24h = useSelector((state) => state[local ? 'localUser' : 'user'].is24h);

    const locationRef = useRef(null);
    const decompressionRef = useRef(null);

    const today = new Date();
    today.setDate(today.getDate() + 1);

    const minTime = new Date();
    minTime.setHours(0);
    minTime.setMinutes(0);

    const maxTime = new Date();
    maxTime.setHours(23);
    maxTime.setMinutes(59);

    const enviroments = ['Required Decompression', 'Overhead Environment', 'Blue Water', 'Ice/Polar', 'Saturation Diving', 'Aquarium Diving'];

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const clearData = () => {
        setLocation({}); 
        setDecompression({}); 
        setGear({value: '1', label: 'SCUBA'});
        setGasMixture({});
        setSurfaceCondition({}); 
        setUnderwaterCondition({});
        setTaskValue([]); 
        setEnviromentValue([]);
        setExisting(null);
        setError(null);
        setData({
            action: 'clear'
        });
        setData({
            key: 'gear',
            value: '1'
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        if (params.diveId)
            data.id = params.diveId;

        if (params.userId)
            data.userId = params.userId;

        let message = '';

        if (!data.date || !data.startTime || !data.totalTime || 
                !data.location || !data.specificLocation || !data.diveDepth || !data.decompressionPlanning ||
                !data.gear || !data.gasMixture || !data.classificationId)
            message = 'Please, enter required fields';

        if (data.date && today.toDateString() === data.date.toDateString() && data.startTime > new Date())
            message = 'Who do you think you are, Nostradamus? The date and time must be in the past';

            if (!message) {
                const res = await (params.diveId ? logsService.edit({
                    ...data,
                    date: data.date instanceof Date ? data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate() : data.date,
                    startTime: data.startTime instanceof Date ? data.startTime.getHours() + ':' + data.startTime.getMinutes() : data.startTime
                }) : logsService.add({
                    ...data,
                    date: data.date instanceof Date ? data.date.getFullYear() + '-' + (data.date.getMonth() + 1) + '-' + data.date.getDate() : data.date,
                    startTime: data.startTime instanceof Date ? data.startTime.getHours() + ':' + data.startTime.getMinutes() : data.startTime
                }));
    
                if (res.res === 'OK') {
                    if (!params.diveId) {
                        clearData();
                        setInfo({
                            ...info,
                            divesNumber: info.divesNumber + 1
                        })
                    }
                    setSuccess(true);
                } else {
                    setError(res.error);
                    setExisting(res.existing);
                }
            } else {
                setError(message);
            }
            setLoading(false);
    };

    const changeValue = (key, value) => {
        setData({
            key: key,
            value: value,
        }); 
    }

    const changeSelectValue = (name, value, selectSetVar) => {
        selectSetVar(value);
        setData({
            key: name,
            value: value.value,
        }); 

        if (name === 'location') {
            setData({
                key: 'diveDepth',
                value: value.Depth || 0,
            }); 
        }
    }

    const changeCheckboxValue = (key, value, cbVar, cbSetVar) => {
        if (cbVar.indexOf(value) > -1) 
            cbVar.splice(cbVar.indexOf(value), 1)
        else 
            cbVar.push(value);
        
        cbSetVar(cbVar);
        setData({
            key: key,
            value: cbVar,
        }); 
    }

    const copyLastDive = async () => {
        setCopyLoading(true);

        const resData = await logsService.editInfo(-1, params.userId);
        Object.keys(resData.dive).forEach((key) => {
            switch (key) {  
                case 'date':
                case 'startTime':
                    resData.dive[key] = new Date(resData.dive[key].date);
                break;

                case 'location':
                    setLocation(info.locations ? info.locations.filter(item => item.value === resData.dive[key]) : '');
                break;
                
                case 'decompressionPlanning':
                    setDecompression(info.decompression.filter(item => item.value === resData.dive[key]));
                break;

                case 'gear':
                    setGear(info.gears.filter(item => item.value === resData.dive[key]));
                break;

                case 'gasMixture':
                    setGasMixture(info.gasMixtures.filter(item => item.value === resData.dive[key]));
                break;

                case 'surfaceCondition':
                    setSurfaceCondition(info.surfaceConditions.filter(item => item.value === resData.dive[key]));
                break;

                case 'underwaterCondition':
                    setUnderwaterCondition(info.underwaterConditions.filter(item => item.value === resData.dive[key]));
                break;

                case 'tasks':
                    setTaskValue(resData.dive[key]);
                break;

                case 'enviroments':
                    setEnviromentValue(resData.dive[key]);
                break;

                default:
            }   

            setData({
                key: key,
                value: resData.dive[key]
            });
        });

        setCopyLoading(false);
    }

    const fetchData = useCallback(async (last) => {
        setPageLoading(true);
        const surfaceConditions = [
            {value: 1, label: 'Calm'},
            {value: 2, label: 'Moderate'},
            {value: 3, label: 'Rough'}
        ];

        const underwaterConditions = [
            {value: 1, label: 'Good'},
            {value: 2, label: 'Moderate'},
            {value: 3, label: 'Poor'}
        ];


        const res = await logsService.addInfo(params.userId || 0);
        setInfo({
            ...res,
            surfaceConditions: surfaceConditions,
            underwaterConditions: underwaterConditions
        });
        if (res.error)
            setError(res.error);

            if (params.diveId) {
                const resData = await logsService.editInfo(params.diveId);
                Object.keys(resData.dive).forEach((key) => {
                    switch (key) {  
                        case 'date':
                        case 'startTime':
                            resData.dive[key] = new Date(resData.dive[key].date);
                        break;
    
                        case 'location':
                            setLocation(res.locations ? res.locations.filter(item => item.value === resData.dive[key]) : '');
                        break;
                        
                        case 'decompressionPlanning':
                            setDecompression(res.decompression.filter(item => item.value === resData.dive[key]));
                        break;
    
                        case 'gear':
                            setGear(res.gears.filter(item => item.value === resData.dive[key]));
                        break;
    
                        case 'gasMixture':
                            setGasMixture(res.gasMixtures.filter(item => item.value === resData.dive[key]));
                        break;

                        case 'surfaceCondition':
                            setSurfaceCondition(surfaceConditions.filter(item => item.value === resData.dive[key]));
                        break;

                        case 'underwaterCondition':
                            setUnderwaterCondition(underwaterConditions.filter(item => item.value === resData.dive[key]));
                        break;
    
                        case 'tasks':
                            setTaskValue(resData.dive[key]);
                        break;
    
                        case 'enviroments':
                            setEnviromentValue(resData.dive[key]);
                        break;
    
                        default:
                    }   
    
                    setData({
                        key: key,
                        value: resData.dive[key]
                    });
                });
            } else {
                setGear({value: '1', label: 'SCUBA'});
                setData({
                    key: 'gear',
                    value: '1'
                });
            }
        

        setPageLoading(false);
      }, [params]);

    useEffect(() => {
        clearData();
        fetchData();
    }, [fetchData]);
    
    return (
        <>
            {pageLoading ? <Loader /> : 
                <>
                    <h1>{params.diveId ? 'Edit dive' : 'Log new dives'}</h1>
                    {(params.diveId || !info.divesNumber) ? '' : <div>
                        <button type="button" className="default" onClick={copyLastDive} disabled={copyLoading}>{copyLoading ? <Loading className="btn-loader" /> : <Copy />}Copy last dive values</button>
                    </div>}
                    <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                        <div className="form-column">
                            <label>Diver</label>
                            <div>{info.userName} (<Link to={params.userId ? `/configuration/users/${params.userId}/dives/` : `/logs/history`}>{info.divesNumber} logged dive{info.divesNumber === 1 ? '' : 's'}</Link>)</div>
                        </div>
                        <div className="form-column">
                            <label>Surface interval since the prior dive<span className="hint"><Help /><span>e.g. “&gt;24 hrs”, “HH:MM ”</span></span><span>(Optional)</span></label>
                            <input type="text" name="surfaceInterval" value={data.surfaceInterval || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength="10" />
                        </div>
                        <div className="form-column mb-32">
                            <label>Dive date</label>
                            <DatePicker 
                                name="date" 
                                selected={data.date} 
                                maxDate={today} 
                                onChange={(value) => changeValue('date', value)}  
                                placeholderText="Select date" 
                                className="required"
                                autoComplete="off"
                                required={true}
                                isClearable
                             />
                        </div>
                        <div className="form-column">
                            <label>Dive start time<span>Please log only the dives that already happened</span></label>
                            <DatePicker 
                                name="startTime" 
                                onChange={(value) => changeValue('startTime', value)} 
                                minTime={minTime}
                                maxTime={(data.date && today.toDateString() === data.date.toDateString()) ? new Date() : maxTime} 
                                selected={data.startTime || ''} 
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={15}
                                timeFormat={is24h > 0 ? 'HH:mm' : 'hh:mm a'} 
                                dateFormat={is24h > 0 ? 'HH:mm' : 'hh:mm a'} 
                                isClearable
                                autoComplete="off"
                                required={true}
                            />
                        </div>
                        <div className="form-column">
                            <label>Total time of dive<span>Enter minutes, surface to surface</span></label>
                            <input type="text" 
                                name="totalTime" 
                                value={data.totalTime || ''} 
                                maxLength="3"  
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                onKeyUp={(e) => {
                                    if (e.target.value.length > 2) locationRef.current.focus();
                                }}
                                required={true} />
                        </div>
                        <div className="form-column">
                            <label>Location or project</label>
                            <Select ref={locationRef} className="select" required 
                                options={info.locations} 
                                onChange={(value) => changeSelectValue('location', value, setLocation)} 
                                styles={customStyles}  
                                value={location}
                            />
                        </div>
                        <div className="form-column">
                            <label>Specific location<span>Dive site name or the best description</span></label>
                            <input type="text" name="specificLocation" value={data.specificLocation || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={70} />
                        </div>
                        <div className="form-column">
                            <label>Depth (ft)</label>
                            <input type="number" step="1" name="diveDepth" 
                                disabled={data.location !== 1 && data.location !== 2 && location.Depth > 0 ? true : false} 
                                value={data.location ? (data.location !== 1 && data.location !== 2 && location.Depth > 0 ? location.Depth : data.diveDepth) : 0} 
                                maxLength="3"
                                onChange={(e) => changeValue(e.target.name, e.target.value)} 
                                onKeyUp={(e) => {
                                    if (e.target.value.length > 2) decompressionRef.current.focus();
                                }}
                                onWheel={(e) => e.target.blur()}
                                required={true} />
                        </div>
                        <div className="form-column">
                            <label>Decompression planning</label>
                            <Select ref={decompressionRef} className="select" required 
                                options={info.decompression} 
                                onChange={(value) => changeSelectValue('decompressionPlanning', value, setDecompression)} 
                                styles={customStyles}  
                                value={decompression} />
                        </div>
                        <div className="form-column">
                            <label>Diving mode</label>
                            <Select className="select" required 
                                options={info.gears} 
                                onChange={(value) => changeSelectValue('gear', value, setGear)} 
                                styles={customStyles} 
                                value={gear} 
                                defaultValue={info.gears ? info.gears[15] : null} />
                        </div>
                        <div className="form-column">
                            <label>Breathing gas</label>
                            <Select className="select" required 
                                options={info.gasMixtures} 
                                onChange={(value) => changeSelectValue('gasMixture', value, setGasMixture)} 
                                styles={customStyles} 
                                value={gasMixture} 
                                defaultValue={info.gasMixtures ? info.gasMixtures[0] : null} />
                        </div>
                        <div className="form-column">
                            <label>Classification</label>
                            <div className="radios blue">
                                {info.classifications ? <>
                                    {info.classifications.map(classification => (
                                        <div className="radio" key={classification['classificationId']}>
                                            <input type="radio" name="classificationId" 
                                                value={classification['classificationId'].toString()} 
                                                checked={parseInt(data.classificationId) === parseInt(classification['classificationId'])}
                                                id={`classification_${classification['classificationId']}`} 
                                                onChange={(e) => changeValue(e.target.name, e.target.value)}
                                                 />
                                            <label htmlFor={`classification_${classification['classificationId']}`}>
                                                {classification['classification']} <Help onClick={() => setClassification(classification)}  />
                                            </label>
                                        </div>
                                    ))}
                                </> : ''}
                            </div>
                        </div>
                        <div className="form-column">
                            <label>Tasks<span>Optional</span></label>
                            <div className="checkboxes blue">
                                {info.tasks ? <>
                                    {info.tasks.map(task => (
                                        <div className="checkbox" key={task['TaskID']}>
                                            <input type="checkbox" name="tasks" 
                                                value={task['TaskID'].toString()} 
                                                checked={taskValue.indexOf(task['TaskID'].toString()) > -1 ? true : false}
                                                id={`task_${task['TaskID']}`} 
                                                onChange={(e) => changeCheckboxValue(e.target.name, e.target.value, taskValue, setTaskValue)}
                                                 />
                                            <label htmlFor={`task_${task['TaskID']}`}>{task['Task']}</label>
                                        </div>
                                    ))}
                                </> : ''}
                            </div>
                        </div>
                        <div className="form-column">
                            <label>Specialized environment<span>(Optional)</span></label>
                            <div className="checkboxes blue">
                                {enviroments ? <>
                                    {enviroments.map(enviroment => (
                                        <div className="checkbox" key={enviroment}>
                                            <input type="checkbox" name="enviroments" 
                                                value={enviroment.replace(' ', '').replace('/', '')} 
                                                checked={enviromentValue.indexOf(enviroment.replace(' ', '').replace('/', '')) > -1 ? true : false}
                                                id={`enviroment_${enviroment}`}
                                                onChange={(e) => changeCheckboxValue(e.target.name, e.target.value, enviromentValue, setEnviromentValue)} />
                                            <label htmlFor={`enviroment_${enviroment}`}>{enviroment}</label>
                                        </div>
                                    ))}
                                </> : ''}
                            </div>
                        </div>
                        <div className="form-column">
                            <label>First buddy<span>(Optional)</span></label>
                            <div className="double">
                                <div className="form-control">
                                    <label>First name</label>
                                    <input type="text" name="buddyFirstName" value={data.buddyFirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                                </div>
                                <div className="form-control">
                                    <label>Last name</label>
                                    <input type="text" name="buddyLastName" value={data.buddyLastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                                </div>
                            </div>
                        </div>
                        <div className="form-column">
                            <label>Second buddy<span>(Optional)</span></label>
                            <div className="double">
                                <div className="form-control">
                                    <label>First name</label>
                                    <input type="text" name="secondBuddyFirstName" value={data.secondBuddyFirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                                </div>
                                <div className="form-control">
                                    <label>Last name</label>
                                    <input type="text" name="secondBuddyLastName" value={data.secondBuddyLastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                                </div>
                            </div>
                        </div>
                        <div className="form-column">
                            <label>Surface conditions<span>(Optional)</span></label>
                            <Select className="select" required 
                                options={info.surfaceConditions} 
                                onChange={(value) => changeSelectValue('surfaceCondition', value, setSurfaceCondition)} 
                                styles={customStyles} 
                                value={surfaceCondition} />
                        </div>
                        <div className="form-column">
                            <label>Underwater conditions<span>(Optional)</span></label>
                            <Select className="select" required 
                                options={info.underwaterConditions} 
                                onChange={(value) => changeSelectValue('underwaterCondition', value, setUnderwaterCondition)} 
                                styles={customStyles} 
                                value={underwaterCondition} />
                        </div>
                        <div className="form-column">
                            <label>Comments<span>(Optional)</span></label>
                            <textarea name="comments" value={data.comments || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                        </div>
                        <div className="form-submit">
                            <div><button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button></div>
                            {error ? <div className="error">{error}</div> : ''}
                            {success ? <div className="success">Dive log was successfully {params.diveId ? 'updated' : 'added'}</div> : ''}
                            {error && existing ? <div className="bordered-content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Starting Time</th>
                                            <th>OM</th>
                                            <th>Duration (min)</th>
                                            <th>Location</th>
                                            <th>Gear</th>
                                            <th>Depth (ft)</th>
                                            <th>Comments</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{existing.OMID === orgId ? <Link to={`/logs/edit/${existing.DiveID}`} target="_blank">{existing.date}<span className="mobile">{is24h > 0 ? existing.time24 : existing.time12}</span></Link> : <>{existing.date}<span className="mobile">{is24h > 0 ? existing.time24 : existing.time12}</span></>}</td>
                                            <td className="desktop">{is24h > 0 ? existing.time24 : existing.time12}</td>
                                            <td><strong className="mobile">OM: </strong><a href={`https://${existing.AAUSURL}`} target="_blank" rel="noreferrer">{existing.Name}</a></td>
                                            <td><strong className="mobile">Duration: </strong>{existing.TotalTimeOfDive}<span className="mobile">min</span></td>
                                            <td><strong className="mobile">Location: </strong>{existing.Location}</td>
                                            <td><strong className="mobile">Gear: </strong>{existing.Gear}</td>
                                            <td><strong className="mobile">Depth: </strong>{existing.DiveDepth || 'n/a'}<span className="mobile">ft</span></td>
                                            <td><strong className="mobile">Comments: </strong>{existing.Comments}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                {existing.OMID === orgId ? <div>
                                    <p>If the actual problem is with the previously entered dive(s), please click on the date on the date above . It will open that dive in another window for editing. After you are done editing the previous dive, close the window and return to this screen.</p>
                                </div> : ''}
                            </div>: ''}
                        </div>
                    </form>
                </>
            }
            {classification ? <ModalDescription setClose={() => setClassification(null)} classification={classification}  /> : ''}
        </>
    );
};

export default Log;

const ModalDescription = ({setClose, classification}) => {
    return (
         <Modal setClose={setClose} icon={<Info />}>
             <h2>{classification.classification}</h2>
             <p>{classification.Description}</p>
         </Modal>
     )
 }
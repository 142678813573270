import React, { useState, useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';

import { plansService } from '../../Services';

import { setFilter } from '../../redux/stores/orgSlice';

import Loader from '../Common/Loader';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Printer} from '../../images/icons/Media/printer.svg';
import {ReactComponent as Edit} from '../../images/icons/General/edit-01.svg';

export const History = () => {
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState('date');
    const [sortOrder, setSortOrder] = useState(false);
    const [user, setUser] = useState({value: 0, label: 'All users'});
    const filter = useSelector((state) => state.org.filter.divePlans ?? null);
    const dispatch = useDispatch();

    // print
    const [data, setData] = useState({});
    const [printLoading, setPrintLoading] = useState(false);
    const [date, setDate] = useState(null);
    const [dateStart, setDateStart] = useState(null);
    const [dateEnd, setDateEnd] = useState(null);
    const [divers, setDivers] = useState([]);

    const statuses = ['New', 'Approved', 'Rejected'];

    const endorsements = {
        DrySuit: 'Dry suit',
        AgaMask: 'Full face mask',
        DiveComputer: 'Dive computer',
        Nitrox: 'Nitrox',
        MixedGas: 'Mixed gas',
        ClosedCircuit: 'Closed circuit rebreather',
        SemiClosedCircuit: 'Semi-closed circuit rebreather',
        Saturation: 'Saturation',
        Decompression: 'Decompression',
        BlueWater: 'Blue water',
        Altitude: 'Altitude',
        IcePolar: 'Ice/polar',
        Cave: 'Cave',
        Cavern: 'Cavern',
        Night: 'Night',
        TeamLeader: 'Lead diver',
        ScientificDiver: 'Scientific diver',
        ScientificDiverInTraining: 'Scientific diver in training',
        AquariumDiver: 'Aquarium diver',
        ExhibitDiver: 'Exhibit diver',
        PresentationDiver: 'Presentation diver',
        SharkLagoonDiver: 'Shark diver',
        NewDiverTrainer: 'New diver trainer',
        NewDiverTraining: 'New diver training complete',
        PresentationTrainer: 'Presentation trainer'
    };

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const changeFilter = (value) => {
        dispatch(setFilter({
            name: 'divePlans',
            value
        }));
    }

    const sort = (e) => {
        if (e.target.id === sortField)
            setSortOrder(!sortOrder)
        else 
            setSortField(e.target.id);
    }

    const printPlan = async (id) => {
        setPrintLoading(true);

        const resData = await plansService.editInfo(id);
        setData(resData.plan);
        setDate(new Date(resData.plan['date'].date));
        setDateStart(new Date(resData.plan['dateStart'].date));
        setDateEnd(new Date(resData.plan['dateEnd'].date));
        setDivers(resData.users ? resData.users.filter(item => resData.plan.divers.split(',').includes(item.value.toString())) : '');

        setPrintLoading(false);

        setTimeout(() => {
        window.frames["print_frame"].document.body.innerHTML = '<h2>Dive plan</h2>' + document.getElementById("print-plan").outerHTML + 
            // eslint-disable-next-line
            '<style>table {border: 1px solid #000; font-family: Tahoma; border-spacing: 0; page-break-after: always; width: 100%;}\
                td {vertical-align: top;}\
                td,th {border-bottom: 1px solid #000; border-right: 1px solid #000; padding: 5px 10px;}\
                td:last-child,th:last-child{border-right: 0;}\
                td .mobile {display: none;}\
                tr:last-child td { border-bottom: 0;}\
                body {font-family: Tahoma; line-height: 1.5;}\
                label {display: block; font-weight: bold;}\
                .form-control{margin: 10px 0;}\
                .emergency-plan {page-break-before: always; page-break-after: always;}\
                ol li {margin-bottom: 10px;}</style>';
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
    }, 1000);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await plansService.history();
        setInfo(res);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Dive plan history</h1>
                {info.list ? <>
                    <div className="bordered-content">
                        <div className="header flex">
                            <h4>{Object.entries(info.list).filter(([key, item]) => ((filter === null || item.status === filter) && (user.value === 0 || user.value === item.userId))).length} dive plan{info.number === 1 ? '' : 's'}</h4>
                            <div className="buttons">
                                <Select className="select" required 
                                    options={info.users} 
                                    onChange={setUser} 
                                    styles={customStyles}  
                                    value={user}
                                    isSearchable={true}
                                />
                            </div>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filter === null ? 'active' : ''} onClick={() =>changeFilter(null)}>View all</button>
                                <button type="button" className={filter === 0 ? 'active' : ''} onClick={() =>changeFilter(0)}>New</button>
                                <button type="button" className={filter === 1 ? 'active' : ''} onClick={() =>changeFilter(1)}>Approved</button>
                                <button type="button" className={filter === 2 ? 'active' : ''} onClick={() =>changeFilter(2)}>Rejected</button>
                            </div>
                        </div>
                        <table id="history-table">
                            <thead>
                                <tr>
                                    <th className={`sortable ${sortField === 'date' ? (sortOrder ? 'down' : 'up') : ''}`} id="date" onClick={sort}>Date</th>
                                    <th className={`sortable ${sortField === 'dateStart' ? (sortOrder ? 'down' : 'up') : ''}`} id="dateStart" onClick={sort}>Start time</th>
                                    <th className={`sortable ${sortField === 'dateEnd' ? (sortOrder ? 'down' : 'up') : ''}`} id="dateEnd" onClick={sort}>End Date</th>
                                    <th className="light">Submitted by</th>
                                    <th className="light">Status</th>
                                    <th className="light">Approved/rejected by</th>
                                    <th className="light table-icon" style={{width: '6%'}}>Edit</th>
                                    <th className="light table-icon" style={{width: '6%'}}>Print</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(info.list).filter(([key, item]) => ((filter === null || item.status === filter) && (user.value === 0 || user.value === item.userId))).sort((a,b) => {
                                        if (typeof a[sortField] === 'number')
                                            return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                        else {
                                            const fieldA = sortField === 'date' ? new Date(a[sortField]) : a[sortField].toUpperCase(); 
                                            const fieldB = sortField === 'date' ? new Date(b[sortField]) : b[sortField].toUpperCase();
                                            if (fieldA < fieldB)
                                                return sortOrder ? -1 : 1;

                                            if (fieldA > fieldB)
                                                return sortOrder ? 1 : -1;
                                            
                                            return 0;
                                        }
                                    }).map(([key, item]) => {
                                    return (
                                        <tr key={item.id} className={(item.status === 2 ? 'denied' : (item.status === 1 ? 'approved' : ''))}>
                                            <td><Link to={`/dive-plans/edit/${item.id}`}>{item.date}</Link></td>
                                            <td><strong className="mobile">Start date: </strong>{item.dateStart}</td>
                                            <td><strong className="mobile">End date: </strong>{item.dateEnd}</td>
                                            <td className="light"><strong className="mobile">Submitted by: </strong>{item.username}</td>
                                            <td className="light"><strong className="mobile">Status: </strong>{statuses[parseInt(item.status)]}</td>
                                            <td className="light">{parseInt(item.status) !== 0 ? <strong className="mobile">{statuses[parseInt(item.status)]} by: </strong> : ''}{item.dsoname}</td>
                                            <td className="light table-icon center desktop"><Link to={`/dive-plans/edit/${item.id}`}><Edit /></Link></td>
                                            {/*<td className="light table-icon center desktop"><Link to={`/dive-plans/print/${item.id}`}><Printer className="desktop" /></Link></td>*/}
                                            <td className="light table-icon center desktop">{printLoading ? <Loading className="btn-loader" alt="loader" /> : <Printer className="cursor" onClick={() => printPlan(item.id)} />}</td>
                                            <td className="light mobile">
                                                <Link to={`/dive-plans/edit/${item.id}`} className="mobile"><label className="label link">Edit</label></Link>
                                                {printLoading ? <Loading className="btn-loader" alt="loader" /> : <label className="label link mobile cursor" onClick={() => printPlan(item.id)}>Print</label>}
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div id="print-plan">
                        <div className="form-control mb-32">
                            <label>Submitted by</label>
                            <div>{data.username}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Submitted date</label>
                            <div>{date ? (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear() + ' ' + (date.getHours() < 10 ? '0' : '') + date.getHours() + ':' + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes() : ''}</div>
                        </div>
                        <h3>Divers</h3>
                        {divers.length ? <>
                            <div className="divers-table">
                                <table>
                                    <tbody>
                                    {
                                        divers.sort((a,b) => {
                                            const fieldA = (a['FirstName'] + ' ' + a['LastName']).toUpperCase(); 
                                            const fieldB = (b['FirstName'] + ' ' + b['LastName']).toUpperCase(); 
                                            if (fieldA < fieldB)
                                                return -1;

                                            if (fieldA > fieldB)
                                                return 1;
                                            
                                            return 0;
                                        }).map(item => {
                                            let endorsementList = '';
                                            Object.keys(endorsements).map(key => {
                                                if (item[key])
                                                    endorsementList += (endorsementList ? ', ' : '') + endorsements[key];
                                            });
                                            return (
                                                <tr key={item.value}>
                                                    <td>
                                                        <p><strong>{item.LastName}, {item.FirstName}</strong></p>
                                                        <p><strong>DOB:</strong> {item.DateOfBirth}</p>
                                                        <p><strong>Emergency contact:</strong> {item.EmergencyFirstName} {item.EmergencyLastName}, {item.EmergencyRelationship}, {item.EmergencyPhone}{item.EmergencyPhoneAlternative ? `, ${item.EmergencyPhoneAlternative}` : ''}</p>
                                                        <p><strong>Diving insurance carrier, policy #:</strong> {item.InsuranceCarrier} {item.InsurancePolicy}</p>
                                                        {item.ScientificDiverInTraining || item.ScientificDiver ? <p><strong>Role:</strong> {item.ScientificDiverInTraining ? 'Scientific diver in training' : (item.ScientificDiver ? 'Scientific diver' : '')}</p> : ''}
                                                        <p><strong>Depth Certification:</strong> {item.DepthCertification}</p>
                                                        <p className={item.MedicalExpiration && new Date(item.MedicalExpiration) < data.dateEnd ? 'red' : ''}><strong>Medical Expiration:</strong> {item.MedicalExpiration}</p>
                                                        <p className={item.CPRExpiration && new Date(item.CPRExpiration) < data.dateEnd ? 'red' : ''}><strong>CPR Expiration:</strong> {item.CPRExpiration}</p>
                                                        <p className={item.O2Expiration && new Date(item.O2Expiration) < data.dateEnd ? 'red' : ''}><strong>O2 Expiration:</strong> {item.O2Expiration}</p>
                                                    </td>
                                                    <td>
                                                        <p className={item.AEDExpiration && new Date(item.AEDExpiration) < data.dateEnd ? 'red' : ''}><strong>AED Expiration:</strong> {item.AEDExpiration}</p>
                                                        <p className={item.FirstAidExpiration && new Date(item.FirstAidExpiration) < data.dateEnd ? 'red' : ''}><strong>First Aid Expiration:</strong> {item.FirstAidExpiration}</p>
                                                        <p className={item.BCServiceExpiration && new Date(item.BCServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>BC Service Expiration:</strong> {item.BCServiceExpiration}</p>
                                                        <p className={item.RegulatorServiceExpiration && new Date(item.RegulatorServiceExpiration) < data.dateEnd ? 'red' : ''}><strong>Regulator Service Expiration:</strong> {item.RegulatorServiceExpiration}</p>
                                                        <p className={item.GaugeCompExp && new Date(item.GaugeCompExp) < data.dateEnd ? 'red' : ''}><strong>Gauge/Computer Expiration:</strong> {item.GaugeCompExp}</p>
                                                        <p>
                                                            <strong>Endorsements:</strong> {endorsementList}
                                                        </p>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                    <tr>
                                        <td colSpan="2">
                                            <p>Participants information last updated: <span>{data.diversUpdateDate}</span></p>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </> : ''}
                        <div className="form-control mb-32">
                            <label>Start date</label>
                            <div>{dateStart ? (dateStart.getMonth() + 1) + '/' + dateStart.getDate() + '/' + dateStart.getFullYear() : ''}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>End date</label>
                            <div>{dateEnd ? (dateEnd.getMonth() + 1) + '/' + dateEnd.getDate() + '/' + dateEnd.getFullYear() : ''}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Diving mode(s) and gas(es)</label>
                            <div>{data.divingModes}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Approximate number of proposed dives</label>
                            <div>{data.divesNumber}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Location(s) of proposed dives</label>
                            <div>{data.locations}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Estimated depth(s) and bottom time(s) anticipated</label>
                            <div>{data.depths}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Decompression status and repetitive dive plans (if required)</label>
                            <div>{data.decompressionStatus}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Proposed work, equipment, and boats to be employed</label>
                            <div>{data.proposedWork}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Any hazardous conditions anticipated</label>
                            <div>{data.conditions}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>List of emergency contact numbers appropriate for dive location</label>
                           <div>{data.emergencyContactLocation}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Name, telephone number, and relationship of person to be contacted for each diver in the event of an emergency</label>
                            <div>{data.emergencyContact}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Nearest operational recompression chamber</label>
                            <div>{data.orChamber}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Nearest accessible hospital</label>
                            <div>{data.hospital}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Available means of transport</label>
                            <div>{data.transport}</div>
                        </div>
                        <h3>In water details</h3>
                        <div className="form-control mb-32">
                            <label>Dive buddy assignments and tasks</label>
                            <div>{data.diverTasks}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Goals and objectives</label>
                            <div>{data.goals}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Maximum depth(s) and bottom time</label>
                            <div>{data.maximumDepth}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Gas management plan</label>
                            <div>{data.gasPlan}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Entry, exit, descent, and ascent procedures</label>
                            <div>{data.procedures}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Perceived environmental and operational hazards and mitigations</label>
                            <div>{data.hazards}</div>
                        </div>
                        <div className="form-control mb-32">
                            <label>Emergency and diver recall procedures</label>
                            <div>{data.emergencyProcedures}</div>
                        </div>
                        {data.id && parseInt(data.status) !== 0 ? 
                        <div className="form-control mb-32">
                            <label>{parseInt(data.status) === 1 ? 'Approved' : 'Rejected'} by</label>
                            <div>{data.dsoname}</div>
                        </div> : ''}
                        {data.id && parseInt(data.status) === 2 ? 
                        <div className="form-control mb-32">
                            <label>Rejection reason:</label>
                            <div>{data.reason}</div>
                        </div> : ''}
                        <div className={`emergency-plan open`}>
                            <h3>Emergency Action Plan</h3>
                            <div>
                                <div>
                                    <p>Depending on and according to the nature of the diving accident:</p>
                                    <ol>
                                        <li>Make appropriate contact with victim or rescue as required.</li>
                                        <li>Establish (A)irway (B)reathing (C)irculation or (C)irculation (A)irway (B)reathing as appropriate.</li>
                                        <li>Stabilize the victim.</li>
                                        <li>Administer 100% oxygen, if appropriate (in cases of Decompression Illness, or Near Drowning).</li>
                                        <li>Call local Emergency Medical System (EMS) for transport to nearest medical treatment facility. Explain the circumstances of the dive incident to the evacuation teams, medics and physicians. Do not assume that they understand why 100% oxygen may be required for the diving accident victim or that recompression treatment may be necessary.</li>
                                        <li>Call appropriate Diving Accident Coordinator for contact with diving physician and recompression chamber, etc.</li>
                                        <li>Notify DSO or designee according to the Emergency Action Plan of the OM.</li>
                                        <li>Complete and submit Incident Report Form (<a href="https://www.aaus.org" target="_blank"rel="noreferrer">www.aaus.org</a>) to the DCB of the organization and the AAUS (Section 2.70 Required Incident Reporting).</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                    <iframe name="print_frame" title="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
                </> : <p className="empty">Dive plan history is empty</p>}
            </>}
        </>
    );
};

export default History;
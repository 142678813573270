import React, { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authService } from '../../Services';

import LoginLayout from '../Common/Layouts/LoginLayout';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Check} from '../../images/icons/General/check-circle.svg';
import {ReactComponent as Mail} from '../../images/icons/Communication/mail-01.svg';
import {ReactComponent as Key} from '../../images/icons/Security/key-01.svg';
import {ReactComponent as ArrowLeft} from '../../images/icons/Arrows/arrow-left.svg';

export const ResetPassword = () => {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const [searchParams] = useSearchParams();
    const orgId = useSelector((state) => state.org.id);

    const handleConfirm = async e => {
        e.preventDefault();
        setLoading(true);
        setError(false);

        if (password !== confirmPassword)
            setError('Passwords do not match');

        if (!password)
            setError('Please, enter password');

        if (!error) {
            const data = await authService.confirmPassword({
                password: password, 
                confirmPassword: confirmPassword,
                hash: searchParams.get('hash'),
                orgId: orgId
            });

            if (data.res === 'OK') {
                setSuccess(true);
                setLoading(false);
                setPassword('');
                setConfirmPassword('');
            } else {
                setLoading(false);
                setError(data.error);
            }
        }
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(false);

        const data = await authService.resetPassword({
            email,
            orgId: orgId
        });

        if (data.res === 'OK') {
            setSuccess(true);
            setLoading(false);
        } else {
            setLoading(false);
            setError(data.error);
        }
    }

    return (
        <LoginLayout>
            <div className="login">
                {searchParams.get('hash') ? 
                    <>
                        {success ? 
                            <>
                                <div className="confirmation">
                                    <div className="mb-20">
                                        <span className="icon green">
                                            <Check />
                                        </span>
                                    </div>
                                    <h2>Password reset</h2>
                                    <p>Your password has been successfully reset. Click below to log in.</p>
                                    <p>
                                        <Link to="/"><button type="button" className="primary">Continue</button></Link>
                                    </p>
                                </div>
                            </> :
                            <>
                                <div className="center mb-20">
                                    <span className="icon">
                                        <Key />
                                    </span>
                                </div>
                                <h2 className="center">Set new password</h2>
                                <p className="center">Your new password must be different to previously used passwords.</p>
                                <form onSubmit={handleConfirm}>
                                    <div className="form-control">
                                        <label>Password</label>
                                        <input type="password" name="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                                        {(password && password.length < 8) ? <p className="input-error">Must be at least 8 characters.</p> : ''}
                                    </div>
                                    <div className="form-control">
                                    <label>Confirm password</label>
                                        <input type="password" name="cpassword" placeholder="Confirm Password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)} />
                                        {(password && confirmPassword && password !== confirmPassword) ? <p className="input-error">Passwords do not match.</p> : ''}
                                    </div>
                                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Reset password</button>
                                    {error ? <div className="error">{error}</div> : ''}
                                </form>
                            </>
                        }
                        <div className="register">
                            <Link to="/" className="arrow">
                                <ArrowLeft />Back to log in
                            </Link>
                        </div>
                    </>:
                    <>
                        {success ? 
                            <>
                                <div className="confirmation">
                                    <div className="mb-20">
                                        <span className="icon">
                                            <Mail />
                                        </span>
                                    </div>
                                    <h2>Check your email</h2>
                                    <p>We sent a password reset link to <br /><span className="medium">{email}</span></p>
                                </div>
                                <p className="center font-14">Didn’t receive the email? <Link onClick={handleSubmit}>Click to resend</Link></p>
                                {error ? <div className="error">{error}</div> : ''}
                            </> :
                            <>
                                <div className="center mb-20">
                                    <span className="icon">
                                        <Key />
                                    </span>
                                </div>
                                <h2 className="center">Forgot password?</h2>
                                <p className="center">No worries, we’ll send you reset instructions.</p>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-control">
                                        <label>Email</label>
                                        <input type="email" name="email" placeholder="Enter your email" value={email} onChange={e => setEmail(e.target.value)} />
                                    </div>
                                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Reset password</button>
                                    {error ? <div className="error">{error}</div> : ''}
                                </form>
                            </>
                        }
                        <div className="back">
                            <Link to="/" className="arrow">
                                <ArrowLeft />Back to log in
                            </Link>
                        </div>
                    </>
                }
        </div>
        </LoginLayout>
    );
};

export default ResetPassword;
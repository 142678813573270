const styles = `
body {
    font-family: Verdana,Arial,sans-serif;
    font-size: 9pt;
    line-height: 135%;
}

h1 {
    font-size: 24px;
    text-align: center;
}
.letter {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
}

.par {
    margin-bottom: 5px;
}
    
.columns.signature {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.columns > div > div {
    margin-bottom: 5px;
}
    
.columns.signature > div:last-child {
    text-align: left;
}
.columns.signature > div > div {
    margin-bottom: 30px;
}

.columns {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.columns> div:last-child {
    text-align: right;
}

.signature {
    width: 60%;
    align-items: flex-start;
    line-height: 20px;
}
    
.signature > div:last-child {
    text-align: left;
}

.certifications {
    display: flex;
    flex-wrap: wrap;
}

.certifications span {
    width: 25%;
}

.mt-32 {
    margin-top: 20px;
}

.buttons {
    display: none;
}

h4 {
    margin-top: 30px;
    margin-bottom: 5px;
    font-size: 120%;
}

table {
    font-size: 90%;
}

td, th {
    border-bottom: 1px solid #777;  
    padding: 3px;
}

.mobile {
    display: none;
}
`;

export default styles;
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: 0,
  name: '',
  hid: '',
  photo: '',
  permissions: '',
  email: '',
  is24h: false,
  expirations: {}
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setId: (state, action) => {
      state.id = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setHid: (state, action) => {
      state.hid = action.payload;
    },
    setPhoto: (state, action) => {
      state.photo = action.payload;
    },
    setPermissions: (state, action) => {
      state.permissions =  action.payload;
    },
    setEmail: (state, action) => {
      state.email =  action.payload;
    },
    setIs24h: (state, action) => {
      state.is24h =  action.payload;
    },
    setExpirations: (state, action) => {
      state.expirations =  action.payload;
    },
    clearUser: () => initialState
  },
})

// Action creators are generated for each case reducer function
export const { setId, setName, setHid, setPhoto, setPermissions, setEmail, setIs24h, setExpirations, clearUser } = userSlice.actions;

export default userSlice.reducer
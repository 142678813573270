import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Form from './Form';
import Print from './Print';
import History from './History';

export const DivePlans = () => {
    return (
        <>
            <Routes>
                <Route index element={<History />} />
                <Route path="add" element={<Form />} />
                <Route path="edit/:planId" element={<Form />} />
                <Route path="print/:planId" element={<Print />} />
                <Route path="history" element={<History />} />
            </Routes>
        </>
    );
};

export default DivePlans;
import React, { useState, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import { authService } from '../../Services';
import { uploadsUrl } from '../../Services/config';

import { setEmail } from '../../redux/stores/userSlice';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Mail} from '../../images/icons/Communication/mail-01.svg';

const userReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

const stepsReduser = (state, event) => {
    return {
        ...state,
        [event.key]: {
            ...state[event.key],
            ...event.value
        }
    };
};

export const SignUp = () => {
    const orgId = useSelector((state) => state.org.id);
    const logoExt = useSelector((state) => state.org.logoExt);
    const orgName = useSelector((state) => state.org.name);
    const [user, setUser] = useReducer(userReducer, {});
    const [date, setDate] = useState();
    const [insuranceExp, setInsuranceExp] = useState();
    const [certExp, setCertExp] = useState();
    const [certLevel, setCertLevel] = useState();
    const [certAgency, setCertAgency] = useState();
    const [active, setActive] = useState(0);
    const [steps, setSteps] = useReducer(stepsReduser, [
        {
            name: 'Profile',
            title: 'Your AAUS profile details',
            description: 'Please provide your basic information',
            finished: false
        },
        {
            name: 'Contact',
            title: 'Emergency Contact',
            description: 'Specify who should be contacted in case of an emergency',
            finished: false
        },
        {
            name: 'Insurance',
            title: 'Insurance Information',
            description: 'Insurance policy details',
            finished: false
        },
        {
            name: 'Certification',
            title: 'Recreational Diving Certification',
            description: 'Highest level you achieved with any agency',
            finished: false
        }
    ]);

    const ActiveTab = componentsMap[steps[active].name];

    if (user.Is24h === undefined) {
        setUser({
            key: 'Is24h',
            value: '0',
        }); 
    }

    const setFinish = (key, value) => {
        setSteps({
            key: key,
            value: {finished: value},
        }); 
    }

    const changeValue = (key, value) => {
        setUser({
            key: key,
            value: value,
        }); 
        setFinish(active, false);
    }

    return (
        <div className="sign-up-wrapper">
            <div className="tabs-nav">
                <header>
                    {logoExt ? <img src={`${uploadsUrl}/logos/${orgId}${logoExt}`} alt={orgName} /> : <img src={`${uploadsUrl}/logos/aaus.png`} alt={orgName} />}
                    <p>{orgName}</p>
                </header>
                <section>
                    <div className="steps">
                        {Object.keys(steps).map(key => {
                            return (
                                <div className={`step ${(key <= active) ? 'active' : ''} ${steps[key].finished ? 'finished' : ''}`} key={key} onClick={() => setActive(key)}>
                                    <span></span>
                                    <div>
                                        <p className="title">{steps[key].title}</p>
                                        <p>{steps[key].description}</p>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </section>
                <footer>
                    <p>&copy; <strong>Dive Scientific</strong> {new Date().getFullYear()}</p>
                    <p><Mail /><a href="mailto:admin@aaus.org" target="_blank" rel="noreferrer">admin@diveaaus.org</a></p>
                </footer>
            </div>
            <main className="tabs-content">
                <div className="sign-up">
                    <div className="logo">
                        {logoExt ? <img src={`${uploadsUrl}/logos/${orgId}${logoExt}`} alt={orgName} /> : <img src={`${uploadsUrl}/logos/aaus.png`} alt={orgName} />}
                    </div>
                    <form autoComplete="off">
                        <ActiveTab user={user} setUser={setUser} changeValue={changeValue} date={date} setDate={setDate} active={active} setActive={setActive} setFinish={setFinish} 
                            certExp={certExp} setCertExp={setCertExp} certLevel={certLevel} setCertLevel={setCertLevel} certAgency={certAgency} setCertAgency={setCertAgency} insuranceExp={insuranceExp} setInsuranceExp={setInsuranceExp} />
                    </form>

                    <div className="dots">
                        {Object.keys(steps).map(key => {
                            return (
                                // eslint-disable-next-line
                                <div className={`dot ${(key == active) ? 'active' : ''}`} key={key} onClick={() => setActive(key)}></div>
                            )
                        })}
                    </div>
                </div>
            </main>
        </div>
    );
};

export default SignUp;

const Profile = ({user, setUser, changeValue, date, setDate, active, setActive, setFinish}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);


    const handleCheck = () => {
        setError('');
        setLoading(true);

        let message = '';

        if (user.Password !== user.CPassword)
            message = 'Passwords do not match';

        if (!user.Password || !user.CPassword || !user.FirstName || !user.LastName || !user.Email || !date)
            message = 'Please, enter required fields';

        if (!message) {
            setFinish(active, true);
            setActive(parseInt(active) + 1)
        } else {
            setFinish(active, false);
            setLoading(false);
            setError(message);
        }
    };

    return (
        <>
            <h2>Create an account</h2>
            <p>The information in your account will be shared between all of your AAUS dive logging profiles on all AAUS Organization Member websites</p>
            <div className={`mb-32 ${error ? 'required' : ''}`}>
                <div className="form-control">
                    <label>Email address*</label>
                    <input type="email" name="Email" placeholder="Enter your email address" value={user.Email || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                    <div className="font-14">Your email address will be used as your User ID</div>
                </div>
                <div className="form-control">
                    <label>First name*</label>
                    <input type="text" name="FirstName" placeholder="Enter your first name" value={user.FirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Middle name</label>
                    <input type="text" name="MiddleName" placeholder="Enter your middle name" value={user.MiddleName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={1} />
                </div>
                <div className="form-control">
                    <label>Last name*</label>
                    <input type="text" name="LastName" placeholder="Enter your last name" value={user.LastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Date of Birth*</label>
                    <DatePicker selected={date} onChange={setDate} placeholderText="Select date" className="required" isClearable />
                </div>
                <div className="form-control">
                    <label>Password*</label>
                    <input type="password" name="Password" placeholder="Enter your password" value={user.Password || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-control">
                    <label>Confirm password*</label>
                    <input type="password" name="CPassword" placeholder="Confirm your password" value={user.CPassword || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-control">
                    <label>Display time preference</label>
                    <div className="radio-group">
                        <div className="radio">
                            <input type="radio" name="Is24h" id="Is24h_0" value="0" checked={'0' === user.Is24h} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                            <label htmlFor="Is24h_0">12 hours (AM/PM)</label>
                        </div>
                        <div className="radio">
                            <input type="radio" name="Is24h" id="Is24h_1" value="1" checked={'1' === user.Is24h} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                            <label htmlFor="Is24h_1">24 hours</label>
                        </div>
                    </div>
                </div>
                <button type="button" className="primary" onClick={handleCheck}>{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Continue to step 2 of 4</button>
                {error ? <div className="error">{error}</div> : ''}
            </div>
            <div className="font-14 center">
                Already have an account? <Link to="/">Log in</Link>
            </div>
        </>
    )
};

const Contact = ({user, setUser, changeValue, date, setDate, active, setActive, setFinish}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCheck = () => {
        setError('');
        setLoading(true);

        let message = '';

        if (!user.EmergencyFirstName || !user.EmergencyLastName || !user.EmergencyRelationship || !user.EmergencyPhone)
            message = 'Please, enter required fields';

        if (!message) {
            setFinish(active, true);
            setActive(parseInt(active) + 1)
        } else {
            setFinish(active, false);
            setLoading(false);
            setError(message);
        }
    };

    return (
        <>
            <h2>Emergency Contact</h2>
            <p>Please specify who should be contacted in case of an emergency</p>
            <div className={error ? 'required' : ''}>
                <div className="form-control">
                    <label>First name*</label>
                    <input type="text" name="EmergencyFirstName" placeholder="Enter their first name" value={user.EmergencyFirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Last name*</label>
                    <input type="text" name="EmergencyLastName" placeholder="Enter their last name" value={user.EmergencyLastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Relationship*</label>
                    <input type="text" name="EmergencyRelationship" placeholder="Spouse, partner, sibling, etc." value={user.EmergencyRelationship || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Best phone number*</label>
                    <input type="tel" name="EmergencyPhone" placeholder="Enter their phone number" value={user.EmergencyPhone || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={16} />
                </div>
                <div className="form-control">
                    <label>Alternative phone number</label>
                    <input type="tel" name="EmergencyPhoneAlternative" placeholder="Enter their alternative phone number" value={user.EmergencyPhoneAlternative || ''} onChange={(e) => changeValue(e.target.name, e.target.value)}  maxLength={16}/>
                </div>
            </div>
            <button type="button" className="primary" onClick={handleCheck}>{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Continue to step 3 of 4</button>
            {error ? <div className="error">{error}</div> : ''}
        </>
    )
};

const Insurance = ({user, setUser, changeValue, date, setDate, active, setActive, setFinish, insuranceExp, setInsuranceExp}) => {
    const handleCheck = () => {
        setFinish(active, true);
        setActive(parseInt(active) + 1)
    };

    return (
        <>
            <h2>Insurance Information</h2>
            <p>Please provide the insurance policy details</p>
            <div>
                <div className="form-control">
                    <label>DAN number</label>
                    <input type="text" name="DANNumber" placeholder="Enter DAN number" value={user.DANNumber || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={15} />
                </div>
                <div className="form-control">
                    <label>Diving insurance carrier</label>
                    <input type="text" name="InsuranceCarrier" placeholder="Enter company name (DAN, PADI, etc.)" value={user.InsuranceCarrier || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={20} />
                </div>
                <div className="form-control">
                    <label>Diving insurance policy #</label>
                    <input type="text" name="InsurancePolicy" placeholder="Enter the policy number" value={user.InsurancePolicy || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={30} />
                </div>
                <div className="form-control">
                    <label>Diving insurance expiration</label>
                    <DatePicker name="InsuranceExpiration" selected={insuranceExp} onChange={setInsuranceExp} placeholderText="Select date" isClearable />
                </div>
                <div className="form-control">
                    <label>Medical insurance carrier</label>
                    <input type="text" name="MedInsuranceCarrier" placeholder="Enter company name (Aetna, Kaiser, etc.)" value={user.MedInsuranceCarrier || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={50} />
                </div>
            </div>
            <button type="button" className="primary" onClick={handleCheck}>Continue to step 4 of 4</button>
        </>
    )
}

const Certification = ({user, setUser, changeValue, date, setDate, active, setActive, setFinish, certExp, setCertExp, certLevel, setCertLevel, certAgency, setCertAgency, insuranceExp}) => {
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const orgId = useSelector((state) => state.org.id);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const certLevels = [
        { value: 'OW', label: 'Basic/Open Water Diver' },
        { value: 'AOW', label: 'Advanced Open Water Diver' },
        { value: 'Rescue', label: 'Rescue Diver' },
        { value: 'MSD', label: 'Master Scuba Diver' },
        { value: 'DM', label: 'Divemaster' },
        { value: 'EFRI', label: 'EFR Instructor' },
        { value: 'AI', label: 'Assistant Instructor' },
        { value: 'OWSI', label: 'OW Instructor' },
        { value: 'SI', label: 'Specialty Instructor' },
        { value: 'MSDT', label: 'Master Scuba Diver Trainer' },
        { value: 'IDCSTAFF', label: 'IDC Staff Instructor' },
        { value: 'MI', label: 'Master Instructor' },
        { value: 'IT', label: 'Instructor Trainer' },
        { value: 'CD', label: 'Course Director' },
        { value: 'Military', label: 'Military Diver' },
        { value: 'Commercial', label: 'Commercial Diver' },
        { value: 'Scientific', label: 'Scientific Diver' },
        { value: 'Tender', label: 'Non-Diving Tender' }
    ];

    const certAgencies = [
        { value: 'ACUC', label: 'ACUC' },
        { value: 'BSAC', label: 'BSAC' },
        { value: 'IDEA', label: 'IDEA' },
        { value: 'IANTD', label: 'IANTD' },
        { value: 'NAUI', label: 'NAUI' },
        { value: 'PADI', label: 'PADI' },
        { value: 'PDIC', label: 'PDIC' },
        { value: 'SSI', label: 'SSI' },
        { value: 'SDI/TDI', label: 'SDI/TDI' },
        { value: 'YMCA', label: 'YMCA' },
        { value: 'Other', label: 'Other' }
    ];

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(false);
        
        let message = '';

        if (!user.FirstName || !user.LastName || !user.Email || !date || !certLevel || !certAgency || !user.EmergencyFirstName || !user.EmergencyLastName || !user.EmergencyRelationship || !user.EmergencyPhone)
            message = 'Please, enter required fields';

        if (!message) {
            const data = await authService.signUp({
                ...user,
                DateOfBirth: date,
                InsuranceExpiration: insuranceExp,
                CertExpiration: certExp,
                CertLevel: certLevel ? certLevel.value : '',
                CertAgency: certAgency ? certAgency.value : '',
                orgId: orgId
            });

            if (data.res === 'OK') {
                dispatch(setEmail(user.Email));
                navigate('/confirm-email');
            } else {
                setLoading(false);
                setError(data.error);
            }
        } else {
            setLoading(false);
            setError(message);
        }
    }

    return (
        <>
            <h2>Recreational Diving Certification</h2>
            <p>Please provide the insurance policy details</p>
            <div className={error ? 'required' : ''}>
                <div className="form-control">
                    <label>Highest recreational diving certification level*</label>
                    <Select className={`select ${certLevel ? '' : 'required'}`} options={certLevels} onChange={setCertLevel} styles={customStyles} required value={certLevel} />
                </div>
                <div className="form-control">
                    <label>Certification agency*</label>
                    <Select className={`select ${certAgency ? '' : 'required'}`} options={certAgencies} onChange={setCertAgency} styles={customStyles} required value={certAgency} />
                </div>
                <div className="form-control">
                    <label>Certification #</label>
                    <input type="text" name="CertNumber" placeholder="Enter the certification number" value={user.CertNumber || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={50} />
                </div>
                <div className="form-control">
                    <label>Certification expiration date</label>
                    <DatePicker selected={certExp} onChange={setCertExp} isClearable />
                </div>
            </div>
            <button type="button" className="primary submit" onClick={handleSubmit}>{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Complete registration</button>
            {error ? <div className="error">{error} before submitting form</div> : ''}
        </>
    )
};

const componentsMap = { Profile, Contact, Insurance, Certification };
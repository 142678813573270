import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';

import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

import { usersService } from '../../Services';

const Certification = ({data, changeValue, changeSelectValue, userId}) => {
    const [certLevel, setCertLevel] = useState({});
    const [certAgency, setCertAgency] = useState({});
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.CertLevel || !data.CertAgency)
            message = 'Please, enter required fields';

        if (!message) {
            const res = await usersService.edit({
                'editType': 'Certification',
                'CertLevel': data.CertLevel,
                'CertAgency': data.CertAgency,
                'CertNumber': data.CertNumber,
                'CertExpiration': data.CertExpiration ? data.CertExpiration.getFullYear() + '/' + (data.CertExpiration.getMonth() + 1) + '/' + data.CertExpiration.getDate() : ''
            }, (userId || 0));

            if (res.res === 'OK') {
                setSuccess(true);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };
    // eslint-disable-next-line
    const [certLevels, setCertLevels] = useState([
            { value: 'OW', label: 'Basic/Open Water Diver' },
            { value: 'AOW', label: 'Advanced Open Water Diver' },
            { value: 'Rescue', label: 'Rescue Diver' },
            { value: 'MSD', label: 'Master Scuba Diver' },
            { value: 'DM', label: 'Divemaster' },
            { value: 'EFRI', label: 'EFR Instructor' },
            { value: 'AI', label: 'Assistant Instructor' },
            { value: 'OWSI', label: 'OW Instructor' },
            { value: 'SI', label: 'Specialty Instructor' },
            { value: 'MSDT', label: 'Master Scuba Diver Trainer' },
            { value: 'IDCSTAFF', label: 'IDC Staff Instructor' },
            { value: 'MI', label: 'Master Instructor' },
            { value: 'IT', label: 'Instructor Trainer' },
            { value: 'CD', label: 'Course Director' },
            { value: 'Military', label: 'Military Diver' },
            { value: 'Commercial', label: 'Commercial Diver' },
            { value: 'Scientific', label: 'Scientific Diver' },
            { value: 'Tender', label: 'Non-Diving Tender' }
        ]);
    
    // eslint-disable-next-line
    const [certAgencies, setCertAgencies] = useState([
            { value: 'ACUC', label: 'ACUC' },
            { value: 'BSAC', label: 'BSAC' },
            { value: 'IDEA', label: 'IDEA' },
            { value: 'IANTD', label: 'IANTD' },
            { value: 'NAUI', label: 'NAUI' },
            { value: 'PADI', label: 'PADI' },
            { value: 'PDIC', label: 'PDIC' },
            { value: 'SSI', label: 'SSI' },
            { value: 'SDI/TDI', label: 'SDI/TDI' },
            { value: 'YMCA', label: 'YMCA' },
            { value: 'Other', label: 'Other' }
        ]);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
            height: '42px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    useEffect(() => {
        if (data.CertLevel)
            setCertLevel(certLevels.filter(item => item.value === data.CertLevel));

        if (data.CertAgency)
            setCertAgency(certAgencies.filter(item => item.value === data.CertAgency));
    }, [setCertLevel, setCertAgency, data, certLevels, certAgencies])

    return (
        <>
            <p>Please specify the highest level you achieved with any agency</p>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
            <div className="form-column">
                    <label>Highest recreational diving certification level</label>
                    <Select className={`select ${certLevel ? '' : 'required'}`} options={certLevels} onChange={(value) => changeSelectValue('CertLevel', value, setCertLevel)} styles={customStyles} required value={certLevel} />
                </div>
                <div className="form-column">
                    <label>Certification agency</label>
                    <Select className={`select ${certAgency ? '' : 'required'}`} options={certAgencies} onChange={(value) => changeSelectValue('CertAgency', value, setCertAgency)} styles={customStyles} required value={certAgency} />
                </div>
                <div className="form-column">
                    <label>Certification #</label>
                    <input type="text" name="CertNumber" placeholder="Enter the certification number" value={data.CertNumber || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={50} />
                </div>
                <div className="form-column">
                    <label>Certification expiration date</label>
                    <DatePicker 
                        name="CertExpiration" 
                        selected={data.CertExpiration} 
                        onChange={(value) => changeValue('CertExpiration', value)} 
                        placeholderText="Select date" 
                        className="required" 
                        isClearable
                    />
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Profile was successfully updated</div> : ''}
                </div>
            </form>
        </>
    )
};

export default Certification;
import { apiUrl } from '.';
import { authService } from './';

const search = async (data) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/search?name=' + data.name, requestOptions);
    return res;
}

const create = async (data) => {
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/users', requestOptions);
    return res;
}

const edit = async (data, id) => {
    const requestOptions = {
        method: 'POST',
        body: data.constructor === ({}).constructor ? JSON.stringify(data) : data
    };

    const res = await authService.handleResponse(apiUrl + '/users' + (id ? '?id='+id : ''), requestOptions);
    return res;
}

const editInfo = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/edit-info' + (id ? '?id='+id : ''), requestOptions);
    return res;
}

const letter = async (id) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/letter' + (id ? '?id='+id : ''), requestOptions);
    return res;
}

const getLogs = async ({userId, logField}) => {
    const requestOptions = {
        method: 'GET'
    };

    const res = await authService.handleResponse(apiUrl + '/users/logs?id='+userId+'&field='+logField, requestOptions);
    return res;
}

export const usersService = {
    search,
    create,
    edit,
    editInfo,
    letter,
    getLogs
};
import { apiUrl } from '.';
import { authService } from './';

const add = async (data) => {
   

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(data)
    };

    const res = await authService.handleResponse(apiUrl + '/suggestions', requestOptions);
    return res;
}

export const suggestionService = {
    add
};
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import { reportService } from '../../Services';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Copy} from '../../images/icons/General/copy-01.svg';

export const Expirations = () => {
    const [report, setReport] = useState();
    //const [emails, setEmails] = useState('');
    const [type, setType] = useState({value: 'MedicalExpiration', label: 'Medical Expiration'});
    const [typeLabel, setTypeLabel] = useState('');
    const [date, setDate] = useState(new Date());
    const [loading, setLoading] = useState(false);

    const reportTypes = {
        'expired': 'Expired', 
        'expired3Months': 'Will expire in the next 90 days', 
        'actual': 'Current',
        'noData': 'No date on file'
    };

    const types = [
        {value: 'InsuranceExpiration', label: 'Diving insurance expiration'},
        {value: 'CertExpiration', label: 'Certification expiration'},
        {value: 'MedicalExpiration', label: 'Medical Expiration'},
        {value: 'NeuroExam', label: 'Neurological Exam Training Expiration'},
        {value: 'CPRExpiration', label: 'CPR Expiration'},
        {value: 'O2Expiration', label: 'O2 Expiration'},
        {value: 'FSOExpiration', label: 'FSO Expiration'},
        {value: 'AEDExpiration', label: 'AED Expiration'},
        {value: 'FirstAidExpiration', label: 'First Aid Expiration'},
        {value: 'BCServiceExpiration', label: 'BC Service Expiration'},
        {value: 'RegulatorServiceExpiration', label: 'Regulator Service Expiration'},
        {value: 'GaugeCompExp', label: 'Gauge/Computer Expiration'}
    ];

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            display: 'inline-block',
            width: '250px',
            marginRight: '10px'
          }),
        control: (provided, state) => ({
          ...provided,
          height: '42px',
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const copy = async (type) => {
        await navigator.clipboard.writeText(report[type + 'Emails'].join(';'));
    }

    const handleReport = async () => {
        if (type && date) {
            setLoading(true);
            const res = await reportService.expirationReport({
                type: type.value,
                date: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
            });

            if (res) {
                setReport(res);
                setTypeLabel(type.label);
            }
            setLoading(false);
        }
    }

    return (
        <>
            <h1 id="report-title">Expirations Report</h1>
            <div className="bordered-content">
                <div className="header">
                    <h4>Report of Divers' User Profile Expirations:</h4>
                    <div className="select-year sentence">
                        Show all divers whose <Select className={`select`} options={types} onChange={setType} styles={customStyles} value={type} /> <br className="mobile" />
                        is on: <DatePicker name="date" selected={date} onChange={setDate} placeholderText="Select date" className="required" isClearable /><br className="mobile" />
                        <button className="primary" type="button" onClick={handleReport}>{loading ? <Loading className="btn-loader"alt="loader" /> : ''}Generate report</button>
                    </div>
                </div>
            </div>
            {report && Object.entries(reportTypes).map(rep => (
                report[rep[0]] ? <div id="report" key={rep[0]}>
                    <div className="bordered-content mt-32">
                        <div className="header">
                            <h4>{rep[1]}</h4>
                            <button className="default" onClick={() => copy(rep[0])}><Copy />Copy these divers' email addresses to clipboard</button>
                        </div>
                        <table>
                            <thead>
                                <tr className="desktop">
                                    <th>#</th>
                                    <th>Last Name, First Name</th>
                                    <th>{typeLabel}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {report[rep[0]].map((item, key) => {
                                    return (
                                        <tr key={key}>
                                            <td className="desktop">{key + 1}</td>
                                            <td className="mobile-bold"><Link to={`/configuration/users/${item.id}`}>{item.LastName}, {item.FirstName}</Link></td>
                                            <td><span className="mobile">Date: </span>{item.Date || 'n/a'}</td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    </div> : ''
            ))}
        </>
    );
};

export default Expirations;
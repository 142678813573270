import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';

import { usersService } from '../../Services';

import Loader from '../Common/Loader';
import Tabs from '../Common/Tabs';
import ProfileDetails from './ProfileDetails';
import EmergencyContact from './EmergencyContact';
import Insurance from './Insurance';
import Certification from './Certification';
import DSO from './DSO';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Profile = () => {
    const params = useParams();
    const [data, setData] = useReducer(dataReducer, {});
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const changeValue = (key, value) => {
        setData({
            key: key,
            value: value,
        }); 
    }

    const changeSelectValue = (name, value, selectSetVar) => {
        selectSetVar(value);
        setData({
            key: name,
            value: value.value,
        }); 

        if (name === 'location') {
            setData({
                key: 'diveDepth',
                value: value.Depth || 0,
            }); 
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await usersService.editInfo(params.userId || 0);
        setName(res.user.FirstName + ' ' + res.user.LastName);
        Object.keys(res.user).forEach((key) => {
            if (['DateOfBirth', 'CertExpiration', 'MedicalExpiration', 'MedicalDate',
                'NeuroExam', 'CPRExpiration', 'CPRExpiration', 'O2Expiration',
                'FSOExpiration', 'AEDExpiration', 'FirstAidExpiration', 'BCServiceExpiration',
                'RegulatorServiceExpiration', 'GaugeCompExp', 'StartDate', 'OriginalDivingAuth',
                'ScientificExam', 'RecentCheckoutDive', 'InsuranceExpiration'].indexOf(key) > -1) 
                res.user[key] = res.user[key] ? new Date(res.user[key].date) : null;

            if (key !== 'Password') {
                setData({
                    key: key,
                    value: res.user[key]
                });
            }
        });

        setLoading(false);
      }, [params]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const tabs = [
        {
            title: <>AAUS<span className="desktop"> profile details</span></>,
            content: <ProfileDetails data={data} changeValue={changeValue} userId={params.userId || 0} />
        },
        {
            title: <>Emergency<span className="desktop">contact</span></>,
            content: <EmergencyContact data={data} changeValue={changeValue} userId={params.userId || 0} />
        },
        {
            title: <>Insurance<span className="desktop"> Information</span></>,
            content: <Insurance data={data} changeValue={changeValue} userId={params.userId || 0} />
        },
        {
            title: <><span className="desktop">Recreational diving certification</span><span className="mobile">Certification</span></>,
            content: <Certification data={data} changeValue={changeValue} changeSelectValue={changeSelectValue} userId={params.userId || 0} />
        },
        {
            title: <>DSO<span className="desktop"> Information</span></>,
            content: <DSO data={data} changeValue={changeValue} changeSelectValue={changeSelectValue} userId={params.userId || 0} />
        }
    ];
    
    return (
        <>
            <h1>{params.userId ? `${name.length ? name : 'User'}'s` : 'My'} profile</h1>
            {loading ? <Loader /> : <Tabs tabs={tabs} />}
        </>
    );
};

export default Profile;
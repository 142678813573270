import React, { useState } from 'react';

const Tabs = ({tabs}) => {

    const [currentTab, setCurrentTab] = useState(0);

    const handleTabClick = (id) => {
        setCurrentTab(id);
    }

    return (
        <div className='tabs'>
            <div className='title'>
                {tabs.map((tab, i) =>
                    <button key={i} id={`tab_${i}`} disabled={currentTab === i} onClick={(() => handleTabClick(i))}>{tab.title}</button>
                )}
            </div>
            <div className='content'>
                {tabs.map((tab, i) =>
                    (currentTab === i && <div key={i}>{tab.content}</div>)
                )}
            </div>
        </div>
    );
}

export default Tabs;
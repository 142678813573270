import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userReducer from './stores/userSlice';
import localUserReducer from './stores/localUserSlice';
import orgReducer from './stores/orgSlice';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session'
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

const rootPersistConfig = {
    key: 'root',
    blacklist: ['user'],
    storage,
  }
  
  const userPersistConfig = {
    key: 'user',
    storage: storageSession,
  }

const rootReducer = combineReducers({ 
    user: persistReducer(userPersistConfig, userReducer), // login only for 10 minutes, saved in sessions
    localUser: localUserReducer,
    org: orgReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk]
  })

export const persistor = persistStore(store);
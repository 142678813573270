import { useSelector, useDispatch } from 'react-redux';
import { useState, useCallback, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import moment from 'moment' ;

import { authService } from '../../../Services';
import { uploadsUrl } from '../../../Services/config';

import Modal from '../Modal';

import { setExpirations } from '../../../redux/stores/userSlice';
import { setLocalExpirations } from '../../../redux/stores/localUserSlice';

import {ReactComponent as Menu} from '../../../images/icons/General/menu-02.svg';
import {ReactComponent as Mail} from '../../../images/icons/Communication/mail-01.svg';
import {ReactComponent as HomeIcon} from '../../../images/icons/General/home-line.svg';
import {ReactComponent as LayersIcon} from '../../../images/icons/Layout/layers-three-01.svg';
import {ReactComponent as LayersIcon2} from '../../../images/icons/Layout/layout-alt-03.svg';
import {ReactComponent as FolderIcon} from '../../../images/icons/Files/file-02.svg';
import {ReactComponent as WavesIcon} from '../../../images/icons/Weather/waves.svg';
import {ReactComponent as ListIcon} from '../../../images/icons/Layout/list.svg';
import {ReactComponent as AlertIcon} from '../../../images/icons/Alerts/alert-triangle.svg';
import {ReactComponent as ChartIcon} from '../../../images/icons/Charts/pie-chart-03.svg';
import {ReactComponent as UsersIcon} from '../../../images/icons/Users/users-01.svg';
import {ReactComponent as MesIcon} from '../../../images/icons/Communication/message-question-circle.svg';
import {ReactComponent as UserIcon} from '../../../images/icons/Users/user-01.svg';
import {ReactComponent as LogOutIcon} from '../../../images/icons/General/log-out-01.svg';
import {ReactComponent as DownIcon} from '../../../images/icons/Arrows/chevron-down.svg';
import {ReactComponent as TargetIcon} from '../../../images/icons/General/target-04.svg';
import {ReactComponent as GlobeIcon} from '../../../images/icons/Maps/globe-06.svg';
import {ReactComponent as UsersRightIcon} from '../../../images/icons/Users/users-right.svg';
import {ReactComponent as CalendarIcon} from '../../../images/icons/Time/calendar-minus-01.svg';
import {ReactComponent as Chart1Icon} from '../../../images/icons/Charts/bar-chart-07.svg';
import {ReactComponent as Chart2Icon} from '../../../images/icons/Charts/bar-chart-square-02.svg';
import {ReactComponent as UploadIcon} from '../../../images/icons/General/upload-cloud-02.svg';

export const Layout = ({children}) => {
    const [activeNav, setActiveNav] = useState(null);
    const [openNav, setOpenNav] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();

    const remember = useSelector((state) => state.org.remember);
    const orgId = useSelector((state) => state.org.id);
    const logoExt = useSelector((state) => state.org.logoExt);
    const orgName = useSelector((state) => state.org.name);
    const version = useSelector((state) => state.org.version);

    const userName = useSelector((state) => state[remember ? 'localUser' : 'user'].name);
    const userPhoto = useSelector((state) => state[remember ? 'localUser' : 'user'].photo);
    const permissions = useSelector((state) => state[remember ? 'localUser' : 'user'].permissions).split(',');
    const expirations = useSelector((state) => state[remember ? 'localUser' : 'user'].expirations);

    const expirationList = {
        'InsuranceExpiration': 'Diving insurance expiration', 
        'CertExpiration': 'Certification expiration date', 
        'MedicalExpiration': 'Medical Expiration', 
        'NeuroExam': 'Neurological Exam Training Expiration',
        'CPRExpiration': 'CPR Expiration', 
        'O2Expiration': 'O2 Expiration', 
        'FSOExpiration': 'Fill Station Operator (FSO) Expiration', 
        'AEDExpiration': 'AED Expiration', 
        'FirstAidExpiration' : 'First Aid Expiration', 
        'BCServiceExpiration': 'BC Service Expiration', 
        'RegulatorServiceExpiration': 'Regulator Service Expiration', 
        'GaugeCompExp': 'Gauge/Computer Expiration'
    };
    
    // eslint-disable-next-line
    const [events, setEvents] = useState(['click', 'load', 'scroll']);

    let startTimerInterval = useRef();

    let timeChecker = useCallback(() => {
        startTimerInterval.current = setInterval(() => {
            let storedTimeStamp = sessionStorage.getItem('lastTimeStamp');

            const diff = moment.duration(moment().diff(moment(storedTimeStamp)));
            const minPast = diff.minutes();
            const maxTime = 10;

            if (minPast >= maxTime) {
                sessionStorage.removeItem("lastTimeStamp");
                authService.logout();
            }
        }, 10000);
    }, []);

    let resetTimer = useCallback(() => {
        sessionStorage.setItem('lastTimeStamp', moment());
    }, []);

    let hideExpirations = () => {
        dispatch(remember ? setLocalExpirations({}) : setExpirations({}));
    }

    useEffect(() => {
        setActiveNav(location.pathname.split('/')[1]);

        if (!remember) {
            events.forEach((event) => {
                window.addEventListener(event, resetTimer);
            });
        
            timeChecker();
        
            return () => {
                clearInterval(startTimerInterval.current);
            };
        }
    }, [resetTimer, events, timeChecker, remember, location]);
    return (
        <div className="container">
            <aside>
                <header>
                    {logoExt ? <img src={`${uploadsUrl}/logos/${orgId}${logoExt}`} alt={orgName} /> : <img src={`${uploadsUrl}/logos/aaus.png`} alt={orgName} />}
                    <p>{orgName}</p>
                    <Menu onClick={() => setOpenNav(!openNav)} />
                </header>
                <nav className={openNav ? 'open' : ''}>
                    <div>
                        <div className="nav-profile">
                            <Link to="/profile"><UserIcon />{userName}</Link>
                            <button onClick={() => {
                                authService.logout();
                            }}><LogOutIcon /></button>
                        </div>
                        <ul>
                            <li>
                                <Link to="/" className={location.pathname === "/" ? 'active' : ''} onClick={() => setOpenNav(false)}><HomeIcon />Home</Link>
                            </li>
                            <li className={activeNav === 'logs' ? 'open' : ''}>
                                <span onClick={() => activeNav !== 'logs' ? setActiveNav('logs') : setActiveNav(null)}>
                                    <LayersIcon />
                                    Logs
                                    <DownIcon />
                                </span>
                                <ul>
                                    <li><Link to="/logs/add" className={location.pathname === "/logs/add" ? 'active' : ''} onClick={() => setOpenNav(false)}><WavesIcon />Log new dives</Link></li>
                                    <li><Link to="/logs/history" className={location.pathname === "/logs/history" ? 'active' : ''} onClick={() => setOpenNav(false)}><ListIcon />My dive log history</Link></li>
                                    {permissions.indexOf('Incident') >= 0 ? <li><Link to="/logs/incidents" className={location.pathname === "/logs/incidents" ? 'active' : ''} onClick={() => setOpenNav(false)}><AlertIcon />Log incidents</Link></li> : ''}
                                    {permissions.indexOf('Import') >= 0 ? <li><Link to="/logs/import" className={location.pathname === "/logs/import" ? 'active' : ''} onClick={() => setOpenNav(false)}><UploadIcon />Import logs</Link></li> : ''}
                                </ul>
                            </li>
                            <li className={activeNav === 'dive-plans' ? 'open' : ''}>
                                <span onClick={() => activeNav !== 'dive-plans' ? setActiveNav('dive-plans') : setActiveNav(null)}>
                                    <LayersIcon2 />
                                    Dive plans
                                    <DownIcon />
                                </span>
                                <ul>
                                    <li><Link to="/dive-plans/add" className={location.pathname === "/dive-plans/add" ? 'active' : ''} onClick={() => setOpenNav(false)}><FolderIcon />New dive plan</Link></li>
                                    <li><Link to="/dive-plans/history" className={location.pathname === "/dive-plans/history" ? 'active' : ''} onClick={() => setOpenNav(false)}><ListIcon />Dive plan history</Link></li>
                                </ul>
                            </li>
                            {permissions.indexOf('Report') >= 0 ? <li className={activeNav === 'reports' ? 'open' : ''}>
                                <span onClick={() => activeNav !== 'reports' ? setActiveNav('reports') : setActiveNav(null)}>
                                    <ChartIcon />
                                    Reports
                                    <DownIcon />
                                </span>
                                <ul>
                                    <li><Link to="/reports/expirations" className={location.pathname === "/reports/expirations" ? 'active' : ''} onClick={() => setOpenNav(false)}><CalendarIcon />Expirations</Link></li>
                                    <li><Link to="/reports/divers" className={location.pathname === "/reports/divers" ? 'active' : ''} onClick={() => setOpenNav(false)}><UsersRightIcon />Divers Contacts</Link></li>
                                    <li><Link to="/reports/aaus" className={location.pathname === "/reports/aaus" ? 'active' : ''} onClick={() => setOpenNav(false)}><Chart1Icon />AAUS Report</Link></li>
                                    <li><Link to="/reports/internal" className={location.pathname === "/reports/internal" ? 'active' : ''} onClick={() => setOpenNav(false)}><Chart2Icon />Internal Stats Report</Link></li>
                                </ul>
                            </li> : ''}
                            {permissions.indexOf('View user') >= 0 || permissions.indexOf('Locations') >= 0 || permissions.indexOf('Tasks') >= 0 ? <li className={activeNav === 'configuration' ? 'open' : ''}>
                                <span onClick={() => activeNav !== 'configuration' ? setActiveNav('configuration') : setActiveNav(null)}>
                                    <UsersIcon />
                                    Configuration
                                    <DownIcon />
                                </span>
                                <ul>
                                    {permissions.indexOf('View user') >= 0 ? <li><Link to="/configuration/users" className={location.pathname === "/configuration/users" ? 'active' : ''} onClick={() => setOpenNav(false)}><UserIcon />Users</Link></li> : ''}
                                    {permissions.indexOf('Locations') >= 0 ? <li><Link to="/configuration/locations" className={location.pathname === "/configuration/locations" ? 'active' : ''} onClick={() => setOpenNav(false)}><GlobeIcon />Locations / Projects</Link></li> : ''}
                                    {permissions.indexOf('Tasks') >= 0 ? <li><Link to="/configuration/tasks" className={location.pathname === "/configuration/tasks" ? 'active' : ''} onClick={() => setOpenNav(false)}><TargetIcon />Dive log tasks</Link></li> : ''}
                                </ul>
                            </li> : ''}
                            <li>
                                <Link to="/suggestion-box" className={location.pathname === "/suggestion-box" ? 'active' : ''} onClick={() => setOpenNav(false)}><MesIcon />Suggestion box</Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </aside>
            <div>
                <header>
                    <Link to="/profile">{userPhoto ? <img src={`${uploadsUrl}/users/${userPhoto}`} alt="User" /> : <UserIcon />}{userName}</Link>
                    <button onClick={() => {
                        authService.logout();
                    }}><LogOutIcon /></button>
                </header>

                <main>
                    {children}
                </main>

                <footer>
                    <div>&copy; <strong>Dive Scientific</strong> {new Date().getFullYear()}. <br className="mobile" /><Link to="/versions">Version 2.0.{version}</Link></div>
                    <div><Mail /><a href="mailto:admin@aaus.org" target="_blank" rel="noreferrer">admin@aaus.org</a></div>
                </footer>
            </div>

            {expirations && Object.keys(expirations).length > 0 ? <ModalExpiration hideExpirations={hideExpirations} expirations={expirations} expirationList={expirationList} /> : ''}
        </div>
    );
};

export default Layout;

const ModalExpiration = ({hideExpirations, expirations, expirationList}) => {
    const today = new Date();
    const threeMonths = new Date();
    threeMonths.setMonth(threeMonths.getMonth() + 3); 

    return (
        <Modal setClose={false} icon={<CalendarIcon />} type="wide">
            <h2>Expirations Alert</h2>
            <p>Attention, you have less than 90 days left to renew the following certifications:</p>
            <div className="expiration-notification">
                {Object.entries(expirations).map(item => {
                    let itemDate = item[1] ? new Date(item[1]).getTime() : 0;
                    return (
                        <div className="expire-date" key={item[0]}>
                            <strong>{expirationList[item[0]]}</strong>
                            <span className={itemDate < today.getTime() ? 'expired' : (itemDate < threeMonths.getTime() ? 'soon' : '')}>{item[1]}</span>
                        </div>
                    )
                })}
            </div>
            <button className="primary" onClick={hideExpirations}>I acknowledge my responsibility</button>
        </Modal>
    )
}
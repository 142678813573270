import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import AsyncSelect from 'react-select/async';

import { confService } from '../../Services';
import { usersService } from '../../Services';

import { setFilter } from '../../redux/stores/orgSlice';

import Loader from '../Common/Loader';
import Modal from '../Common/Modal';
//import Tabs from '../Common/Tabs';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Plus} from '../../images/icons/General/plus.svg';
import {ReactComponent as Sunset} from '../../images/icons/Weather/sunset.svg';
import {ReactComponent as Sunrise} from '../../images/icons/Weather/sunrise.svg';
import {ReactComponent as Edit} from '../../images/icons/General/edit-01.svg';
import {ReactComponent as Waves} from '../../images/icons/Weather/waves.svg';
import {ReactComponent as Hand} from '../../images/icons/General/heart-hand.svg';
import {ReactComponent as Certificate} from '../../images/icons/Education/certificate-02.svg';
import {ReactComponent as User} from '../../images/icons/Users/user-01.svg';
import {ReactComponent as Search} from '../../images/icons/General/search-sm.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Users = () => {
    const [info, setInfo] = useReducer(dataReducer, {});
    const [dives, setDives] = useState([]);
    const [number, setNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(0);
    const [sortField, setSortField] = useState('LastName');
    const [sortOrder, setSortOrder] = useState(true);
    const local = useSelector((state) => state.org.remember);
    const filter = useSelector((state) => state.org.filter.user ?? null);
    const userId = useSelector((state) => state[local ? 'localUser' : 'user'].id);
    const permissions = useSelector((state) => state[local ? 'localUser' : 'user'].permissions).split(',');
    const [newUser, setNewUser] = useState(false);
    const [search, setSearch] = useState(null);
    const [confirmDeactivation, setConfirmDeactivation] = useState(0);
    const dispatch = useDispatch();

    const changeFilter = (value) => {
        dispatch(setFilter({
            name: 'user',
            value
        }));
    }

    const sort = (e) => {
        if (e.target.id === sortField)
            setSortOrder(!sortOrder)
        else 
            setSortField(e.target.id);
    }

    const setStatus = async (id, key, statusValue, confirm = false) => {
        if (id === userId && !statusValue && !confirm) {
            setConfirmDeactivation([id, key]);
        } else {
            setStatusLoading(id);
            const res = await confService.userStatus({
                id: id,
                status: statusValue
            });

            if (res.result === 'OK')
                setInfo({
                    key: key, 
                    value: {
                        ...info[key],
                        Status: statusValue
                    }
                });

            setStatusLoading(0);
            setConfirmDeactivation(false);
        }
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await confService.getUsers();
        res.list.forEach((el, key) => {
            setInfo({
                key: key,
                value: el
            });
        });
        setNumber(res.number);
        setDives(res.dives);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Users</h1>
                {info ? <>
                    <div className="bordered-content">
                        <div className="header">
                            <h4>{Object.entries(info).filter(([key, item]) => (filter === null || item.Status === filter)).length} user{number === 1 ? '' : 's'}</h4>
                            <div className="buttons">
                                <div className="search">
                                    <Search />
                                    <input type="text" name="search" onKeyUp={(e) => setSearch(e.target.value)} placeholder="Enter user name..." />
                                </div>
                                {permissions.indexOf('Add user') > -1 ? <button className="primary" type="button" onClick={() => setNewUser(true)}><Plus />Add user</button> : ''}
                             </div>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filter === null ? 'active' : ''} onClick={() =>changeFilter(null)}>View all</button>
                                <button type="button" className={filter === 1 ? 'active' : ''} onClick={() =>changeFilter(1)}>Active</button>
                                <button type="button" className={filter === 0 ? 'active' : ''} onClick={() =>changeFilter(0)}>Inactive</button>
                                <button type="button" className={filter === 2 ? 'active' : ''} onClick={() =>changeFilter(2)}>Not Approved</button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '17%'}} className={`sortable ${sortField === 'LastName' ? (sortOrder ? 'down' : 'up') : ''}`} id="LastName" onClick={sort}>Last name</th>
                                    <th style={{width: '17%'}} className={`sortable ${sortField === 'FirstName' ? (sortOrder ? 'down' : 'up') : ''}`} id="FirstName" onClick={sort}>First name</th>
                                    <th style={{width: '24%'}} className={`sortable ${sortField === 'Email' ? (sortOrder ? 'down' : 'up') : ''}`} id="Email" onClick={sort}>Email</th>
                                    <th style={{width: '7%'}} className="table-icon light">History</th>
                                    <th style={{width: '8%'}} className="table-icon light">Log a dive</th>  
                                    <th style={{width: '7%'}} className="table-icon light">Reciprocity</th>  
                                    <th style={{width: '7%'}} className="table-icon light">Training</th>  
                                    <th style={{width: '7%'}} className="table-icon light">Status</th>  
                                    <th style={{width: '6%'}} className="table-icon light">Edit</th>  
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(info).filter(([key, item]) => (filter === null || item.Status === filter)).sort(([,a],[,b]) => {
                                    if (typeof a[sortField] === 'number')
                                        return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                    else {
                                        const fieldA = a[sortField].toUpperCase(); 
                                        const fieldB = b[sortField].toUpperCase();
                                        if (fieldA < fieldB)
                                            return sortOrder ? -1 : 1;

                                        if (fieldA > fieldB)
                                            return sortOrder ? 1 : -1;
                                        
                                        return 0;
                                    }
                                }).map(([key, item]) => {
                                    if (search && (item.Email.toLowerCase().indexOf(search.toLowerCase()) < 0 && (item.FirstName + ' ' + item.LastName).toLowerCase().indexOf(search.toLowerCase()) < 0))
                                        return false;
                                    return (
                                        <tr key={item.id} className={!item.Status ? 'disabled' : (item.Status === 2 ? 'not-approved' : '')}>
                                            <td className={item.Status === 2 ? 'new' : ''}><strong className="mobile">User: </strong>{item.LastName}<span className="mobile">, {item.FirstName}</span></td>
                                            <td className="desktop">{item.FirstName}</td>
                                            <td><strong className="mobile">Email: </strong><a href={`mailto:${item.Email}`}>{item.Email}</a></td>
                                            <td className="table-icon desktop">{permissions.indexOf('View dive') > -1 ? <Link to={`/configuration/users/${item.id}/dives`}><label className="label link pointer" title="Dive history">{dives[item.id] ?? 0}</label></Link> : ''}</td>
                                            <td className="table-icon desktop">{permissions.indexOf('Add dive') > -1 ? <Link to={`/configuration/users/${item.id}/dives/add`}><Waves className="pointer" title="Log a dive" /></Link> : ''}</td>
                                            <td className="table-icon desktop"><Link to={`/configuration/users/${item.id}/reciprocity`}><Hand className="pointer" title="LOR (Letter of Reciprocity)" /></Link></td>
                                            <td className="table-icon desktop"><Link to={`/configuration/users/${item.id}/training`}><Certificate className="pointer" title="VOT (Verification of Training letter)" /></Link></td>
                                            <td className="table-icon desktop">{((item.Status === 2 && permissions.indexOf('Confirm user') > -1) || item.Status !== 2 ? 
                                                    (statusLoading === item.id ? 
                                                        <Loading className="btn-loader" /> : 
                                                        item.Status === 1 ? 
                                                            <Sunset className="pointer" onClick={() => statusLoading === item.id ? {} : setStatus(item.id, key, item.Status === 1 ? 0 : 1)} title="Deactivate" /> :
                                                            <Sunrise className="pointer" onClick={() => statusLoading === item.id ? {} : setStatus(item.id, key, item.Status === 1 ? 0 : 1)} title="Activate" />
                                                    ) : 
                                                '')}</td>
                                            <td className="table-icon desktop last">{permissions.indexOf('Edit user') > -1 ? <Link to={`/configuration/users/${item.id}`}><Edit className="pointer" title="Edit user profile" /></Link> : ''}</td>
                                            <td className="mobile">
                                                {permissions.indexOf('View dive') > -1 ? <Link to={`/configuration/users/${item.id}/dives`}><label className="label link">History ({dives[item.id] ?? 0})</label></Link> : ''}
                                                {permissions.indexOf('Add dive') > -1 ? <Link to={`/configuration/users/${item.id}/dives/add`}><label className="label link">Log a dive</label></Link> : ''}
                                                <Link to={`/configuration/users/${item.id}/reciprocity`}><label className="label link">Reciprocity</label></Link>
                                                <Link to={`/configuration/users/${item.id}/training`}><label className="label link">Training</label></Link>
                                                {(item.Status === 2 && permissions.indexOf('Confirm user') > -1) || item.Status !== 2 ? <label className="label link" onClick={() => statusLoading === item.id ? {} : setStatus(item.id, key, item.Status === 1 ? 0 : 1)}>{statusLoading === item.id ? <Loading className="btn-loader" /> : ''}{item.Status === 1 ? 'Deactivate' : 'Activate'}</label> : ''}
                                                {permissions.indexOf('Edit user') > -1 ? <Link to={`/configuration/users/${item.id}`}><label className="label link">Edit</label></Link> : ''}
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>  
                </> : <p className="empty">User list is empty</p>}
                {newUser ? <ModalNewUser setClose={() => setNewUser(false)} /> : ''}
                {confirmDeactivation ? <ModalConfirmDeactivation setClose={() => setConfirmDeactivation(0)} setStatus={setStatus} user={confirmDeactivation} statusLoading={statusLoading} /> : ''}
            </>}
        </>
    );
};

export default Users;

const ModalConfirmDeactivation = ({setClose, setStatus, user, statusLoading}) => {
    /*const tabs = [
        {
            title: 'New User',
            content: <NewUser updateInfo={updateInfo} />
        },
        {
            title: 'Existing user',
            content: <ExistingUser updateInfo={updateInfo} />
        }
    ];*/

    return (
        <Modal setClose={setClose} icon={<User />} type="wide">
            <h4>You are about to deactivate your own account. Are you sure?</h4>
            <div className="buttons">
                <button className="primary" type="button" disabled={statusLoading === user[0]} onClick={() => setStatus(user[0], user[1], 0, true)}>{statusLoading === user[0] ? <Loading className="btn-loader" /> : 'Yes'}</button>
                <button className="white-bordered" type="button" onClick={() => setClose()} disabled={statusLoading === user[0]}>Cancel</button>
            </div>
        </Modal>
    )
}

const ModalNewUser = ({setClose, updateInfo}) => {
    /*const tabs = [
        {
            title: 'New User',
            content: <NewUser updateInfo={updateInfo} />
        },
        {
            title: 'Existing user',
            content: <ExistingUser updateInfo={updateInfo} />
        }
    ];*/

    return (
        <Modal setClose={setClose} icon={<User />} type="wide">
            <h4>Add an AAUS user to this OM</h4>
            <ExistingUser updateInfo={updateInfo} />
        </Modal>
    )
}

/*const NewUser = () => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const changeValue = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setLoading(true);

        let message = '';

        if (!data.Email || !data.FirstName || !data.LastName || !data.Password)
            message = 'Please, enter required fields';

        if (!data.CPassword || data.Password !== data.CPassword) {
            message = 'Passwords do not match';
        }

        if (!message) {
            const res = await usersService.create({
                'type': 'new',
                'Email': data.Email,
                'FirstName': data.FirstName,
                'LastName': data.LastName,
                'Password': data.Password,
                'CPassword': data.CPassword
            });

            if (res.res === 'OK') {
                navigate('/configuration/users/' + res.id);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    return (
        <>
            <p>The information in account is shared between all of AAUS dive logging profiles on all AAUS Organization Member websites</p>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                <div className="form-column">
                    <label>Email address</label>
                    <input type="email" name="Email" placeholder="Enter your email address" value={data.Email || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-column">
                    <label>First name</label>
                    <input type="text" name="FirstName" placeholder="Enter your first name" value={data.FirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-column">
                    <label>Last name</label>
                    <input type="text" name="LastName" placeholder="Enter your last name" value={data.LastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-column">
                    <label>Password</label>
                    <input type="password" name="Password" placeholder="New password" value={data.Password || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-column">
                    <label>Confirm password</label>
                    <input type="password" name="CPassword" placeholder="Confirm new password" value={data.CPassword || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Send</button>
                    {error ? <div className="error">{error}</div> : ''}
                </div>
            </form>
        </>
    )
}*/

const ExistingUser = () => {
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            width: '100%',
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #b0e2ff',

        })
    }

    const loadOptions = async (inputValue) => {
        if (inputValue.length >= 2) {
            const data = await usersService.search({
                'name': inputValue
            });

            return data.list;
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setLoading(true);

        let message = '';

        if (!user)
            message = 'Please, enter required fields';

        if (!message) {
            const res = await usersService.create({
                id: user.id,
                type: 'existing'
            });

            if (res.res === 'OK') {
                navigate('/configuration/users/' + user.id);
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    return (
        <>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                <div className="form-column">
                    <label>User</label>
                    <div>
                        <AsyncSelect
                            onChange={setUser}
                            styles={customStyles}
                            width='100%'
                            menuColor='red'
                            getOptionLabel={e => e.name+' ('+e.Email+')'}
                            getOptionValue={e => e.id}
                            loadOptions={loadOptions}
                            placeholder="Start typing a last name..."
                        />
                        <div className="font-14">You may search by the full last name and the full first name, separating them by comma. For example "Smith, John".</div>
                    </div>
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Add</button>
                    {error ? <div className="error">{error}</div> : ''}
                </div>
            </form>
        </>
    )
}
import React, { useState, useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';

import { logsService } from '../../Services';

import Loader from '../Common/Loader';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Download} from '../../images/icons/General/download-cloud-02.svg';
import {ReactComponent as Printer} from '../../images/icons/Media/printer.svg';

export const History = () => {
    const params = useParams();
    const [info, setInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [loadingCsv, setLoadingCsv] = useState(false);
    const [sortField, setSortField] = useState('date');
    const [sortOrder, setSortOrder] = useState(false);

    const sort = (e) => {
        if (e.target.id === sortField)
            setSortOrder(!sortOrder)
        else 
            setSortField(e.target.id);
    }

    const printTable = () => {
        window.frames["print_frame"].document.body.innerHTML = document.getElementById("history-table").outerHTML + 
            // eslint-disable-next-line
            '<style>table {border: 1px solid #000; font-family: Tahoma; border-spacing: 0;}\
                td,th {border-bottom: 1px solid #000; border-right: 1px solid #000; padding: 5px 10px;}\
                td:last-child,th:last-child{border-right: 0;}\
                td .mobile {display: none;}</style>';
        window.frames["print_frame"].window.focus();
        window.frames["print_frame"].window.print();
    }
    
    const downloadCsv = async (name) => {
        setLoadingCsv(true);
        const date = new Date();
        const res = await logsService.historyExport(params.userId || 0);
        const a = document.createElement('a');
        let binaryData = [];
        binaryData.push(res);
        let url = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csv"}))
        a.href = url;
        a.download = name + '_dive-log_'+window.location.hostname+'_'+moment(date).format('YYYY-MM-DD-HH-mm')+'.csv';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
        setLoadingCsv(false);
    };

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await logsService.history(params.userId || 0);
        setInfo(res);

        setLoading(false);
      }, [params]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>{params.userId ? (info.user ? `${info.user.FirstName} ${info.user.LastName}'s` : '') : 'My'} dive log history</h1>
                {info.list ? <>
                    <div className="bordered-content">
                        <div className="header">
                            <h4>Dives <span className="label">{info.divesNumber} logged dive{info.divesNumber === 1 ? '' : 's'}</span></h4>
                            <p>If you would like to see all columns, please download the CSV</p>
                            <div className="buttons">
                                <button className="default" onClick={printTable}><Printer />Print</button>
                                <button className="default" onClick={() => downloadCsv(`${info.user.LastName}-${info.user.FirstName}`)}>{loadingCsv ? <Loading className="btn-loader" alt="loader" /> : <Download />}Download CSV</button>
                                <iframe name="print_frame" title="print_frame" width="0" height="0" frameBorder="0" src="about:blank"></iframe>
                            </div>
                        </div>
                        <table id="history-table">
                            <thead>
                                <tr>
                                    <th className={`sortable ${sortField === 'date' ? (sortOrder ? 'down' : 'up') : ''}`} id="date" onClick={sort}>Date</th>
                                    <th className={`sortable ${sortField === 'date' ? (sortOrder ? 'down' : 'up') : ''}`} id="date" onClick={sort}>Starting time</th>
                                    <th className={`sortable ${sortField === 'totalTime' ? (sortOrder ? 'down' : 'up') : ''}`} id="totalTime" onClick={sort}>Duration (min)</th>
                                    <th className={`sortable ${sortField === 'location' ? (sortOrder ? 'down' : 'up') : ''}`} id="location" onClick={sort}>Location</th>
                                    <th className={`sortable ${sortField === 'gear' ? (sortOrder ? 'down' : 'up') : ''}`} id="gear" onClick={sort}>Gear</th>
                                    <th className={`sortable ${sortField === 'diveDepth' ? (sortOrder ? 'down' : 'up') : ''}`} id="diveDepth" onClick={sort}>Depth (ft)</th>
                                    <th className="light">Comments</th>
                                </tr>
                            </thead>
                            <tbody>
                                {info.list.sort((a,b) => {
                                        if (typeof a[sortField] === 'number')
                                            return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                        else {
                                            const fieldA = sortField === 'date' ? new Date(a[sortField]) : a[sortField].toUpperCase(); 
                                            const fieldB = sortField === 'date' ? new Date(b[sortField]) : b[sortField].toUpperCase();
                                            if (fieldA < fieldB)
                                                return sortOrder ? -1 : 1;

                                            if (fieldA > fieldB)
                                                return sortOrder ? 1 : -1;
                                            
                                            return 0;
                                        }
                                    }).map(item => {
                                    return (
                                        <tr key={item.id}>
                                            <td><Link to={params.userId ? `/configuration/users/${params.userId}/dives/${item.id}` : `/logs/edit/${item.id}`}>{item.date}<span className="mobile"> {item.time}</span></Link></td>
                                            <td className="desktop">{item.time}</td>
                                            <td><strong className="mobile">Duration: </strong>{item.totalTime}</td>
                                            <td><strong className="mobile">Location: </strong>{item.location}</td>
                                            <td><strong className="mobile">Gear: </strong><span className="label desktop">{item.gear}</span> <span className="mobile">{item.gear}</span></td>
                                            <td><strong className="mobile">Depth: </strong>{item.diveDepth} <span className="mobile">ft</span></td>
                                            <td className="light">{item.comments}</td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                </> : <p className="empty">Dive history is empty</p>}
            </>}
        </>
    );
};

export default History;
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { authService } from '../../Services';

import { setId, setName, setHid, setPhoto, setPermissions, setIs24h, setExpirations } from '../../redux/stores/userSlice';
import { setLocalId, setLocalName, setLocalHid, setLocalPhoto, setLocalPermissions, setLocalIs24h, setLocalExpirations } from '../../redux/stores/localUserSlice';
import { setRemember, clearFilter } from '../../redux/stores/orgSlice';

import LoginLayout from '../Common/Layouts/LoginLayout';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';

export const Login = () => {
    const [login, setLogin] = useState();
    const [password, setPassword] = useState();
    const [checkbox, setCheckbox] = useState(false);
    const [loading, setLoading] = useState();
    const [error, setError] = useState();
    const [success, setSuccess] = useState();
    const orgId = useSelector((state) => state.org.id);
    const orgName = useSelector((state) => state.org.name);
    const dispatch = useDispatch();

    const handleCheckbox = () => {
        setCheckbox(!checkbox);
    }

    const handleSubmit = async e => {
        e.preventDefault();
        setLoading(true);
        setError(false);

        const user = await authService.login({
            login,
            password,
            orgId
        });

        if (user.hid) {
            dispatch(clearFilter());
            if (checkbox) {
                dispatch(setLocalId(user.uid));
                dispatch(setLocalHid(user.hid));
                dispatch(setLocalName(user.name));
                dispatch(setLocalPhoto(user.photo));
                dispatch(setLocalPermissions(user.permissions));
                dispatch(setLocalIs24h(user.Is24h));
                dispatch(setLocalExpirations(user.expirations));
                dispatch(setRemember(checkbox));
            } else {
                dispatch(setId(user.uid));
                dispatch(setHid(user.hid));
                dispatch(setName(user.name));
                dispatch(setPhoto(user.photo));
                dispatch(setPermissions(user.permissions));
                dispatch(setIs24h(user.Is24h));
                dispatch(setExpirations(user.expirations));
            }
            setLoading(false);
        } else {
            setLoading(false);
            if (user.success) 
                setSuccess(user.success);
            else
                setError(user.error);
        }
    }

    return (
        <LoginLayout>
            <div className="login">
                <h2>Log in to your account</h2>
                <p>Welcome back! Please enter your details.</p>

                <form onSubmit={handleSubmit}>
                    <div className="form-control">
                        <label>Email</label>
                        <input type="text" name="login" placeholder="Enter your email" onChange={e => setLogin(e.target.value)} />
                    </div>
                    <div className="form-control">
                        <label>Password</label>
                        <input type="password" name="password" placeholder="Password" onChange={e => setPassword(e.target.value)} />
                    </div>
                    <div className="forgot-password">
                        <div className="checkbox">
                            <input type="checkbox" name="remember" id="remember" checked={checkbox} onChange={handleCheckbox} />
                            <label htmlFor="remember">Remember me</label>
                        </div>
                        <Link to="/reset-password">Forgot password</Link>
                    </div>
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Sign in</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">{success}</div> : ''}
                </form>
                <div className="register">
                    <p>Don’t have an account with {orgName}?<br />
                        If you already have an existing AAUS dive logging profile with another AAUS Organization Member, please go ahead and log in above with your existing credentials. Our DSO will then review your profile and activate your account here at {orgName} as well.</p>
                    <p>Otherwise proceed to <Link to="/sign-up">sign up for a new account</Link>.</p>
                </div>
            </div>
        </LoginLayout>
    );
};

export default Login;
import React, { useState, useCallback, useEffect, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';
import moment from 'moment';

import "react-datepicker/dist/react-datepicker.css";

import { logsService } from '../../Services';

import Loader from '../Common/Loader';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Upload} from '../../images/icons/General/upload-cloud-02.svg';

const dataReducer = (state, event) => {
        if (event.action === 'clear')
            return {};
        else 
            return {
                ...state,
                [event.key]: {
                    ...state[event.key],
                    ...event.value
                }
            };
        
};

export const ImportForm = () => {
    const navigate = useNavigate();
    const [data, setData] = useReducer(dataReducer, {});
    const [info, setInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formLoading, setFormLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [error, setError] = useState('');

    const fileInput = useRef(null);

    const local = useSelector((state) => state.org.remember);
    const is24h = useSelector((state) => state[local ? 'localUser' : 'user'].is24h);

    const today = new Date();
    today.setDate(today.getDate() + 1);

    const minTime = new Date();
    minTime.setHours(0);
    minTime.setMinutes(0);

    const maxTime = new Date();
    maxTime.setHours(23);
    maxTime.setMinutes(59);

    const customStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
          }),
        input: (provided, state) => ({
            ...provided,
            height: '32px'
          }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #D0D5DD',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const errStyles = {
        container: (provided, state) => ({
            ...provided,
            flex: '1',
          }),
        input: (provided, state) => ({
        ...provided,
        height: '32px'
        }),
        control: (provided, state) => ({
          ...provided,
          border: '1px solid #ff0000',
          '&:hover': {
            boxShadow: '0 0 1px #5D3FC5'
          }
        })
    }

    const changeValue = (name, value, key) => {
        setData({
            key: key, 
            value: {
                [name]: value,
            }
        });
    };

    const readCsv = (ev) => {
        if (ev.target.files && ev.target.files[0]) {
            setLoading(true);

            setData({action: 'clear'});

            var myFile = ev.target.files[0];
            var reader = new FileReader();
            
            reader.addEventListener('load', function (e) {
                let csvdata = e.target.result; 
                let parsedata = [];

                let newLinebrk = csvdata.split("\r\n");
                for(let i = 1; i < newLinebrk.length; i++) {
                    let line = newLinebrk[i].split(",");
                    setData({
                        key: i, 
                        value: {
                            email: line[0],
                            user: info.users.filter(item => item.value === parseInt(info.userEmail[line[0]]))[0],
                            date: new Date(line[1]),
                            time: new Date(line[1] + ' ' + line[2]),
                            total: line[3],
                            location: info.locations.filter(item => item.label === line[4])[0],
                            slocation: line[5],
                            depth: line[6],
                            decompression: info.decompression.filter(item => item.label === line[7])[0],
                            gear: info.gears.filter(item => item.label === line[8])[0],
                            gas: info.gas.filter(item => item.label === line[9])[0],
                            classification: info.classifications.filter(item => item.label === line[10])[0],
                        }
                    });
                }

                setData(parsedata);
                setLoading(false);
            });

            reader.readAsBinaryString(myFile);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setFormLoading(true);

        let dataSet = [];
        Object.keys(data).forEach(key => {
            let dive = {};
            if (data[key]['email']) {
                Object.keys(data[key]).forEach(item => {
                    if (!data[key][item]) {
                        setError('Please, fill in required fields');
                        setFormLoading(false);
                        return;
                    } else
                        dive[item] = item === 'time' ? data[key][item].getHours() + ':' + data[key][item].getMinutes() : data[key][item]
                });
                dataSet.push(dive);
            }
        });

        const res = await logsService.importData(dataSet);
        if (res.res === 'OK')
            navigate('/logs/import/');
        else {
            setError('Something went wrong. Please, check the data and try again.');
            setFormLoading(false);
        }
    };

    const downloadTemplate = async () => {
        const date = new Date();
        const res = await logsService.importTemplate();
        const a = document.createElement('a');
        let binaryData = [];
        binaryData.push(res);
        let url = window.URL.createObjectURL(new Blob(binaryData, {type: "text/csvapplication/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"}))
        a.href = url;
        a.download = 'dive-import-template_'+window.location.hostname+'_'+moment(date).format('YYYY-MM-DD-HH-MM')+'.xlsx';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };

    const fetchData = useCallback(async (last) => {
        setPageLoading(true);

        const res = await logsService.importInfo();
        setInfo(res);
            
        setPageLoading(false);
      }, [setPageLoading]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <>
            {pageLoading ? <Loader /> : <>
                <h1>Import</h1>
                <div className="bordered-content ">
                    <div className="header">
                        <h4>Import dive log</h4>
                        <p>Upload a CSV file based on <span className="pseudo-link" onClick={downloadTemplate}>the template</span> to import dive logs</p>
                        <div className="buttons">
                            {loading ? <Loading className="btn-loader" /> : <>
                                <button className="default" onClick={() => fileInput.current.click()}>
                                    <Upload />Upload a file
                                </button>
                                <input
                                    type="file"
                                    onChange={(event) => readCsv(event)}
                                    accept=".csv"
                                    ref={fileInput}
                                    style={{display: 'none'}}
                                />
                            </>}
                        </div>
                    </div>
                </div>
                {Object.keys(data).length ? <form onSubmit={handleSubmit}>
                    <div className="bordered-content two-buttons ">
                        {formLoading ? <Loading className="btn-loader" /> : <>
                            <button type="submit" className="primary">Submit</button>
                            <button type="submit" className="primary">Submit</button>
                        </>}
                        {error ? <div className="error">{error}</div> : ''}
                    </div>
                    <div className="bordered-content ">
                        <table className="import-table">
                            <thead>
                                <tr>
                                    <th>Email</th>
                                    <th>User</th>
                                    <th>Date</th>
                                    <th>Start time</th>
                                    <th>Total time</th>
                                    <th>Location or project</th>
                                    <th>Specific location</th>
                                    <th>Depth (ft)</th>
                                    <th>Decompression planning</th>
                                    <th>Diving mode</th>
                                    <th>Breathing gas</th>
                                    <th>Classification</th>
                                </tr>
                            </thead>
                            <tbody>
                                {// eslint-disable-next-line
                                Object.entries(data).map(item => {
                                    if (item[1].email)
                                        return (
                                            <tr key={item[0]}>
                                                <td>{item[1].email}</td>
                                                <td>
                                                    <strong className="mobile">user</strong>
                                                    <Select className={`select ${item[1].user ? '' : 'err'}`} required 
                                                        options={info.users} 
                                                        onChange={(value) => changeValue('user', value, item[0])} 
                                                        styles={item[1].user ? customStyles : errStyles}  
                                                        value={item[1].user} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Date</strong>
                                                    <DatePicker 
                                                        name="date" 
                                                        selected={item[1].date} 
                                                        maxDate={today} 
                                                        onChange={(value) => changeValue('date', value, item[0])}  
                                                        placeholderText="Select date"
                                                        className={`required ${item[1].date ? '' : 'err'}`}
                                                        autoComplete="off"
                                                        isClearable
                                                    />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Time</strong>
                                                    <DatePicker 
                                                        name="time" 
                                                        onChange={(value) => changeValue('time', value, item[0])} 
                                                        minTime={minTime}
                                                        maxTime={(item[1].time && today.toDateString() === item[1].time.toDateString()) ? new Date() : maxTime} 
                                                        selected={item[1].time || ''} 
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeFormat={is24h > 0 ? 'HH:mm' : 'hh:mm a'} 
                                                        dateFormat={is24h > 0 ? 'HH:mm' : 'hh:mm a'} 
                                                        isClearable
                                                        autoComplete="off"
                                                        className={`required ${item[1].time ? '' : 'err'}`}
                                                    />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Total time</strong>
                                                    <input type="number" step="1" name="totalTime" value={item[1].total} className={item[1].total && parseInt(item[1].total) > -1 ? '' : 'err'} onChange={(e) => changeValue('total', e.target.value, item[0])} onWheel={(e) => e.target.blur()} /></td>
                                                <td>
                                                    <strong className="mobile">Location</strong>
                                                    <Select className={`select ${item[1].location ? '' : 'err'}`} required 
                                                        options={info.locations} 
                                                        onChange={(value) => changeValue('location', value, item[0])} 
                                                        styles={item[1].location ? customStyles : errStyles} 
                                                        value={item[1].location} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Specific location</strong>
                                                    <input type="text" name="slocation" value={item[1].slocation} className={item[1].slocation ? '' : 'err'} onChange={(e) => changeValue('slocation', e.target.value, item[0])} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Depth</strong>
                                                    <input 
                                                        type="number" 
                                                        step="0.1" 
                                                        name="depth" 
                                                        className={parseFloat(item[1].depth) > 0 ? '' : 'err'} 
                                                        onChange={(e) => changeValue('depth', e.target.value, item[0])}
                                                        disabled={item[1].location ? (item[1].location.value !== 1 && item[1].location.value !== 2 && item[1].location.Depth > 0 ? true : false) : true} 
                                                        value={item[1].location ? (item[1].location.value !== 1 && item[1].location.value !== 2 && item[1].location.Depth > 0 ? item[1].location.Depth : item[1].depth) : 0}
                                                        onWheel={(e) => e.target.blur()} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Decompression</strong>
                                                    <Select className={`select ${item[1].decompression ? '' : 'err'}`} required 
                                                        options={info.decompression} 
                                                        onChange={(value) => changeValue('decompression', value, item[0])} 
                                                        styles={item[1].decompression ? customStyles : errStyles} 
                                                        value={item[1].decompression} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Diving mode</strong>
                                                    <Select className={`select ${item[1].gear ? '' : 'err'}`} required 
                                                        options={info.gears} 
                                                        onChange={(value) => changeValue('gear', value, item[0])} 
                                                        styles={item[1].gear ? customStyles : errStyles} 
                                                        value={item[1].gear} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Gas mixture</strong>
                                                    <Select className={`select ${item[1].gas ? '' : 'err'}`}required 
                                                        options={info.gas} 
                                                        onChange={(value) => changeValue('gas', value, item[0])} 
                                                        styles={item[1].gas ? customStyles : errStyles} 
                                                        value={item[1].gas} />
                                                </td>
                                                <td>
                                                    <strong className="mobile">Classification</strong>
                                                    <Select className={`select ${item[1].classification ? '' : 'err'}`} required 
                                                        options={info.classifications} 
                                                        onChange={(value) => changeValue('classification', value, item[0])} 
                                                        styles={item[1].classification ? customStyles : errStyles} 
                                                        value={item[1].classification} />
                                                </td>
                                            </tr>
                                        )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="bordered-content two-buttons">
                        {formLoading ? <Loading className="btn-loader" /> : <>
                            <button type="submit" className="primary">Submit</button>
                            <button type="submit" className="primary">Submit</button>
                        </>}
                        {error ? <div className="error">{error}</div> : ''}
                    </div>
                </form> : ''}
            </>}
        </>
    );
};

export default ImportForm;
import React, { useState, useRef } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector, useDispatch } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import { usersService } from '../../Services';
import { uploadsUrl } from '../../Services/config';

import { setName, setPhoto, setIs24h } from '../../redux/stores/userSlice';
import { setLocalName, setLocalPhoto, setLocalIs24h } from '../../redux/stores/localUserSlice';

const ProfileDetails = ({data, changeValue, userId}) => {
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [photoField, setPhotoField] = useState(null);
    const dispatch = useDispatch();

    const fileRef = useRef(null);

    const remember = useSelector((state) => state.org.remember);

    const updatePhoto = (event) => {
        changeValue('Photo', event.target.files[0] ? true : null);
        setPhotoField(event.target.files[0]);
    }
    
    const removePhoto = () => {
        setPhotoField(null);
        changeValue('Photo', null);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.Email || !data.FirstName || !data.LastName || !data.DateOfBirth)
            message = 'Please, enter required fields';

        if (data.Password && (!data.CPassword || data.Password !== data.CPassword)) {
            message = 'Passwords do not match';
        }

        const formData = new FormData();
        
        for (const [key, value] of Object.entries(data)) {
            if (key === 'DateOfBirth') {
                let date = new Date(value);
                formData.append(key, date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate());
            } else 
                formData.append(key, value);
        }

        formData.append('editType', 'ProfileDetails');

        if (photoField)
            formData.append('photo', photoField);

        if (!message) {
            const res = await usersService.edit(formData, (userId || 0));

            if (res.res === 'OK') {
                setSuccess(true);
                if (photoField) {
                    changeValue('Photo', res.img);
                    setPhotoField(null);
                }
                if (!userId) {
                    if (remember) {
                        dispatch(setLocalName(data.FirstName + ' ' + data.LastName));
                        dispatch(setLocalIs24h(data.Is24h));
                        if (res.img)
                            dispatch(setLocalPhoto(res.img+'?'+ new Date().getTime()));
                    } else {
                        dispatch(setName(data.FirstName + ' ' + data.LastName));
                        dispatch(setIs24h(data.Is24h));
                        if (res.img)
                            dispatch(setPhoto(res.img+'?'+ new Date().getTime()));
                    }
                }
            } else {
                setError(res.error);
            }
        } else {
            setError(message);
        }
        setLoading(false);
    };

    return (
        <>
            <p>The information in this tab is shared between all of {userId ? '' : 'your'} AAUS dive logging profiles on all AAUS Organization Member websites</p>
            <form onSubmit={handleSubmit} className={error ? 'required' : ''}>
                <div className="form-column">
                    <label>Email address</label>
                    <div>
                        <input type="email" name="Email" placeholder="Enter your email address" value={data.Email || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required />
                        <div className="font-14">{userId ? 'This' : 'Your'} email address is used as {userId ? '' : 'your'} User ID</div>
                    </div>
                </div>
                <div className="form-column img-upload">
                    <label>Your photo<span>This will be displayed on your profile.</span></label>
                    <div>
                        <input ref={fileRef} type="file" name="photo" accept="image/*" onChange={updatePhoto} />
                        <div className="img">
                            {!photoField && data.Photo ? <img src={uploadsUrl + '/users/'+data.Photo} className="form-photo" alt="aUser vatar" /> : ''}
                            {photoField ? <img src={URL.createObjectURL(photoField)} className="form-photo" alt="User avatar" /> : ''}
                        </div>
                        <div className="photo-buttons">
                            {photoField || data.Photo ? <div onClick={removePhoto} className="delete">Delete</div> : ''}
                            <div onClick={() => {fileRef.current.click()}} className="upload">{photoField || data.Photo ? 'Update' : 'Upload'}</div>
                        </div>
                    </div>
                </div>
                <div className="form-column">
                    <label>First name</label>
                    <input type="text" name="FirstName" placeholder="Enter your first name" value={data.FirstName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Middle name<span>(Optional)</span></label>
                    <input type="text" name="MiddleName" placeholder="Enter your middle name" value={data.MiddleName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} maxLength={1} />
                </div>
                <div className="form-column">
                    <label>Last name</label>
                    <input type="text" name="LastName" placeholder="Enter your last name" value={data.LastName || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} required maxLength={20} />
                </div>
                <div className="form-column">
                    <label>Date of birth</label>
                    <DatePicker 
                        name="DateOfBirth" 
                        selected={data.DateOfBirth} 
                        onChange={(value) => changeValue('DateOfBirth', value)} 
                        placeholderText="Select date" 
                        className="required" 
                        isClearable
                    />
                </div>
                <div className="form-column">
                    <label>Display time preference</label>
                    <div className="radio-group">
                        <div className="radio">
                            <input type="radio" name="Is24h" id="Is24h_0" value="0" defaultChecked={data.Is24h !== 1} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                            <label htmlFor="Is24h_0">12 hours (AM/PM)</label>
                        </div>
                        <div className="radio">
                            <input type="radio" name="Is24h" id="Is24h_1" value="1" defaultChecked={data.Is24h === 1} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                            <label htmlFor="Is24h_1">24 hours</label>
                        </div>
                    </div>
                </div>
                <h3 className="space-top">Change password</h3>
                <p>Please fill in these fields only of you want to change password</p>
                <div className="form-column">
                    <label>Password</label>
                    <input type="password" name="Password" placeholder="New password" value={data.Password || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                </div>
                <div className="form-column">
                    <label>Confirm password</label>
                    <input type="password" name="CPassword" placeholder="Confirm new password" value={data.CPassword || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                </div>
                <div className="form-submit">
                    <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Profile was successfully updated</div> : ''}
                </div>
            </form>
        </>
    )
};

export default ProfileDetails;
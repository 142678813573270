import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { uploadsUrl } from '../../../Services/config';

export const LoginLayout = ({children}) => {
    const orgId = useSelector((state) => state.org.id);
    const logoExt = useSelector((state) => state.org.logoExt);
    const orgName = useSelector((state) => state.org.name);

    const images = require.context('../../../images/covers', true);
    const imageList = images.keys().map(image => images(image));    
    const randonImg = useMemo(() => Math.floor(Math.random() * imageList.length), [imageList.length]);

    return (
     <div className="login-wrapper">
        <div className="content">
            <header>
                {logoExt ? <img src={`${uploadsUrl}/logos/${orgId}${logoExt}`} alt={orgName} /> : <img src={`${uploadsUrl}/logos/aaus.png`} alt={orgName} />}
                <p>{orgName}</p>
            </header>
            <main>
                {children}
            </main>
            <footer>
                &copy; <strong>Dive Scientific</strong> {new Date().getFullYear()}
            </footer>
        </div>
        <div className="cover" style={{
            backgroundImage: `url(${imageList[randonImg]})`
        }}></div>
     </div>
    );
};

export default LoginLayout;
import React, { useState, useCallback, useEffect } from 'react';
import {Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { logsService } from '../../Services';

import Loader from '../Common/Loader';

import {ReactComponent as Plus} from '../../images/icons/General/plus.svg';
import {ReactComponent as Edit} from '../../images/icons/General/edit-01.svg';

export const Incidents = () => {
    const [info, setInfo] = useState([]);
    const [number, setNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState('date');
    const [sortOrder, setSortOrder] = useState(false);
    const orgName = useSelector((state) => state.org.name);

    const sort = (e) => {
        if (e.target.id === sortField)
            setSortOrder(!sortOrder)
        else 
            setSortField(e.target.id);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await logsService.getIncidents();
        setInfo(res.list);
        setNumber(res.number);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Log incidents</h1>
                <div className="bordered-content">
                    <div className="header">
                        <h4>{number} item{number === 1 ? '' : 's'}</h4>
                        <p>Log a new incident internally at {orgName}</p>
                        <div className="buttons">
                            <Link to="/logs/incidents/add"><button className="primary" type="button"><Plus />Log a new incident</button></Link>
                        </div>
                    </div>
                    {info.length ? 
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '90%'}} className={`sortable ${sortField === 'date' ? (sortOrder ? 'down' : 'up') : ''}`} id="date" onClick={sort}>Date</th>
                                    <th style={{width: '10%'}} className="light">Edit</th>  
                                </tr>
                            </thead>
                            <tbody>
                                {info.sort((a,b) => {
                                    if (typeof a[sortField] === 'number')
                                        return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                    else {
                                        const fieldA = a[sortField];
                                        const fieldB = b[sortField];
                                        if (fieldA < fieldB)
                                            return sortOrder ? -1 : 1;

                                        if (fieldA > fieldB)
                                            return sortOrder ? 1 : -1;
                                        
                                        return 0;
                                    }
                                }).map((item) => {
                                    return (
                                        <tr key={item.IncidentID}>
                                            <td className="desktop">{item.date}</td>
                                            <td className="mobile"><Link to={`/logs/incidents/${item.IncidentID}`}>{item.date}</Link></td>
                                            <td className="desktop last"><Link to={`/logs/incidents/${item.IncidentID}`}><Edit className="pointer" /></Link></td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table> : 
                        <p className="empty">Incident list is empty</p>}
                </div>
                <div className="details">
                    <h4>Submitting incidents to AAUS</h4>
                    <p>Incidents can now be entered at any point throughout the year by accessing <a href="https://www.aaus.org/" target="_blank" rel="noreferrer">the Statistics tab on the Organizational Member's profile of the main AAUS website</a>.</p>
                    <p>Please be sure that the number of incidents entered throughout the calendar year equals the number submitted on your annual statistic form.To enter an incident, select the + sign to the right of the Incidents Entered section and complete the form.</p>
                    <p>Please refer to Submission Information (right menu bar or Members tab) for detailed instructions on form completion. Once an incident is submitted the Statistics Chair will be notified for approval.</p>
                </div>
            </>}
        </>
    );
};

export default Incidents;
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NoAccess from '../Common/NoAccess';
import Users from './Users';
import Tasks from './Tasks';
import Locations from './Locations';
import History from '../Logs/History';
import Log from '../Logs/Log';
import Profile from '../Profile/Index';
import Letter from './Letter';

export const Configuration = () => {
    const local = useSelector((state) => state.org.remember);
    const permissions = useSelector((state) => state[local ? 'localUser' : 'user'].permissions).split(',');

    return (
        <>
            <Routes>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('View user') >= 0} />}>
                    <Route index element={<Users />} />
                    <Route path="users" element={<Users />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Edit user') >= 0} />}>
                    <Route path="users/:userId" element={<Profile />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('View dive') >= 0} />}>
                    <Route path="users/:userId/dives" element={<History />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Edit dive') >= 0} />}>
                    <Route path="users/:userId/dives/:diveId" element={<Log />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Add dive') >= 0} />}>
                    <Route path="users/:userId/dives/add" element={<Log />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('View user') >= 0} />}>
                    <Route path="users/:userId/reciprocity" element={<Letter />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('View user') >= 0} />}>
                    <Route path="users/:userId/reciprocity/print" element={<Letter />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('View user') >= 0} />}>
                    <Route path="users/:userId/training" element={<Letter />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Tasks') >= 0} />}>
                    <Route path="tasks" element={<Tasks />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Locations') >= 0} />}>
                    <Route path="locations" element={<Locations />} />
                </Route>
                <Route path="*" element={<div className="error-404">There's nothing here: 404!</div>} />
            </Routes>
        </>
    );
};

export default Configuration;

const ProtectedRoute = ({ isAllowed, children }) => {
    if (!isAllowed) {
      return <NoAccess />;
    } else 
      return <>
        {children ? children : <Outlet />}
      </>;
};
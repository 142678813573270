import React, { useState, useCallback, useEffect, useReducer } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { confService } from '../../Services';

import { setFilter } from '../../redux/stores/orgSlice';

import Loader from '../Common/Loader';
import Modal from '../Common/Modal';

import {ReactComponent as Loading} from '../../images/icons/General/loading-01.svg';
import {ReactComponent as Plus} from '../../images/icons/General/plus.svg';
import {ReactComponent as Sunset} from '../../images/icons/Weather/sunset.svg';
import {ReactComponent as Sunrise} from '../../images/icons/Weather/sunrise.svg';
import {ReactComponent as Edit} from '../../images/icons/General/edit-01.svg';
import {ReactComponent as Globe} from '../../images/icons/Maps/globe-06.svg';

const dataReducer = (state, event) => {
    if (event.action === 'clear')
        return {};
    else 
        return {
            ...state,
            [event.key]: event.value
        };
};

export const Locations = () => {
    const [info, setInfo] = useReducer(dataReducer, {});
    const [number, setNumber] = useState(0);
    const [loading, setLoading] = useState(false);
    const [statusLoading, setStatusLoading] = useState(0);
    const [editItem, setEditItem] = useState(null);
    const [editItemKey, setEditItemKey] = useState(null);
    const [sortField, setSortField] = useState('Location');
    const [sortOrder, setSortOrder] = useState(false);
    const dispatch = useDispatch();

    const filter = useSelector((state) => state.org.filter.location ?? null);

    const changeFilter = (value) => {
        dispatch(setFilter({
            name: 'location',
            value
        }));
    }

    const sort = (e) => {
        if (e.target.id === sortField)
            setSortOrder(!sortOrder)
        else 
            setSortField(e.target.id);
    }

    const updateInfo = (item) => {
        setInfo({
            key: editItemKey, 
            value: item
        });
    }

    const setStatus = async (id, name, statusValue) => {
        setStatusLoading(id);
        const res = await confService.locationStatus({
            id: id,
            status: statusValue ? 1 : 0
        });

        if (res.result === 'OK')
            setInfo({
                key: name, 
                value: {
                    ...info[name],
                    Status: statusValue ? 1 : 0
                }
            });

            setStatusLoading(0);
    }

    const fetchData = useCallback(async () => {
        setLoading(true);

        const res = await confService.getLocations();
        res.list.forEach((el, key) => {
            setInfo({
                key: key,
                value: el
            });
        });
        setNumber(res.number);

        setLoading(false);
      }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <>
            {loading ? <Loader /> : <>
                <h1>Locations / Projects</h1>
                {info ? <>
                    <div className="bordered-content">
                        <div className="header">
                            <h4>{number} item{number === 1 ? '' : 's'}</h4>
                            <p>“Open Water” and “Other” are built-in locations that cannot be edited</p>
                            <div className="buttons">
                                <button className="primary" type="button" onClick={() => setEditItem({})}><Plus />Add item</button>
                            </div>
                        </div>
                        <div className="filter">
                            <div className="filter-buttons">
                                <button type="button" className={filter === null ? 'active' : ''} onClick={() =>changeFilter(null)}>View all</button>
                                <button type="button" className={filter === 1 ? 'active' : ''} onClick={() =>changeFilter(1)}>Active</button>
                                <button type="button" className={filter === 0 ? 'active' : ''} onClick={() =>changeFilter(0)}>Inactive</button>
                            </div>
                        </div>
                        <table>
                            <thead>
                                <tr>
                                    <th style={{width: '25%'}} className={`sortable ${sortField === 'Location' ? (sortOrder ? 'down' : 'up') : ''}`} id="Location" onClick={sort}>Name</th>
                                    <th style={{width: '55%'}} className={`sortable ${sortField === 'Depth' ? (sortOrder ? 'down' : 'up') : ''}`} id="Depth" onClick={sort}>Depth (ft)</th>
                                    <th style={{width: '10%'}} className="light">Status</th>
                                    <th style={{width: '10%'}} className="light">Edit</th>  
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(info).filter(([key, item]) => (filter === null || item.Status === filter)).sort(([,a],[,b]) => {
                                    if (typeof a[sortField] === 'number')
                                        return sortOrder ? a[sortField] - b[sortField] : b[sortField] - a[sortField];
                                    else {
                                        const fieldA = a[sortField].toUpperCase(); 
                                        const fieldB = b[sortField].toUpperCase();
                                        if (fieldA < fieldB)
                                            return sortOrder ? -1 : 1;

                                        if (fieldA > fieldB)
                                            return sortOrder ? 1 : -1;
                                        
                                        return 0;
                                    }
                                }).map(([key, item]) => {
                                    return (
                                        <tr key={item.LocationID} className={!item.Status ? 'disabled' : ''}>
                                            <td className={`mobile-bold ${item.LocationID <= 2 ? 'bold' : ''}`}>{item.Location}</td>
                                            <td>{item.LocationID > 2 ? <><strong className="mobile">Depth: </strong>{item.Depth ? <>{item.Depth} <span className="mobile">ft</span></> : 'n/a'}</> : ''}</td>
                                            <td className="desktop">{item.LocationID > 2 ? 
                                                (statusLoading === item.LocationID ? 
                                                    <Loading className="btn-loader" /> : 
                                                    (item.Status ? 
                                                        <Sunset className="pointer" onClick={() => statusLoading === item.LocationID ? {} : setStatus(item.LocationID, key, !item.Status)} /> :
                                                        <Sunrise className="pointer" onClick={() => statusLoading === item.LocationID ? {} : setStatus(item.LocationID, key, !item.Status)} />
                                                    )
                                                ) : 
                                            ''}</td>
                                            <td className="desktop last">{item.LocationID > 2 ? <Edit className="pointer" onClick={() => {
                                                setEditItem(item);
                                                setEditItemKey(key);    
                                            }} /> : ''}</td>
                                            <td className="mobile">
                                                {item.LocationID > 2 ? <label className="label link" onClick={() => statusLoading === item.LocationID ? {} : setStatus(item.LocationID, key, !item.Status)}>{statusLoading === item.LocationID ? <Loading className="btn-loader" /> : ''}{item.Status ? 'Deactivate' : 'Activate'}</label> : ''}
                                                {item.LocationID > 2 ? <label className="label link" onClick={() => {
                                                    setEditItem(item);
                                                    setEditItemKey(key);
                                                }}>Edit</label> : ''}
                                            </td>
                                        </tr>
                                    )}
                                )}
                            </tbody>
                        </table>
                    </div>
                    {editItem != null ? <ModalLocation item={editItem} setClose={() => {
                        setEditItem(null);
                        setEditItemKey(null);
                    }} updateInfo={updateInfo} /> : ''}
                </> : <p className="empty">Location list is empty</p>}
            </>}
        </>
    );
};

export default Locations;

const ModalLocation = ({item, setClose, updateInfo}) => {
    const [data, setData] = useState(item);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const changeValue = (key, value) => {
        setData({
            ...data,
            [key]: value
        })
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        setError('');
        setSuccess(false);
        setLoading(true);

        let message = '';

        if (!data.Location)
            message = 'Please, enter required fields';

            if (!message) {
                const res = await (data.LocationID ? confService.editLocation(data) : confService.addLocation(data));
    
                if (res.res === 'OK') {
                    setData({
                        ...data,
                        LocationID: item.LocationID || res.id,
                        Status: item.Status > -1 ? item.Status : 1
                    });
                    updateInfo({
                        ...data,
                        LocationID: item.LocationID || res.id,
                        Status: item.Status > -1 ? item.Status : 1
                    });
                    setSuccess(true);
                } else {
                    setError(res.error);
                }
            } else {
                setError(message);
            }
            setLoading(false);
    };

    return (
        <Modal setClose={setClose} icon={<Globe />}>
            <h4>{data.LocationID ? 'Edit' : 'Add'} location</h4>
            <form onSubmit={handleSubmit}>
                <div className="form-control">
                    <label>Name</label>
                    <input type="text" name="Location" value={data.Location || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} />
                </div>
                <div className="form-control">
                    <label>Depth</label>
                    <input type="number" name="Depth" value={data.Depth || ''} onChange={(e) => changeValue(e.target.name, e.target.value)} step="1" />
                </div>
                <div className="form-submit">
                    <div>
                        <button type="button" className="default" onClick={setClose}>Cancel</button>
                        <button type="submit" className="primary">{loading ? <Loading className="btn-loader" alt="loader" /> : ''}Save</button>
                    </div>
                    {error ? <div className="error">{error}</div> : ''}
                    {success ? <div className="success">Location was successfully {data.LocationID ? 'updated' : 'added'}</div> : ''}
                </div>
            </form>
        </Modal>
    )
}
import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

import NoAccess from '../Common/NoAccess';
import Log from './Log';
import History from './History';
import Incidents from './Incidents';
import IncidentForm from './IncidentForm';
import Import from './Import';
import ImportForm from './ImportForm';

export const Logs = () => {
    const local = useSelector((state) => state.org.remember);
    const permissions = useSelector((state) => state[local ? 'localUser' : 'user'].permissions).split(',');

    return (
        <>
            <Routes>
                <Route index element={<History />} />
                <Route path="add" element={<Log />} />
                <Route path="edit/:diveId" element={<Log />} />
                <Route path="history" element={<History />} />
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Incident') >= 0} />}>
                    <Route path="incidents" element={<Incidents />} />
                    <Route path="incidents/add" element={<IncidentForm />} />
                    <Route path="incidents/:incidentId" element={<IncidentForm />} />
                </Route>
                <Route element={<ProtectedRoute isAllowed={permissions.indexOf('Import') >= 0} />}>
                    <Route path="import" element={<Import />} />
                    <Route path="import/form" element={<ImportForm />} />
                </Route>
                <Route path="*" element={<div className="error-404">There's nothing here: 404!</div>} />
            </Routes>
        </>
    );
};

export default Logs;

const ProtectedRoute = ({ isAllowed, children }) => {
    if (!isAllowed) {
      return <NoAccess />;
    } else 
      return <>
        {children ? children : <Outlet />}
      </>;
};